import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { AUTH_ROUTES } from './shared/routes/auth-layout.routes';
import { MAIN_ROUTES } from './shared/routes/full-layout.routes';


export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'medicaloffice/core/patient',
    pathMatch: 'full',
  },

  // SET ROUTES LAYOUTS GIWARE
  // =======================================================================
  {
    path: 'medicaloffice', component: FullLayoutComponent,
    data: {
      title: 'full Views'
    },
    children: MAIN_ROUTES
  },
  {
    path: 'medicaloffice',
    component: AuthLayoutComponent,
    data: {
      title: 'content Views'
    },
    children: AUTH_ROUTES
  },
  {
    path: '**',
    redirectTo: 'medicaloffice/error'
  }
];
