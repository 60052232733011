import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { UpdatePregnancyRequestDom } from '../models/pregnancy.request.dom';
import { PregnancyResponseDom } from '../models/pregnancy.response.dom';
import { PregnancyRepository } from '../repositories/pregnancy.repository';

@Injectable({ providedIn: 'root' })
export class UpdatePregnancyUseCase implements UseCase<UpdatePregnancyRequestDom, Promise<Result<PregnancyResponseDom, Failure>>> {
    constructor(private _pregnancyRepository: PregnancyRepository) { }
    execute = (params: UpdatePregnancyRequestDom): Promise<Result<PregnancyResponseDom, Failure>> => this._pregnancyRepository.update(params);
}