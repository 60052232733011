import { Result } from "base/result/result";
import { Failure } from "base/failure/failure";
import { NoResult } from "base/usecase/query";
import { UltrasoundResponseDom } from "../models/ultrasound.response";
import { CreateUltrasoundRequestDom, UpdateUltrasoundRequestDom } from "../models/ultrasound.request";
export abstract class UltrasoundRepository {
    abstract create(request: CreateUltrasoundRequestDom): Promise<Result<UltrasoundResponseDom, Failure>>;
    abstract update(request: UpdateUltrasoundRequestDom): Promise<Result<UltrasoundResponseDom, Failure>>;
    abstract delete(id: number): Promise<Result<NoResult, Failure>>;
    abstract listByPatientId(patientId: number): Promise<Result<UltrasoundResponseDom[], Failure>>;
}