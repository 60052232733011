import { BasePaginationRequest } from "base/pagination/pagination";

export class CreateConsultationRequestDom {
  constructor(public params: CommonConsultationParams) {}
}

export class UpdateConsultationRequestDom {
  constructor(
    public params: {
      id: number;
      status: boolean;
      consultationNumber: number;
      additionalNote?: string;
    } & CommonConsultationParams
  ) {}
}

interface CommonConsultationParams {
  reason: string;
  diagnosis: string;
  patientId: number;
  dateOfConsultation: string;
}

export class PageableConsultationsRequestDom {
  constructor(
    public patientId: number,
    public params: BasePaginationRequest) { }
}