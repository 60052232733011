import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const MAIN_ROUTES: Routes = [
  {
    path: 'core',
    loadChildren: () => import('../../core/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('../../core/administration/administration.module').then(m => m.AdministrationModule)
  }
];
