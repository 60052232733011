
import { CreateIncapacityRequestDom, IncapacityResponseDom, UpdateIncapacityRequestDom } from "domain/incapacity";
import { CreateIncapacityRequestDto, IncapacityResponseDto, UpdateIncapacityRequestDto } from "../dtos/incapacity.dto";

export class IncapacityMapper {
    static toDom(param: IncapacityResponseDto): IncapacityResponseDom {
        return {
            id: param.id,
            patientId: param.patientId,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            duration: param.duration,
            diagnosis: param.diagnosis,
            observations: param.observations
        };
    }

    static toCreateDto(param: CreateIncapacityRequestDom): CreateIncapacityRequestDto {
        return <CreateIncapacityRequestDto>{
            patientId: param.params.patientId,
            initialDate: param.params.initialDate,
            finalDate: param.params.finalDate,
            duration: param.params.duration,
            diagnosis: param.params.diagnosis,
            observations: param.params.observations
        };
    }

    static toUpdateDto(param: UpdateIncapacityRequestDom): UpdateIncapacityRequestDto {
        return <UpdateIncapacityRequestDto>{
            id: param.params.id,
            patientId: param.params.patientId,
            initialDate: param.params.initialDate,
            finalDate: param.params.finalDate,
            duration: param.params.duration,
            diagnosis: param.params.diagnosis,
            observations: param.params.observations
        };
    }
}