import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { ReportRepository } from '../repositories/report.repository';

@Injectable({ providedIn: 'root' })
export class AllConsultationsAnnualReportUseCase implements UseCase<number, Promise<Result<Blob, Failure>>> {
    constructor(private _reportRepository: ReportRepository) { }
    execute = (params: number): Promise<Result<Blob, Failure>> => this._reportRepository.consultationsAnnual(params);
}