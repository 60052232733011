import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicalPrescriptionRepository } from 'domain/plans/medical-order/repositories/medical-prescription.repository';
import { MedicalPrescriptionImplRepository } from './medical-prescription-impl.repository';
import { ListMedicalPrescryptionsByTypeUseCase } from 'domain/plans/medical-order/usecases/list-medical-prescriptions-by-type.usecase';


export const provider = { provide: MedicalPrescriptionRepository, useClass: MedicalPrescriptionImplRepository }

@NgModule({
  providers: [
    provider,
    ListMedicalPrescryptionsByTypeUseCase
  ],
  imports: [
    CommonModule
  ]
})
export class MedicalPrescriptionInfModule { }
