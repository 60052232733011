import { CreatePhysicalExamRequestDto, PhysicalExamResponseDto, UpdatePhysicalExamRequestDto } from "../dtos/physical-exam.dto";
import { PhysicalExamResponseDom } from "domain/consultation/physical-exam/models/physical-exam.response.dom";
import { CreatePhysicalExamRequestDom, UpdatePhysicalExamRequestDom } from "domain/consultation/physical-exam/models/physical-exam.request.dom";

export class PhysicalExamMapper {
    static toDom(param: PhysicalExamResponseDto): PhysicalExamResponseDom {
        return {
            id: param.id,
            cervixHead: param.cervixHead,
            breasts: param.breasts,
            abdomen: param.abdomen,
            speculoscopy: param.speculoscopy,
            vaginalExamination: param.vaginalExamination,
            limbs: param.limbs,
            centralNervousSystem: param.centralNervousSystem,
            consultationId: param.consultationId,
        };
    }

    static toCreateDto(param: CreatePhysicalExamRequestDom): CreatePhysicalExamRequestDto {
        return <CreatePhysicalExamRequestDto>{
            cervixHead: param.params.cervixHead,
            breasts: param.params.breasts,
            abdomen: param.params.abdomen,
            speculoscopy: param.params.speculoscopy,
            vaginalExamination: param.params.vaginalExamination,
            limbs: param.params.limbs,
            centralNervousSystem: param.params.centralNervousSystem,
            consultationId: param.params.consultationId
        };
    }

    static toUpdateDto(param: UpdatePhysicalExamRequestDom): UpdatePhysicalExamRequestDto {
        return <UpdatePhysicalExamRequestDto>{
            id: param.params.id,
            cervixHead: param.params.cervixHead,
            breasts: param.params.breasts,
            abdomen: param.params.abdomen,
            speculoscopy: param.params.speculoscopy,
            vaginalExamination: param.params.vaginalExamination,
            limbs: param.params.limbs,
            centralNervousSystem: param.params.centralNervousSystem,
            consultationId: param.params.consultationId
        };
    }
}