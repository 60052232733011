import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { MedicineResponseDom } from '../models/medicine.response.dom';
import { MedicineRepository } from '../repositories/medicine.repository';

@Injectable({ providedIn: 'root' })
export class ListMedicinesByConsultationIdUseCase implements UseCase<number, Promise<Result<MedicineResponseDom[], Failure>>> {
    constructor(private _medicineRepository: MedicineRepository) { }
    execute = (params: number): Promise<Result<MedicineResponseDom[], Failure>> => this._medicineRepository.listByConsultationId(params);
}