import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { MedicalOrderType } from 'domain/item-list/constants/item-list.const';
import { MedicalPrescriptionRepository } from '../repositories/medical-prescription.repository';
import { MedicalPrescriptionResponseDom } from '../models/medical-prescription/medical-prescription.response.dom';

@Injectable({ providedIn: 'root' })
export class ListMedicalPrescryptionsByTypeUseCase implements UseCase<MedicalOrderType, Promise<Result<MedicalPrescriptionResponseDom[], Failure>>> {
    constructor(private _medicalPrescriptionRepository: MedicalPrescriptionRepository) { }
    execute = (params: MedicalOrderType): Promise<Result<MedicalPrescriptionResponseDom[], Failure>> => this._medicalPrescriptionRepository.list(params);
}