import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-ts';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subject } from 'rxjs';
import { PasswordLocalStorage, NamesLocalStorage } from './passwordlocalstorage';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  onChangeCurrenUser = new Subject<any>();

  constructor(
    @Inject(LOCAL_STORAGE) private storageService: StorageService  ) {}

  /**
   * SET CURRENT USER
   */
  saveCurentUserLocalStorage(user: any): void {
    const usuario = JSON.stringify(user);
    const currentUserCrypt: any = CryptoJS.AES.encrypt( usuario, PasswordLocalStorage.PasswordLocalStorage );
    this.storageService.set( NamesLocalStorage.CurrentAccountUser, currentUserCrypt );
    this.onChangeCurrenUser.next(user);
  }

  getCurrentUserLocaStorage(): any {
    const currentUser = this.storageService.get(NamesLocalStorage.CurrentAccountUser);
    const bytesUsuario = CryptoJS.AES.decrypt( currentUser, PasswordLocalStorage.PasswordLocalStorage );
    const decryptedDataCurrentAccount = JSON.parse( bytesUsuario.toString(CryptoJS.enc.Utf8) );
    return decryptedDataCurrentAccount;
  }

  saveRolLocalStorage(rol: any): void {
    const rolString = JSON.stringify(rol);
    const crypt: any = CryptoJS.AES.encrypt(rolString, PasswordLocalStorage.PasswordLocalStorage);
    localStorage.setItem(NamesLocalStorage.Rol, crypt)
  }

  getRolLocaStorage(): any {
    const rol = this.storageService.get( NamesLocalStorage.Rol );
    const bytes = CryptoJS.AES.decrypt( rol, PasswordLocalStorage.PasswordLocalStorage );
    const decryptedData = JSON.parse( bytes.toString(CryptoJS.enc.Utf8) );
    return decryptedData;
  }

  clearByKey(key: string): void {
    if (this.verificateExistLocalStorage(key)) {
      this.storageService.remove(key);
    }
  }

  verificateExistLocalStorage(key: string): boolean {
    try {
      const verificate = this.storageService.get(key);
      return verificate ? true : false;
    } catch (ex) {
      return false;
    }
  }

  logout(): void {
    this.storageService.remove(NamesLocalStorage.Rol);
    this.storageService.remove(NamesLocalStorage.CurrentAccountUser);
    this.storageService.remove(NamesLocalStorage.idToken);
  }
}
