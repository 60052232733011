import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const AUTH_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../../core/authentication/authentication.module').then(m => m.AuthenticationModule)
},
{
  path: 'error',
  loadChildren: () => import('../../core/not-found/not-found.module').then(m => m.NotFoundModule)
}
];
