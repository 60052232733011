export class ExcelUtil {
  static generate(name: string, blob: Blob) {
    var newBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    //window.open(data, '_blank')
    var link = document.createElement("a");
    link.href = data;
    //window.open(link, '_blank')
    link.download = name + ".xlsx";
    link.click();
    setTimeout(() => window.URL.revokeObjectURL(data), 100);
  }
}
