import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { CreatePregnancyRequestDom, UpdatePregnancyRequestDom } from 'domain/consultation/pregnancy/models/pregnancy.request.dom';
import { PregnancyResponseDom } from 'domain/consultation/pregnancy/models/pregnancy.response.dom';
import { PregnancyRepository } from 'domain/consultation/pregnancy/repositories/pregnancy.repository';
import { environment } from 'environments/environment';
import { PregnancyResponseDto } from './dtos/pregnancy.dto';
import { PregnancyMapper } from './mappers/pregnancy.mapper';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PregnancyImplRepository extends BaseApi implements  PregnancyRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
  create(request: CreatePregnancyRequestDom): Promise<Result<PregnancyResponseDom, Failure>> {
    return this.http
    .post<PregnancyResponseDto>(`${this.baseUrl}/v1/Gynecological_Pregnancy/Create`, JSON.stringify(PregnancyMapper.toCreateDto(request)))
      .pipe(map( _ => this.baseOk(PregnancyMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<PregnancyResponseDom>(_))).toPromise();
  }
  update(request: UpdatePregnancyRequestDom): Promise<Result<PregnancyResponseDom, Failure>> {
    return this.http
    .put<PregnancyResponseDto>(`${this.baseUrl}/v1/Gynecological_Pregnancy/Update`, JSON.stringify(PregnancyMapper.toUpdateDto(request)))
      .pipe(map( _ => this.baseOk(PregnancyMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<PregnancyResponseDom>(_))).toPromise();
  }
}
