<!-- Filter Datatables -->
<section class="mt-2">
  <!-- ngx-datatable-filter -->
  <div  class="row">
   <div *ngIf="page.sourceData===sourceData.SERVER" [ngClass]="{'col-lg-6': enabledFilter,'col-lg-12 mb-2': !enabledFilter }">
     Mostrar
     <select class="custom-select" style="width: auto" (change)="onLimitChange($event.target.value)">
       <option *ngFor="let option of pageLimitOptions" [value]="option.value"
         [selected]="option.value == currentPageLimit">
         {{option.value}}
       </option>
     </select>
     registros por pagina
   </div>
   <div *ngIf="enabledFilter"  class="col-lg-6 ">
     <fieldset class="form-group ">
       <div class="input-group">
         <input id="ngx-filter-ref{{idSearch}}" class="form-control form-control-sm d-inline-block " [type]="inputType"
           placeholder="Buscar..." (keyup)="filter($event)" />
         <div class="input-group-append">
           <span class="input-group-text" id="basic-addon6"><i class="ft-search"></i></span>
         </div>
       </div>
     </fieldset>
   </div>
 </div>

 <ngx-datatable  *ngIf="page.sourceData===sourceData.LOCAL"  class="bootstrap core-bootstrap" [columns]="columns" [columnMode]="ColumnMode.force"
   [headerHeight]="50" [footerHeight]="50" rowHeight="auto"  [rows]="rows" [count]="page.totalElements"  [limit]="page.itemsPerPage" [scrollbarH]="true"
   [externalPaging]="false" [externalSorting]="true"   (sort)="onSort($event)"   [messages]="{emptyMessage: 'No se encontró información', totalMessage: ' registros'}">
 </ngx-datatable>
 <ngx-datatable  *ngIf="page.sourceData===sourceData.SERVER"  class="bootstrap core-bootstrap" [columns]="columns" [columnMode]="ColumnMode.force"
   (sort)="onSort($event)" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
   [rows]="rows" [scrollbarH]="true"  [externalPaging]="true" [externalSorting]="true"
   [count]="page.totalElements" [offset]="page.currentPage" [limit]="page.itemsPerPage"
   (page)="setPage($event)"   [messages]="{emptyMessage: 'No se encontró información', totalMessage: ' registros'}">
   <!-- <ngx-datatable-column *ngFor="let item of columns"  name="{{item.name}}" prop="{{item.prop}}">
     <ng-template ngx-datatable-cell-template let-value="value"  let-row="row">
       {{ value }}
     </ng-template>
   </ngx-datatable-column> -->
 </ngx-datatable>
 <!-- ngx-datatable-filter -->
</section>
<!--/ Filter Datatables -->
