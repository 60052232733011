import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultationImplRepository } from './consultation-impl.repository';
import { ConsultationRepository } from 'domain/consultation/consultation/repositories/consultation.repository';
import { CreateConsultationUseCase } from 'domain/consultation/consultation/usecases/create-consultation.usecase';
import { UpdateConsultationUseCase } from 'domain/consultation/consultation/usecases/update-consultation.usecase';
import { ListConsultationsUseCase } from 'domain/consultation/consultation/usecases/list-consultation.usecase';
import { CompleteConsultationUseCase } from 'domain/consultation/consultation/usecases/complete-consultation.usecase';
import { FindPartialConsultationUseCase } from 'domain/consultation/consultation/usecases/find-partial-consultation.usecase';
import { FindByIdConsultationUseCase } from 'domain/consultation/consultation/usecases/find-by-id-consultation.usecase';

export const consultationRepositoryProvider = { provide: ConsultationRepository, useClass: ConsultationImplRepository }
@NgModule({
  providers: [
    consultationRepositoryProvider,
    CreateConsultationUseCase,
    UpdateConsultationUseCase,
    ListConsultationsUseCase,
    CompleteConsultationUseCase,
    FindPartialConsultationUseCase,
    FindByIdConsultationUseCase
  ],
  imports: [
    CommonModule
  ]
})
export class ConsultationInfModule { }
