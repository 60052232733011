import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER } from "@angular/core";
import {
  ConfigService,
  configServiceFactory,
} from "./shared/api/config.service";
import { AuthGuard } from "./shared/auth/auth.guard";
import { CacheInterceptor } from "./shared/auth/cache.interceptor";
import { LoginGuard } from "./shared/auth/login.guard";
import { SecurityInterceptor } from "./shared/auth/security.interceptor";
import { LocalStorageService } from "./shared/localstorage/localstorage.service";
import { SweetAlertService } from "./shared/services/sweet-alert.service";
import { NavigationService } from "./shared/services/navigation.service";

export const AppProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true,
  },
  ConfigService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SecurityInterceptor,
    multi: true,
  },
  LocalStorageService,
  SweetAlertService,
  NavigationService,
  AuthGuard,
  LoginGuard,
  {
    provide: APP_INITIALIZER,
    useFactory: configServiceFactory,
    deps: [ConfigService],
    multi: true,
  },
];
