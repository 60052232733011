import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { ConsultationResponseDom } from '../models/consultation.response.dom';
import { CreateConsultationRequestDom } from '../models/consultation.request.dom';
import { ConsultationRepository } from '../repositories/consultation.repository';

@Injectable({ providedIn: 'root' })
export class CreateConsultationUseCase implements UseCase<CreateConsultationRequestDom, Promise<Result<ConsultationResponseDom, Failure>>> {
    constructor(private _consultationRepository: ConsultationRepository) { }
    execute = (params: CreateConsultationRequestDom): Promise<Result<ConsultationResponseDom, Failure>> => this._consultationRepository.create(params);
}