import { MedicalOrdersRoutings } from 'presentation/app/core/administration/medical-orders/medical-orders-routing.module';
import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';
import { MedicationsRoutings } from 'presentation/app/core/administration/medications/medications-routing.module';
import { PatientRoutings } from 'presentation/app/core/home/patient/patient-routing.module';

export const ROUTES: RouteInfo[] = [
  {
    path: '/medicaloffice/core/admin', title: 'menu.administration.title', icon: 'ft-settings', class: 'dropdown nav-item has-sub', isExternalLink: false, submenu: [
      { path: `${MedicalOrdersRoutings.MAIN}`, title: 'menu.administration.medicalOrder', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: `${MedicationsRoutings.MAIN}`, title: 'menu.administration.medicines', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: PatientRoutings.MAIN, title: 'menu.patients', icon: 'ft-users', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  },
  {
    path: '/medicaloffice/core/reports', title: 'menu.reports', icon: 'ft-bar-chart-2', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  }
];
