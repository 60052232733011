import { BasePaginationRequest } from "base/pagination/pagination"

export class CreatePatientRequestDom {
    constructor(public params:
      CommonPatientParams
      ) {}
}

export class UpdatePatientRequestDom {
  constructor(public params:
    { id: number } & CommonPatientParams
    ) {}
}
export class PageablePatientsRequestDom {
  constructor(public params: BasePaginationRequest) {}
}

interface CommonPatientParams {
  names: string;
  lastNames: string;
  identificationNumber: string;
  dateOfBirth: string;
  phone: string;
  address: string;
  identificationTypeId: number;
  genderId: number;
}