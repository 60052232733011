import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlTree, CanLoad, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IsActiveSessionUseCase } from 'domain/session/usecases/is-active-session.usecase';
import { NoParams } from 'base/usecase/query';
import { NavigationService } from '../services/navigation.service';

@Injectable()

export class LoginGuard implements CanActivate, CanLoad {

  constructor( 
    private _navigation: NavigationService,
    private  _isActiveSessionUseCase: IsActiveSessionUseCase ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
    let isAuthenticated = this._isActiveSessionUseCase.execute(NoParams);
    if(isAuthenticated)
      this._navigation.navigateTo("/");
    return !isAuthenticated
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Promise<boolean | UrlTree> {
    let isAuthenticated = this._isActiveSessionUseCase.execute(NoParams);
    if(isAuthenticated)
      this._navigation.navigateTo("/");
    return !isAuthenticated
  }
}
