<!-- ng-select start -->

<!-- [appendTo]="'body'" Para mostrar esto fuera de este cuadro de diálogo o de cualquier elemento principal, debe adjuntarlo al cuerpo del navegador.-->
<div *ngIf="formGroup" [formGroup]="formGroup">
  <div *ngIf="selectType===selectTypeResource.SELECT" class="form-group mb-0">
    <i *ngIf="showIcon" [ngClass]="icon"></i> <label *ngIf="title">{{required ? '*':''}}  {{title}}</label>
      <!--USE bindValue="value" para capturar el value-->
      <!-- <ng-select id="idSelect" (change)="_onChange($event)" appendTo="body" [items]="items"  bindLabel="name" bindValue="value"   [placeholder]="placeholder" [(ngModel)]="defaultValue" [formControlName]="formControlName" >
      </ng-select> -->
      <!--Using ng-option and for loop-->
      <ng-select [searchable]="searchable" [notFoundText]="'No se encontraron resultados'" [appendTo]="appendTo" (change)="_onChange($event)"  [(ngModel)]="defaultValue" [formControlName]="formControlName">
        <ng-option *ngFor="let item of items" [value]="item.value">{{item.name}}</ng-option>
      </ng-select>
      <!-- <p class="text-italic font-small-2 mt-1">
        Selected city: {{selectedCity | json}}
      </p> -->
  </div>

  <div *ngIf="selectType===selectTypeResource.MULTISELECT" class="form-group mb-1">
    <i *ngIf="showIcon" [ngClass]="icon"></i> <label *ngIf="title">{{required ? '*':''}}  {{title}}</label>
    <!-- <ng-select  id="idSelect"  (change)="_onChange($event)" [items]="items" bindLabel="name" bindValue="value" [multiple]="true" [closeOnSelect]="false" [formControlName]="formControlName"
    [placeholder]="placeholder" appendTo="body" [(ngModel)]="defaultValue">
    </ng-select> -->
    <ng-select [searchable]="searchable" [notFoundText]="'No se encontraron resultados'" [appendTo]="appendTo" (change)="_onChange($event)" [closeOnSelect]="false"  [(ngModel)]="defaultValue" [multiple]="true" [formControlName]="formControlName">
      <ng-option *ngFor="let item of items" [value]="item.value">{{item.name}}</ng-option>
    </ng-select>
    <!-- <p class="text-italic font-small-2 mt-1">
      Selected cities: {{selectedCityIds | json}}
    </p> -->
  </div>
  <div *ngFor="let error of showErrorsService.listOfErrors(formGroup.get(formControlName))">
    <ng-container *ngIf="showTextError">
      <small><span class="badge badge-pill bg-light-danger badge-round"> <i class="ft-info"></i> {{error}}</span></small>
    </ng-container>
  </div>
  <div *ngIf="formGroupNameError">
    <ng-container
        *ngFor="let error of showErrorsService.listOfErrors(formGroupNameError ? formGroupValidator.get([formGroupNameError]):formGroupValidator.get(''))">
        <small><span class="badge badge-pill bg-light-danger badge-round"> <i class="ft-info"></i> {{error}}</span></small>
    </ng-container>
</div>
</div>


<div *ngIf="!formGroup">
<div *ngIf="selectType===selectTypeResource.SELECT" class="form-group mb-1">
  <i *ngIf="showIcon" [ngClass]="icon"></i> <label *ngIf="title"> {{title}}</label>
    <!-- <ng-select (change)="_onChange($event)" appendTo="body" [items]="items" bindLabel="name" bindValue="value"   [placeholder]="placeholder" [(ngModel)]="defaultValue"  >
    </ng-select> -->
    <ng-select #select [searchable]="searchable" [notFoundText]="'No se encontraron resultados'" [appendTo]="appendTo" (change)="_onChange($event)" [closeOnSelect]="false"  [(ngModel)]="defaultValue">
      <ng-option *ngFor="let item of items" [value]="item.value">{{item.name}}</ng-option>
    </ng-select>
</div>
<div *ngIf="selectType===selectTypeResource.MULTISELECT" class="form-group mb-1">
  <i *ngIf="showIcon" [ngClass]="icon"></i> <label *ngIf="title"> {{title}}</label>
  
  <!-- <ng-select (change)="_onChange($event)" appendTo="body" [items]="items" bindLabel="name" bindValue="value" [multiple]="true" [closeOnSelect]="false"
  [placeholder]="placeholder" [(ngModel)]="defaultValue">
  </ng-select> -->
  <ng-select #select [searchable]="searchable" [notFoundText]="'No se encontraron resultados'" [appendTo]="appendTo" (change)="_onChange($event)" [closeOnSelect]="false"  [(ngModel)]="defaultValue" [multiple]="true">
    <ng-option *ngFor="let item of items" [value]="item.value">{{item.name}}</ng-option>
    <ng-template ngIf="enabledAll" ng-header-tmp>
      <button (click)="selectAll(select)" class="btn btn-sm btn-raised bg-success-300 mr-2" [disabled]="items.length == 0">
        <i class="ft-plus-circle"></i> Seleccionar todos
      </button>
      <button (click)="unselectAll(select)" class="btn btn-sm btn-raised bg-secondary-500" [disabled]="defaultValue == null || defaultValue.length == 0">
        <i class="ft-trash-2"></i> Limpiar
      </button>
    </ng-template>
  </ng-select>
</div>
</div>
