import { UseCase } from 'base/usecase/use-case';
import { LoginResponseDom } from '../../models/login-response.dom';
import { LoginRequestDom } from '../../models/login-request.dom';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { AuthRepository } from '../../repositories/auth.repository';
import { SessionRepository } from 'domain/session/repositories/session.repository';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthLoginUseCase implements UseCase<LoginRequestDom, Promise<Result<LoginResponseDom, Failure>>> {
    constructor(private _userRepository: AuthRepository,
    private _sessionRepository: SessionRepository) { }
    async execute(params: LoginRequestDom): Promise<Result<LoginResponseDom, Failure>> {
        var response = await this._userRepository.login(params)
        if (response.isRight)
            this._sessionRepository.save(response.value)
        return response;
        /* return this._userRepository.login(params)
        .pipe(map((data) => {
            if (data.isRight()) 
                this._sessionRepository.save(data.value)
            return data;
        })); */
    }
}