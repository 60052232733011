import { Component, HostListener, Inject, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Failure } from "base/failure/failure";
import { LoginRequestDom } from "domain/auth/models/login-request.dom";
import { LoginResponseDom } from "domain/auth/models/login-response.dom";
import { AuthLoginUseCase } from "domain/auth/usecases/auth/auth-login.usecase";
import { BaseComponent } from "presentation/app/shared/components/base/base-component";
import { GenericLoadingService } from "presentation/app/shared/components/generic-loading/generic-loading.service";
import { NavigationService } from "presentation/app/shared/services/navigation.service";
import { SweetAlertService } from "presentation/app/shared/services/sweet-alert.service";
import { WINDOW } from "presentation/app/shared/services/window.service";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPageComponent extends BaseComponent{  
  formGroupLogin:FormGroup;
  // CARRUSEL
  showNavigationArrows = true;
  showNavigationIndicators = false;
  images = [1, 2, 3].map((n) => `${this.assets}/img/slider/s${n}.jpg`);
  constructor(
    @Inject(WINDOW) private window: Window,
    public translate: TranslateService,
    private genericLoadingService:GenericLoadingService,
    private _navigation: NavigationService,
    private _formBuilder: FormBuilder,
    _route: ActivatedRoute,
    private _sweetAlertService: SweetAlertService,
    config: NgbCarouselConfig,
    private _authLoginUseCase: AuthLoginUseCase,
    private _traslateService: TranslateService
  ) {
    super(_route);
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  onInit(): void {
    this.formGroupLogin= this.createLoginForm();
  }

  createLoginForm(): FormGroup {
    return this._formBuilder.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
      rememberme: [true],
    });
  }
  
  onAfterViewInit(): void {
    this.changeheight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.changeheight();
  }

  changeheight(){
    const changeHeightSlide = Array.from(document.getElementsByClassName('carousel-size') as HTMLCollectionOf<HTMLElement>)
    changeHeightSlide.forEach((element) => element.style.height = `${window.innerHeight}px`);
  }
  // On submit button click
  async onLogin(event: any) {
    this.genericLoadingService.show()
    var result = await this._authLoginUseCase.execute(new LoginRequestDom(this.formGroupLogin.getRawValue()));
    result.fold(
      (_: LoginResponseDom) => this._navigation.navigateTo("/"),
      (error: Failure) => this.messageError(error))
    this.genericLoadingService.hide();
  }

  messageError(failure: Failure) {
    switch (failure.key) {
      case 'FAILED_USER_NOT_FOUND':
        this._sweetAlertService.showToastInfo(this._traslateService.instant("login.errors.accountNotFound"));
      break;
      case 'FAILED_EMAIL_NOT_CONFIRMED':
        this._sweetAlertService.showToastInfo(this._traslateService.instant("login.errors.emailNotConfirmed"));
      break;
      case 'FAILED_UNSUPORTED_ROL_USER':
        this._sweetAlertService.showToastInfo(this._traslateService.instant("login.errors.roleInvalid"));
      case 'FAILED_USERNAME_OR_PASWORD_INVALID':
        this._sweetAlertService.showToastInfo(this._traslateService.instant("login.errors.credentialsInvalid"));
      break;
      default:
        this._sweetAlertService.showToastInfo(this._traslateService.instant("errors.unknowError"));
        break;
    }
  }

  onChanges(changes: SimpleChanges): void { }
  onDestroy(): void {} 
}