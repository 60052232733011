import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { StatisticRepository } from '../repositories/statistic.repository';
import { MonthSerie } from '../models/statistics.response.dom';

@Injectable({ providedIn: 'root' })
export class AllConsultationsMonthlyUseCase implements UseCase<{
    year: number,
    monthIds: number[]
}, Promise<Result<MonthSerie[], Failure>>> {
    constructor(private _StatisticRepository: StatisticRepository) { }
    execute = (params: {
        year: number,
        monthIds: number[]
    }): Promise<Result<MonthSerie[], Failure>> => this._StatisticRepository.consultationsMonthly(params.year, params.monthIds);
}