import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreateMedicalOrderRequestDom, UpdateMedicalOrderRequestDom } from 'domain/administration/medical-order/models/medical-order.request.dom';
import { BaseComponent } from 'presentation/app/shared/components/base/base-component';
import { ItemSelect } from 'presentation/app/shared/components/generic-select/generic-select.component';
import Chart from 'chart.js/auto';
import { years, months, currentMonth, currentYear, findMonthName, findMonthColor } from './data'
import { ChartLabel, DataSet, lineChartDataSet, lineChartLabels, lineChartOptions, lineChartType } from './line-chart-settings';
import { CHART_COLORS } from './chart-color';
import { AllConsultationsAnnualUseCase } from 'domain/statistics/usecases/all-consultation-annual.usecase';
import { AllConsultationsMonthlyUseCase } from 'domain/statistics/usecases/all-consultation-monthly.usecase';
import { AnualSerie, MonthSerie } from 'domain/statistics/models/statistics.response.dom';
import { GenericLoadingService } from 'presentation/app/shared/components/generic-loading/generic-loading.service';
import { AllConsultationsAnnualReportUseCase } from 'domain/statistics/usecases/all-consultation-annual-report.usecase';
import { AllConsultationsMonthlyReportUseCase } from 'domain/statistics/usecases/all-consultation-monthly-report.usecase';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { ExcelUtil } from 'base/utils/excel.util';
@Component({
  selector: 'app-statistics-graph-annual',
  templateUrl: './statistics-graph-annual.component.html',
  styleUrls: ['./statistics-graph-annual.component.scss']
})
export class StatisticsGraphAnnualComponent extends BaseComponent {
  public chart: any;
  CHART_TYPE = CHART_TYPE.MONTHLY

  @Output() onCancelEvent= new EventEmitter<any>();
  @Output() onCreateEvent= new EventEmitter<CreateMedicalOrderRequestDom>();
  @Output() onUpdateEvent = new EventEmitter<UpdateMedicalOrderRequestDom>();
  @Input() _modalParameters;

  listDataYears: ItemSelect[] = [];
  listDataMonths: ItemSelect[] = [];
  selectedYear = currentYear
  selectedMonth = [currentMonth]
  constructor(
    _route: ActivatedRoute,
    private _allConsultationsAnnualUseCase: AllConsultationsAnnualUseCase,
    private _allConsultationsMonthlyUseCase: AllConsultationsMonthlyUseCase,
    private _allConsultationsAnnualReportUseCase: AllConsultationsAnnualReportUseCase,
    private _allConsultationsMonthlyReportUseCase: AllConsultationsMonthlyReportUseCase,
    private _loading:GenericLoadingService
  ) {
    super(_route);
  }
  onInit(): void {
    this.listDataYears = this.listDataYears.concat(years);
    this.listDataMonths = this.listDataMonths.concat(months);
    this.load()
  }

  load() {
    this.createChartMonth()
  }
  onAfterViewInit(): void { }
  
  genLabelsDays = (): ChartLabel[] => Array.from({ length: 31 }, (_, index) => ({ label: (index + 1).toString() }))
  genLabelsMonths = (): ChartLabel[] => months.map(a => <ChartLabel>{ label: a.name })

  async createChartMonth() {
    this._loading.show()
    let result = await this._allConsultationsMonthlyUseCase.execute({
      year: this.selectedYear,
      monthIds: this.selectedMonth
    })
    result.foldRight((value: MonthSerie[]) => {
      let datasets = value.map(e => <DataSet>{
        label: e.label,
        color: findMonthColor(Number(e.key)),
        data: e.data.map(a=>a.value)
      })
      this.buildChart(
        this.genLabelsDays(),
        datasets,
        `Reporte de consultas de mes(es) de ${findMonthName(this.selectedMonth)} de ${this.selectedYear}`,
        `Dias del mes(es) de ${findMonthName(this.selectedMonth)} de ${this.selectedYear}`
      )
    })
    this._loading.hide()
  }

  async createChartYear() {
    this._loading.show()
    let result = await this._allConsultationsAnnualUseCase.execute(this.selectedYear)
    result.foldRight((value: AnualSerie[]) => {
      this.buildChart(
        this.genLabelsMonths(),
        [{ label: "Cantidad por mes", data: value.map(a=>a.value), color: CHART_COLORS.orange}],
        `Reporte de consultas del año ${this.selectedYear}`,
        `Meses del año ${this.selectedYear}`
      )
    })
    this._loading.hide()
  }

  private buildChart(
    labels: ChartLabel[],
    data: DataSet[],
    title: string,
    xTitle: string
  ) {
    this.chart?.destroy();
    this.chart = new Chart("report_chart", {
      type: lineChartType, //this denotes tha type of chart
      data: {
        labels: lineChartLabels(labels), 
	      datasets: lineChartDataSet(data)
      },
      options: lineChartOptions({
        title: title,
        xTitle: xTitle,
        yTitle: 'Consultas'
      })
    });
  }

  cleanChart(event: ItemSelect | ItemSelect[]): boolean {
    if (!event || (Array.isArray(event) && event.length == 0)) {
      this.chart?.destroy();
      this.chart = null
      return true 
    }
    return false;
  }

  onChangeMonth(event: ItemSelect[]) {
    if(this.cleanChart(event)) return;
    this.CHART_TYPE - CHART_TYPE.MONTHLY
    this.selectedMonth = event.map(a=>a.value)
    this.createChartMonth()
  }
  onChangeAllMonth(_: ItemSelect[]) {
    this.CHART_TYPE - CHART_TYPE.ANNUAL
    this.createChartYear()
  }
  onChangeYear(event: ItemSelect) {
    if(this.cleanChart(event)) return;
    this.selectedYear = event.value
    this.selectedMonth = [currentMonth]
    this.CHART_TYPE - CHART_TYPE.MONTHLY
    this.createChartMonth()
  }

  async onGenerateReport() {
    this._loading.show()
    let result:  Result<Blob, Failure>;
    if (this.CHART_TYPE == CHART_TYPE.ANNUAL) 
      result = await this._allConsultationsAnnualReportUseCase.execute(this.selectedYear)
    else 
      result = await this._allConsultationsMonthlyReportUseCase.execute({
        year: this.selectedYear,
        monthIds: this.selectedMonth
      })
    result.foldRight((value: Blob) => {
      let name = this.CHART_TYPE == CHART_TYPE.ANNUAL ?
        `reporte_anual_${this.selectedYear}` :
        `reporte_mensual_(${this.nameFormat(findMonthName(this.selectedMonth))})_${this.selectedYear}`
      ExcelUtil.generate(name, value)
    })
    this._loading.hide()
  }

  nameFormat(texto: string): string {
    const textSpace = texto.toLowerCase();
    const result = textSpace.replace(/,/g, '_');
    return result;
  }
  
  onCancel = () => this.onCancelEvent.emit()
  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void {}
}

enum CHART_TYPE{
  ANNUAL,
  MONTHLY
}