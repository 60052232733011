import { FormGroup } from '@angular/forms';
import * as CryptoJS from 'crypto-ts';
import { ItemList } from '../api/pediakids.model';
import { ItemSelect } from '../components/generic-select/generic-select.component';

export abstract class Utils {

  static isEmptyObject(obj) {
    if (obj && Object.keys(obj).length !== 0) {
      return false;
    }
    return true;
  }
  static getDateNow() {
    let date = new Date(); //Almacena fecha actual
    return date;
    /* return  new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    ); */
  }

  static getDateNowOnlyYYYYMMDD() {
    let date = this.getDateNow(); //Almacena fecha actual
    return  new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
    );
  }

  static decrypt(value:string):string{
    const  valueCustom = value.replace('&&','/');
    let key:string = "5TGB&YHN7UJM(IK<5TGB&YHN7UJM(IK<";
    let iv:string = "!QAZ2WSX#EDC4RFV";
    var dataDecrypt = CryptoJS.AES.decrypt(
      valueCustom,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.PKCS7
      }
    );
    let data= dataDecrypt.toString(CryptoJS.enc.Utf8)
    return data;
  }

  static encrypt(value:string):string{
    let key:string = "5TGB&YHN7UJM(IK<5TGB&YHN7UJM(IK<";
    let iv:string = "!QAZ2WSX#EDC4RFV";
    var dataDecrypt = CryptoJS.AES.encrypt(
      value,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.PKCS7
      }
    );
    return dataDecrypt.toString();
  }


  static mapItemSelect(items: any, value: string = "Id", name : string = "Name", valueSelected: any = null):ItemSelect[] {
    if(items && items.length>0){
      return  items.map((item)=>{
        const response: ItemSelect={
          value:item[value],
          name: item[name].toString(),
          data:item,
          selected: item[value] == valueSelected? true:false
        }
        return response;
      });
    }else{
      return []
    }
  }
  static filterTipo(filter:String): string {
    return `TypeList/Name eq '${filter}'`;
  }


  static filterInTipos(filter:String[]): string {
    let query=""
    if(filter.length>0){
      query = filter.map(e=>`'${e}'`).join(',');
    }
    return `TypeList/Name in(${query})`;
  }


  static getParametrosByNombre(name:string, parametros:ItemList[]):ItemList[] {
    return parametros && parametros.length>0 ? parametros.filter(a=>a?.TypeList?.Name==name):[];
  }

  static getDateMayorEdadUtc() {
    let mDateNow = Utils.getDateNow(); //Almacena fecha actual
    return new Date(
        Date.UTC(
            mDateNow.getFullYear()-18,
            mDateNow.getMonth(),
            mDateNow.getDate(),
            mDateNow.getHours(),
            mDateNow.getMinutes(),
            mDateNow.getSeconds(),
            mDateNow.getMilliseconds()
        )
    );
  }

  static markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}




export enum CrudOperation {
  VIEW_RECORD = 'view_record',
  ADD_RECORD = 'add_record',
  EDIT_RECORD = 'edit_record',
  DELETE_RECORD = 'delete_record',
}



export enum ITEM_TYPE {
  TIPO_IDENTIFICACION = 'TIPO_IDENTIFICACION',
  GENERO = 'GENERO',
}
