
export interface Pagination {
    sortBy?:string,
    sortDir?:SORT_DIR,
    currentPage: number,
    itemsPerPage:number,
    totalCount: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPageNumber?:number,
}

export interface BasePaginationRequest {
    pagination:Pagination,
    search:string
}
  
export enum SORT_DIR {
    DESC = "desc",
    ASC = "asc"
}  