import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'presentation/app/shared/auth/auth.guard';
import { StatisticMainComponent } from './statistic-main.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: StatisticMainComponent,
    data: {
      title: 'Page',
      roles: ['Authenticated'],
    }
  },
  {
    path: 'graph-annual',
    loadChildren: () => import('./statistics-graph-annual/statistics-graph-annual.module').then( (modulo) => modulo.StatisticsGraphAnnualModule ),
    canLoad: [ AuthGuard ]
  },
  {
    path: 'panel-consolidate',
    loadChildren: () => import('./statistics-panel-consolidated/statistics-panel-consolidated.module').then( (modulo) => modulo.StatisticsPanelConsolidatedModule ),
    canLoad: [ AuthGuard ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatisticsRoutingModule { }


export class StatisticsRoutings {
  public static MAIN: string = "/medicaloffice/core/statistics";
  public static GRAPH_ANNUAL: string = "/medicaloffice/core/statistics/graph-annual";
  public static PANEL_CONSOLIDATE: string = "/medicaloffice/core/statistics/panel-consolidate";
}
