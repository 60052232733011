import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationService } from 'presentation/app/shared/services/navigation.service';
@Component({
  selector: 'app-generic-card-header',
  templateUrl: './generic-card-header.component.html',
  styleUrls: ['./generic-card-header.component.scss']
})
export class GenericCardHeaderComponent implements OnInit {

  @Output() onEventClick = new EventEmitter<any>();
  @Input() showBtnBack: Boolean = false;
  @Input() showBtn:Boolean=true;

  @Input() title: String = "";
  @Input() btnIcon: String = "ft-plus";
  @Input() btnText: String = "actions.create";

  constructor(private _navigation: NavigationService,
    ) { }
  ngOnInit(): void {}
  onClick() {
    this.onEventClick.emit();
  }
  onBack =() => this._navigation.back()
}