<div class="row">
  <div class="col-12">
    <div class="content-header">Estadisticas Generales 
      <app-generic-button [class]="'bg-light-success btn-icon round mr-1 mb-1'"  [title]="''" [icon]="'ft-refresh-ccw
      '" (onClick)="load()"></app-generic-button></div>
  </div>
</div>

<div class="row">
  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 success">{{ consolidated?.totalPatients }}</h3>
              <span>Total Pacientes</span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-user success font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">{{ consolidated?.totalConsultations }}</h3>
              <span>Total Consultas</span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-pie-chart warning font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>