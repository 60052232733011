import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-generic-option-item',
  templateUrl: './generic-option-item.component.html',
  styleUrls: ['./generic-option-item.component.scss']
})
export class GenericOptionItemComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() title: string = "Edit";
  @Input() icon: string = "ft-edit-2";

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.eventClick.emit()
  }

}
