import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { CreateClinicalHistoryRequestDom, UpdateClinicalHistoryRequestDom } from 'domain/consultation/clinical-history/models/clinical-history.request.dom';
import { ClinicalHistoryResponseDom } from 'domain/consultation/clinical-history/models/clinical-history.response.dom';
import { ClinicalHistoryRepository } from 'domain/consultation/clinical-history/repositories/clinical-history.repository';
import { environment } from 'environments/environment';
import { ClinicalHistoryResponseDto } from './dtos/clinical-history.dto';
import { ClinicalHistoryMapper } from './mappers/clinical-history.mapper';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClinicalHistoryImplRepository extends BaseApi implements  ClinicalHistoryRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
  create(request: CreateClinicalHistoryRequestDom): Promise<Result<ClinicalHistoryResponseDom, Failure>> {
    return this.http
    .post<ClinicalHistoryResponseDto>(`${this.baseUrl}/v1/MedicalHistory/Create`, JSON.stringify(ClinicalHistoryMapper.toCreateDto(request)))
      .pipe(map( _ => this.baseOk(ClinicalHistoryMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<ClinicalHistoryResponseDom>(_))).toPromise();
  }
  update(request: UpdateClinicalHistoryRequestDom): Promise<Result<ClinicalHistoryResponseDom, Failure>> {
    return this.http
    .put<ClinicalHistoryResponseDto>(`${this.baseUrl}/v1/MedicalHistory/Update`, JSON.stringify(ClinicalHistoryMapper.toUpdateDto(request)))
      .pipe(map( _ => this.baseOk(ClinicalHistoryMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<ClinicalHistoryResponseDom>(_))).toPromise();
  }
}

