import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Left, Result } from 'base/result/result';
import { FrecuenceDom, ItemListDom } from 'domain/item-list/models/item-list.dom';
import { ItemListRepository } from 'domain/item-list/repositories/item-list.repository';
import { environment } from 'environments/environment';
import { ItemListMapper } from './mappers/item-list.mapper';
import { catchError, map } from 'rxjs/operators';
import { TYPE_LIST } from 'domain/item-list/constants/type-list.const';
import { ItemListResponseDto } from './dtos/item-list.dto';

@Injectable({
  providedIn: 'root'
})
export class ItemListImplRepository extends BaseApi implements ItemListRepository {
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  }
  async listFrecuences(): Promise<Result<FrecuenceDom[], Failure>> {
    return this.http
    .get<FrecuenceDom[]>(`${this.baseUrl}/v1/ItemList/GetItemListByTypeName?typeName=${TYPE_LIST.FRECUENCE_MEDICATION}`)
    .pipe(map( _ => this.baseOk( _.map(ItemListMapper.toFrecuenceDom))))
    .pipe(catchError((_) => this.baseCatch<FrecuenceDom[]>(_))).toPromise();
  }
  
  listByTypeName(typeName: TYPE_LIST): Promise<Result<ItemListDom[], Failure>> {
    return this.http
      .get<ItemListResponseDto[]>(`${this.baseUrl}/v1/ItemList/GetItemListByTypeName?typeName=${typeName}`)
      .pipe(map( _ => this.baseOk( _.map(ItemListMapper.toDom))))
      .pipe(catchError((_) => this.baseCatch<ItemListDom[]>(_))).toPromise();
  }
}
