import { Component, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CreateMedicalOrderRequestDom, UpdateMedicalOrderRequestDom } from "domain/administration/medical-order/models/medical-order.request.dom";
import { CreateMedicalOrderUseCase } from "domain/administration/medical-order/usecases/create-medical-order.usecase";
import { UpdateMedicalOrderUseCase } from "domain/administration/medical-order/usecases/update-medical-order.usecase";
import { BaseComponent } from "presentation/app/shared/components/base/base-component";
import { SweetAlertService } from "presentation/app/shared/services/sweet-alert.service";
import { MedicalOrderListService } from "../medical-order-list/medical-order-list.service";

@Component({
  selector: "app-medical-order-modal",
  templateUrl: "./medical-order-modal.component.html",
  styleUrls: ["./medical-order-modal.component.scss"],
})
export class MedicalOrderModalComponent extends BaseComponent {
  constructor(
    _route: ActivatedRoute,
    private _createMedicalOrderUseCase: CreateMedicalOrderUseCase,
    private _updateMedicalOrderUseCase: UpdateMedicalOrderUseCase,
    private _medicalOrderListService: MedicalOrderListService,
    private _notify: SweetAlertService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
  ) {
    super(_route);
  }
  onInit(): void {}
  onAfterViewInit(): void { }
  async onCreate(request: CreateMedicalOrderRequestDom) {
    let result = await this._createMedicalOrderUseCase.execute(request)
    result.fold((_) => this.successResponse(), (_) => this.errorResponse('error.create'))
  }
  async onUpdate(request: UpdateMedicalOrderRequestDom) {
    let result = await this._updateMedicalOrderUseCase.execute(request)
    result.fold((_) => this.successResponse(), (_) => this.errorResponse('error.update'))
  }

  successResponse = () => {
    this._notify.showToastSucces()
    this._medicalOrderListService.refresh()
    this.activeModal.close()
  }

  errorResponse = (key: string) => this._notify.showAlertError(this.translate.instant('alerts.title'),this.translate.instant(key))

  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void {}
}
