import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseApi } from "base/api/base-api";
import { Failure } from "base/failure/failure";
import { Result } from "base/result/result";
import { ReportRepository } from "domain/statistics/repositories/report.repository";
import { environment } from "environments/environment";
import { AnnualRequestDto, MonthlyRequestDto } from "./dtos/statistic.dto";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ReportImplRepository extends BaseApi implements ReportRepository {
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  }

  private _optionsExcell() {
    const options: Object = {
      observe: 'response',
      responseType: "blob",
    };
    return options;
  }
  consultationsMonthly(
    year: number,
    monthIds: number[]
  ): Promise<Result<Blob, Failure>> {
    return this.http
      .post<HttpResponse<Blob>>(
        `${this.baseUrl}/v1/Statistic/ConsultationPerYearAndMonth`,
        <MonthlyRequestDto>{
          months: monthIds,
          year: year,
        },
        this._optionsExcell()
      )
      .pipe(map((_) => {
        console.log("RESPONSE: ",_)
        return this.baseOk(_.body)
      }))
      .pipe(catchError((_) => this.baseCatch<Blob>(_)))
      .toPromise();
  }
  consultationsAnnual(year: number): Promise<Result<Blob, Failure>> {
    return this.http
      .post<HttpResponse<Blob>>(
        `${this.baseUrl}/v1/Statistic/ConsultationPerYear`,
        <AnnualRequestDto>{ year: year },
        this._optionsExcell()
      )
      .pipe(map((_) => {
        console.log("RESPONSE: ",_)
        return this.baseOk(_.body)
      }))
      .pipe(catchError((_) => this.baseCatch<Blob>(_)))
      .toPromise();
  }
}