import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'presentation/app/shared/components/base/base-component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from "@angular/common";
import { ItemSelect } from 'presentation/app/shared/components/generic-select/generic-select.component';
import { AllTypesMedicationsUseCase } from 'domain/item-list/usecases/all-types-medications.usecase';
import { NoParams } from 'base/usecase/query';
import { ItemListDom } from 'domain/item-list/models/item-list.dom';
import { CreateMedicationRequestDom, UpdateMedicationRequestDom } from 'domain/administration/medication/models/medication.request.dom';
import { MedicationResponseDom } from 'domain/administration/medication/models/medication.response.dom';
import { CrudOperation } from 'presentation/app/shared/utils/utils.resource';
import { MastersMapper } from 'presentation/app/shared/utils/masters.mapper.utils';

@Component({
  selector: 'app-medication-form',
  templateUrl: './medication-form.component.html',
  styleUrls: ['./medication-form.component.scss']
})
export class MedicationFormComponent  extends BaseComponent {
  formGroup: FormGroup;
  @Output() onCancelEvent= new EventEmitter<any>();
  @Output() onCreateEvent= new EventEmitter<CreateMedicationRequestDom>();
  @Output() onUpdateEvent = new EventEmitter<UpdateMedicationRequestDom>();
  @Input() _modalParameters;
  listDataType: ItemSelect[] = []

  constructor(
    _route: ActivatedRoute,
    private _location: Location,
    private _formBuilder: FormBuilder,
    private _allTypesMedicationsUseCase: AllTypesMedicationsUseCase
    ) {
    super(_route);
  }
  onInit(): void {
    this.formGroup = this.createForm(this._modalParameters?.data)
    this.load()
  }

  createForm(data: MedicationResponseDom = null): FormGroup {
    return this._formBuilder.group({
      id: [data?.id],
      name: [data?.name, [Validators.required, Validators.minLength(3)]],
      typeId: [data?.typeId, [Validators.required]],
      requiresCalculation: [data?.requiresCalculation ?? false, [Validators.required]]
    });
  }
  
  load(){
    this.getTypesMedications();
  }

  async getTypesMedications() {
    let result = await this._allTypesMedicationsUseCase.execute(NoParams)
    result.foldRight((value: ItemListDom[]) =>  this.listDataType = MastersMapper.toSelect(value))
  }
  
  onAfterViewInit(): void { }
  onSave() { 
    let data = this.formGroup.getRawValue()
    if (this._crudValue == CrudOperation.EDIT_RECORD)
      this.onUpdateEvent.emit(new UpdateMedicationRequestDom(data))
    else 
      this.onCreateEvent.emit(new CreateMedicationRequestDom(data))
  }
  onCancel = () => this.onCancelEvent.emit()
  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void {}
}
