import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GenericModalService } from "presentation/app/shared/components/generic-modal/generic-modal.service";
import { MedicationFormComponent } from "./medication-form/medication-form.component";
import { MedicationModalComponent } from "./medication-modal/medication-modal.component";
import { CrudOperation } from "presentation/app/shared/utils/utils.resource";

@Component({
  selector: "app-medication-main",
  templateUrl: "./medication-main.component.html",
  styleUrls: ["./medication-main.component.scss"],
})
export class MedicationMainComponent implements OnInit {
  constructor(
    private router: Router,
    private _modalService: GenericModalService
    ) {}
  ngOnInit(): void {}
  onCreate() {
    this._modalService.openModal(MedicationModalComponent, { action: CrudOperation.ADD_RECORD }, null);
  }
}
