
import { UltrasoundResponseDom } from "domain/plans/ultrasound/models/ultrasound.response";
import { CreateUltrasoundRequestDto, UltrasoundResponseDto, UpdateUltrasoundRequestDto } from "../dtos/ultrasound.dto";
import { CreateUltrasoundRequestDom, UpdateUltrasoundRequestDom } from "domain/plans/ultrasound/models/ultrasound.request";

export class UltrasoundMapper {
    static toDom(param: UltrasoundResponseDto): UltrasoundResponseDom {
        return {
            id: param.id,
            ultrasoundDate: param.ultrasoundDate,
            ultrasoundWeeks: param.ultrasoundWeeks,
            currentDate: param.currentDate,
            weeksToday: param.weeksToday,
            consultationId: param.consultationId
        };
    }

    static toCreateDto(param: CreateUltrasoundRequestDom): CreateUltrasoundRequestDto {
        return <CreateUltrasoundRequestDto>{
            ultrasoundDate: param.params.ultrasoundDate,
            ultrasoundWeeks: param.params.ultrasoundWeeks,
            currentDate: param.params.currentDate,
            weeksToday: param.params.weeksToday,
            consultationId: param.params.consultationId
        };
    }

    static toUpdateDto(param: UpdateUltrasoundRequestDom): UpdateUltrasoundRequestDto {
        return <UpdateUltrasoundRequestDto>{
            id: param.params.id,
            ultrasoundDate: param.params.ultrasoundDate,
            ultrasoundWeeks: param.params.ultrasoundWeeks,
            currentDate: param.params.currentDate,
            weeksToday: param.params.weeksToday,
            consultationId: param.params.consultationId
        };
    }
}