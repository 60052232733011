import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MedicalOrderRepository } from 'domain/administration/medical-order/repositories/medical-order.repository';
import { MedicalOrderImplRepository } from './medical-order-impl.repository';
import { CreateMedicalOrderUseCase } from 'domain/administration/medical-order/usecases/create-medical-order.usecase';
import { DeleteMedicalOrderByIdUseCase } from 'domain/administration/medical-order/usecases/delete-medical-order-by-id.usecase';
import { ListMedicalsOrderUseCase } from 'domain/administration/medical-order/usecases/list-medicals-order.usecase';
import { UpdateMedicalOrderUseCase } from 'domain/administration/medical-order/usecases/update-medical-order.usecase';

export const medicalOrderProvider = { provide: MedicalOrderRepository, useClass: MedicalOrderImplRepository }
@NgModule({
  providers: [
    medicalOrderProvider,
    CreateMedicalOrderUseCase,
    DeleteMedicalOrderByIdUseCase,
    ListMedicalsOrderUseCase,
    UpdateMedicalOrderUseCase
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class MedicalOrderInfModule { }
