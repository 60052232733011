import { MedicationResponseDom } from "domain/administration/medication/models/medication.response.dom";
import { CreateMedicationDto, MedicationResponseDto, UpdateMedicationDto } from "../dtos/medication.dto";
import { CreateMedicationRequestDom, UpdateMedicationRequestDom } from "domain/administration/medication/models/medication.request.dom";
import { BaseResponseApi } from "base/api/base-api";
import { Pageable } from "base/pagination/pageable";
import { PaginationMapper } from "base/pagination/pagination-mapper";

export class MedicationMapper {

    static toPageableDom(param: BaseResponseApi<MedicationResponseDto>): Pageable<MedicationResponseDom> {
        return <Pageable>{
            data: param.data.map(MedicationMapper.toDom),
            pagination: PaginationMapper.toPagin(param.paging)
        };
    }

    static toDom(param: MedicationResponseDto): MedicationResponseDom {
        return {
            id: param.id,
            name: param.name,
            typeId: param.routeAdministrationId,
            typeName: param.routeAdministrationIdName,
            requiresCalculation: param.requiresCalculation
        };
    }

    static toCreateDto(param: CreateMedicationRequestDom): CreateMedicationDto {
        return <CreateMedicationDto>{
            name: param.params.name,
            routeAdministrationId: param.params.typeId,
            requiresCalculation: param.params.requiresCalculation
        };
    }

    static toUpdateDto(param: UpdateMedicationRequestDom): UpdateMedicationDto {
        return <UpdateMedicationDto>{
            id: param.params.id,
            name: param.params.name,
            routeAdministrationId: param.params.typeId,
            requiresCalculation: param.params.requiresCalculation
        };
    }
}