<div class="row">
    <div class="col-12">
        <div class="content-header">Reporte Anual</div>
    </div>
</div>

<div class="card">
    <div class="card-content">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4">
                    <app-generic-select [required]="true" [appendTo]="'body'" [items]="listDataYears"
                        [selectType]="selectType.SELECT" [title]="'Año'"
                        [defaultValue]="selectedYear"
                        (onChange)="onChangeYear($event)">
                    </app-generic-select>
                </div>
                <div class="col-lg-4">
                    <app-generic-select [required]="true" [appendTo]="'body'" [items]="listDataMonths"
                        [enabledAll]="true"
                        [selectType]="selectType.MULTISELECT" [title]="'Mes'"
                        [defaultValue]="selectedMonth"
                        (onChange)="onChangeMonth($event)"
                        (onChangeAll)="onChangeAllMonth($event)"
                        >
                    </app-generic-select>
                </div>
                <div class="col-lg-4">
                    <app-generic-button (onClick)="onGenerateReport()" [background]="'bg-success mt-3'"  [disabled]="!chart" [icon]="'fa fa-download'" [title]="'actions.download_report' | translate" [class]="'mr-2'"></app-generic-button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="chart-container">
                        <canvas id="report_chart">{{ chart }}</canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>