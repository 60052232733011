import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { PatientRepository } from '../repositories/patient.repository';
import { CreatePatientRequestDom } from '../models/patient.request.dom';
import { PatientResponseDom } from '../models/patient.response.dom';

@Injectable({ providedIn: 'root' })
export class CreatePatientUseCase implements UseCase<CreatePatientRequestDom, Promise<Result<PatientResponseDom, Failure>>> {
    constructor(private _patientRepository: PatientRepository) { }
    execute = (params: CreatePatientRequestDom): Promise<Result<PatientResponseDom, Failure>> => this._patientRepository.create(params);
}