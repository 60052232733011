import { Component, OnInit } from "@angular/core";
import { PatientRoutings } from "./patient-routing.module";
import { NavigationService } from "presentation/app/shared/services/navigation.service";

@Component({
  selector: 'app-patient-main',
  templateUrl: './patient-main.component.html',
  styleUrls: ['./patient-main.component.scss']
})
export class PatientMainComponent implements OnInit {
  constructor(
    private _navigation: NavigationService,
    ) {}

  ngOnInit(): void {}
  onCreate= () => this._navigation.navigateTo(PatientRoutings.CREATE);
}
