<div class="card-header">
    <!--==================HEADER==================================-->
    <div class="row">
      <div [ngClass]="showBtn ? 'col-lg-6 col-sm-8': 'col-lg-12 col-sm-12'" class=" col-xs-12">
        <h5 class="form-section" style="color: #342E49;">
          <a *ngIf="showBtnBack" (click)="onBack()" style="margin-right: 5px;"><i
              class="ft-arrow-left"></i></a> 
            {{title}}
        </h5>
      </div>
      <div *ngIf="showBtn" class="col-lg-6 text-right ">
        <app-generic-button (onClick)="onClick()" [icon]="btnIcon" [title]="btnText | translate"></app-generic-button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  