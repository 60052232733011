import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateIncapacityUseCase, DeleteIncapacityUseCase, FindIncapacityByIdUseCase, IncapacityRepository, UpdateIncapacityUseCase } from 'domain/incapacity';
import { IncapacityImplRepository } from './incapacity-impl.repository';
import { HttpClientModule } from '@angular/common/http';
import { ListIncapacitiesUseCase } from 'domain/incapacity/usecases/list-incapacities.usecase';


export const incapacityProvider = { provide: IncapacityRepository, useClass: IncapacityImplRepository }
@NgModule({
  providers: [
    incapacityProvider,
    CreateIncapacityUseCase,
    DeleteIncapacityUseCase,
    FindIncapacityByIdUseCase,
    UpdateIncapacityUseCase,
    ListIncapacitiesUseCase
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class IncapacityInfModule { }