<app-generic-datatable
[columns]="columns"
[enabledFilter]="true"
[rows]="data"
[page]="page"
(nextPage)="onNexPageEvent($event)"
(filterEvent)="onFilterEvent($event)"
(sortEvent)="onSortEvent($event)"
(limitPageEvent)="onLimitPageEvent($event)"
>
</app-generic-datatable>
<!--COLUMN  OPTIONS-->
<ng-template
#cellTemplateOptions
let-rowIndex="rowIndex"
let-row="row"
let-value="value"
>
<app-generic-options>
    <app-generic-option-item [title]="'actions.edit' | translate" [icon]="'ft-edit-1'" (eventClick)="onEdit(row)"></app-generic-option-item>
    <app-generic-option-item [title]="'actions.delete' | translate" [icon]="'ft-trash-2'" (eventClick)="onDelete(row)"></app-generic-option-item>
</app-generic-options>
</ng-template>