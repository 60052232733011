export class SessionDom {
    constructor(
        public id: string,
        public fullName: string,
        public phoneNumber: string,
        public roleNames: string[],
        public userName: string,
        public token: string,
        public email?: string,
      ) {}
  }