import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { NoResult } from 'base/usecase/query';
import { CreateMedicineRequestDom, UpdateMedicineRequestDom } from 'domain/plans/medicines/models/medicine.request.dom';
import { MedicineResponseDom } from 'domain/plans/medicines/models/medicine.response.dom';
import { MedicineRepository } from 'domain/plans/medicines/repositories/medicine.repository';
import { environment } from 'environments/environment';
import { MedicineMapper } from './mappers/medicine.mapper';
import { MedicineResponseDto } from './dtos/medicine.dto';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedicinesImplRepository  extends BaseApi implements  MedicineRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
  create(request: CreateMedicineRequestDom): Promise<Result<MedicineResponseDom, Failure>> {
    return this.http
    .post<MedicineResponseDto>(`${this.baseUrl}/v1/Requested_Medication/Create`, JSON.stringify(MedicineMapper.toCreateDto(request)))
      .pipe(map(_ => this.baseOk(MedicineMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicineResponseDom>(_))).toPromise();
  }
  update(request: UpdateMedicineRequestDom): Promise<Result<MedicineResponseDom, Failure>> {
    return this.http
    .put<MedicineResponseDto>(`${this.baseUrl}/v1/Requested_Medication/Update`, JSON.stringify(MedicineMapper.toUpdateDto(request)))
      .pipe(map(_ => this.baseOk(MedicineMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicineResponseDom>(_))).toPromise();
  }
  delete(id: number): Promise<Result<NoResult, Failure>> {
    return this.http
    .delete<any>(`${this.baseUrl}/v1/Requested_Medication/Delete/${id}`)
      .pipe(map(_ => this.baseOk(NoResult)))
      .pipe(catchError(this.baseCatch)).toPromise();
  }
  listByConsultationId(consultationId: number): Promise<Result<MedicineResponseDom[], Failure>> {
    return this.http
    .get<MedicineResponseDto[]>(`${this.baseUrl}/v1/Requested_Medication/GetAllByConsultationId/${consultationId}`)
    .pipe(map(_ => this.baseOk(_.map(MedicineMapper.toDom))))
    .pipe(catchError((_) => this.baseCatch<MedicineResponseDom[]>(_))).toPromise();
  }
}
