import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'presentation/app/shared/auth/auth.guard';
import { MedicalOrderMainComponent } from './medical-order-main.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: MedicalOrderMainComponent,
        data: {
          title: 'Ordenes Medicas',
          roles: ['Authenticated'],
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MedicalOrdersRoutingModule {}
export class MedicalOrdersRoutings {
  public static MAIN: string = "/medicaloffice/administration/medical-orders";
}