
import { CreateMedicineRequestDto, MedicineResponseDto, UpdateMedicineRequestDto } from "../dtos/medicine.dto";
import { MedicineResponseDom } from "domain/plans/medicines/models/medicine.response.dom";
import { CreateMedicineRequestDom, UpdateMedicineRequestDom } from "domain/plans/medicines/models/medicine.request.dom";

export class MedicineMapper {
    static toDom(param: MedicineResponseDto): MedicineResponseDom {
        return {
            id: param.id,
            medicationId: param.medicationId,
            medicationName: param.medicationName,
            dose: param.dose,
            frecuenceId: param.frecuenceId,
            frecuenceName: param.frecuenceName,
            durationDays: param.durationDays,
            quantity: param.quantity,
            observation: param.observation,
            consultationId: param.consultationId
        };
    }

    static toCreateDto(param: CreateMedicineRequestDom): CreateMedicineRequestDto {
        return <CreateMedicineRequestDto>{
            medicationId: param.params.medicationId,
            dose: param.params.dose,
            frecuenceId: param.params.frecuenceId,
            durationDays: param.params.durationDays,
            quantity: param.params.quantity,
            observation:param.params.observation,
            consultationId: param.params.consultationId
        };
    }

    static toUpdateDto(param: UpdateMedicineRequestDom): UpdateMedicineRequestDto {
        return <UpdateMedicineRequestDto>{
            id: param.params.id,
            medicationId: param.params.medicationId,
            dose: param.params.dose,
            frecuenceId: param.params.frecuenceId,
            durationDays: param.params.durationDays,
            quantity: param.params.quantity,
            consultationId: param.params.consultationId,
            observation:param.params.observation,
        }
            
    }
}