import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentSessionUseCase } from 'domain/session/usecases/current-session.usecase';
import { SessionRepository } from 'domain/session/repositories/session.repository';
import { SessionImplRepository } from './session-impl.repository';
import { IsActiveSessionUseCase } from 'domain/session/usecases/is-active-session.usecase';

export const sessionProvider =  { provide: SessionRepository, useClass: SessionImplRepository }
@NgModule({
    providers: [
        CurrentSessionUseCase,
        IsActiveSessionUseCase,
        sessionProvider,
    ],
    imports: [
        CommonModule,
  ],
  exports:[]
})
export class SessionInfModule { }
