import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoParams } from 'base/usecase/query';
import { ConsolidatedDom } from 'domain/statistics/models/statistics.response.dom';
import { GetConsolidatedUseCase } from 'domain/statistics/usecases/get-consolidated.usecase';
import { BaseComponent } from 'presentation/app/shared/components/base/base-component';
import { GenericLoadingService } from 'presentation/app/shared/components/generic-loading/generic-loading.service';

@Component({
  selector: 'app-statistics-panel-consolidated',
  templateUrl: './statistics-panel-consolidated.component.html',
  styleUrls: ['./statistics-panel-consolidated.component.scss']
})

export class StatisticsPanelConsolidatedComponent extends BaseComponent {
  consolidated: ConsolidatedDom;
  constructor(
    _route: ActivatedRoute,
    private _getConsolidatedUseCase: GetConsolidatedUseCase,
    private loading:GenericLoadingService
  ) {
    super(_route);
  }
  onInit(): void {
    this.load()
  }

  async load() {
    this.loading.show()
    let result = await this._getConsolidatedUseCase.execute(NoParams);
    result.foldRight(value => this.consolidated = value)
    this.loading.hide()
  }
  onAfterViewInit(): void { }
  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void {}
}