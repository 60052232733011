import { Result } from "base/result/result";
import { CreateMedicationRequestDom, PageableMedicationsRequestDom, UpdateMedicationRequestDom } from "../models/medication.request.dom";
import { MedicationResponseDom } from "../models/medication.response.dom";
import { Failure } from "base/failure/failure";
import { NoResult } from "base/usecase/query";
import { Pageable } from "base/pagination/pageable";
export abstract class MedicationRepository {
    abstract create(request: CreateMedicationRequestDom): Promise<Result<MedicationResponseDom, Failure>>;
    abstract update(request: UpdateMedicationRequestDom): Promise<Result<MedicationResponseDom, Failure>>;
    abstract delete(id: number): Promise<Result<NoResult, Failure>>;
    abstract list(request: PageableMedicationsRequestDom): Promise<Result<Pageable<MedicationResponseDom>, Failure>>;

    abstract all(): Promise<Result<MedicationResponseDom[], Failure>>;
}