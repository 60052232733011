import { HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Failure } from "base/failure/failure";
import { Left, Result, Right } from "base/result/result";
import { BaseErrorApi } from "./base-error-api";
import { Observable, of } from "rxjs";
import { BasePaginationRequest } from "base/pagination/pagination";


export interface BaseResponseApi<Result = any>{
    paging: BaseResponsePageableApi;
    data: Array<Result>;
}

export interface BaseResponsePageableApi{
    currentPage: number;
    pageSize: number,
    totalCount: number,
    totalPages: number,
    hasPreviousPage: boolean,
    hasNextPage: boolean,
    nextPageNumber?: number
}

export abstract class BaseApi {

    basePageable(pagin: BasePaginationRequest): HttpParams {
        let params = new HttpParams()
            .set('PageNumber', pagin.pagination.currentPage.toString())
            .set('PageSize', pagin.pagination.itemsPerPage.toString());
        if (pagin.search)
            params = params.set('Search', pagin.search);
        if (pagin.pagination.sortBy) 
            params = params.set('OrderBy', `${pagin.pagination.sortBy} ${pagin.pagination.sortDir}`);
        return params;
    }

    baseCatch<T>(response: HttpErrorResponse): Observable<Result<T, Failure>> {
        let error: BaseErrorApi = response.error;
        console.error(response)
        return of(new Left<Failure>(new Failure(response.message, error?.key??'UNKNOW')))
    }

    baseOk<T>(response: T): Result<T,Failure> {
        return new Right<T>(response);
    }

    baseResponse<T>(_: HttpResponse<any>, onMapper: () => T): Result<T, Failure>   {
        if (_.ok) return this.baseOk<T>(onMapper())
        return this.baseError<T>()
    }

    baseError<T>(failure: Failure = null): Result<T,Failure> {
        return new Left<Failure>(failure ? failure: new Failure(""));
    }
}