// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  securityUrl: 'http://24.199.109.16:5000/api/v1/auth',
  pediakids: 'http://24.199.109.16:5000/odata/pediakids',
  api: 'http://24.199.109.16:5000/api',
  assets: '/presentation/assets',
  encryptionKey: '5TGB&YHN7UJM(IK<5TGB&YHN7UJM(IK<',
  encryptionIv: '!QAZ2WSX#EDC4RFV',
};
