
import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Location } from "@angular/common";
import { EncryptionUtil } from 'base/utils/encryption.utils';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private _router: Router, private location: Location) {}

  navigateTo(route: string, queryParams?: Record<string, string>, replaceUrl: boolean = false): void {
    let data = queryParams?  EncryptionUtil.encrypt(this.convertToQueryString(queryParams)): null;
    const navigationExtras: NavigationExtras = {
        ...(queryParams && { queryParams: { param: data} }),
        replaceUrl: replaceUrl,
    };
    this._router.navigate([route], navigationExtras);
  }

  back() {
    this.location.back();
  }

  private convertToQueryString(queryParams: Record<string, string>): string {
    return JSON.stringify(queryParams);
  }
}