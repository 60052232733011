import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, BaseResponseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Pageable } from 'base/pagination/pageable';
import { Result } from 'base/result/result';
import { NoResult } from 'base/usecase/query';
import { CreateMedicationRequestDom, PageableMedicationsRequestDom, UpdateMedicationRequestDom } from 'domain/administration/medication/models/medication.request.dom';
import { MedicationResponseDom } from 'domain/administration/medication/models/medication.response.dom';
import { MedicationRepository } from 'domain/administration/medication/repositories/medication.repository';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import { MedicationResponseDto } from './dtos/medication.dto';
import { MedicationMapper } from './mappers/medication.mapper';
import { BasePaginationRequest, Pagination } from 'base/pagination/pagination';

@Injectable({
  providedIn: 'root'
})
export class MedicationImplRepository  extends BaseApi implements MedicationRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  }


  create(request: CreateMedicationRequestDom): Promise<Result<MedicationResponseDom, Failure>> {
    return this.http
    .post<MedicationResponseDto>(`${this.baseUrl}/v1/Medication/Create`, JSON.stringify(MedicationMapper.toCreateDto(request)))
      .pipe(map(_ => this.baseOk(MedicationMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicationResponseDom>(_))).toPromise();
  }
  update(request: UpdateMedicationRequestDom): Promise<Result<MedicationResponseDom, Failure>> {
    return this.http
    .put<MedicationResponseDto>(`${this.baseUrl}/v1/Medication/Update`, JSON.stringify(MedicationMapper.toUpdateDto(request)))
      .pipe(map(_ => this.baseOk(MedicationMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicationResponseDom>(_))).toPromise();
  }
  delete(id: number): Promise<Result<NoResult, Failure>> {
    return this.http
    .delete<any>(`${this.baseUrl}/v1/Medication/Delete/${id}`)
      .pipe(map(_ => this.baseOk(NoResult)))
      .pipe(catchError(this.baseCatch)).toPromise();
  }
  list(request: PageableMedicationsRequestDom): Promise<Result<Pageable<MedicationResponseDom>, Failure>> {
    const params = this.basePageable(request.params)
    return this.http
      .get<BaseResponseApi<MedicationResponseDto>>(`${this.baseUrl}/v1/Medication/GetAll`, { params: params })
      .pipe(map(_ => this.baseOk(MedicationMapper.toPageableDom(_))))
      .pipe(catchError((_) => this.baseCatch<Pageable<MedicationResponseDom>>(_))).toPromise();
  }


  all(): Promise<Result<MedicationResponseDom[], Failure>> {
    return this.http
      .get<MedicationResponseDto[]>(`${this.baseUrl}/v1/Medication/GetAllNotPageable`)
      .pipe(map(_ => this.baseOk(_.map(MedicationMapper.toDom))))
      .pipe(catchError((_) => this.baseCatch<MedicationResponseDom[]>(_))).toPromise();
  }
}