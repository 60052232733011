import { MedicalPrescriptionResponseDom } from "domain/plans/medical-order/models/medical-prescription/medical-prescription.response.dom";
import { MedicalPrescriptionResponseDto } from "../dtos/medical-prescription.dto";

export class MedicalPrescriptionMapper {
    static toDom(param: MedicalPrescriptionResponseDto): MedicalPrescriptionResponseDom {
        return {
            id: param.id,
            name: param.name
        };
    }
}