import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ItemListRepository } from 'domain/item-list/repositories/item-list.repository';
import { ItemListImplRepository } from './item-list-impl.repository';
import { AllGendersUseCase } from 'domain/item-list/usecases/all-genders.usecase';
import { AllTypesIdentificationsUseCase } from 'domain/item-list/usecases/all-types-identifications.usecase';
import { AllTypesMedicalOrdersUseCase } from 'domain/item-list/usecases/all-types-medical-orders.usecase';
import { AllTypesMedicationsUseCase } from 'domain/item-list/usecases/all-types-medications.usecase';
import { AllPlaningMethodsUseCase } from 'domain/item-list/usecases/all-planning-methods.usecase';

export const itemListProvider = { provide: ItemListRepository, useClass: ItemListImplRepository }
@NgModule({
  providers: [
    itemListProvider,
    AllGendersUseCase,
    AllTypesIdentificationsUseCase,
    AllTypesMedicationsUseCase,
    AllTypesMedicalOrdersUseCase,
    AllPlaningMethodsUseCase
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class ItemsListInfModule { }
