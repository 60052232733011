import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicalOrderRepository } from 'domain/plans/medical-order/repositories/medical-order.repository';
import { MedicalOrderImplRepository } from './medical-order-impl.repository';
import { CancelMedicalOrderUseCase } from 'domain/plans/medical-order/usecases/cancel-medical-order.usecase';
import { CreateMedicalOrderUseCase } from 'domain/plans/medical-order/usecases/create-medical-order.usecase';
import { InterpretMedicalOrderUseCase } from 'domain/plans/medical-order/usecases/interpret-medical-order.usecase';
import { ListMedicalOrdersByConsultationIdUseCase } from 'domain/plans/medical-order/usecases/list-medical-orders-by-consultation-id.usecase';
import { DeleteMedicalOrderUseCase } from 'domain/plans/medical-order/usecases/delete-medical-order.usecase';

export const provider = { provide: MedicalOrderRepository, useClass: MedicalOrderImplRepository }
@NgModule({
  providers: [
    provider,
    CancelMedicalOrderUseCase,
    CreateMedicalOrderUseCase,
    InterpretMedicalOrderUseCase,
    ListMedicalOrdersByConsultationIdUseCase,
    DeleteMedicalOrderUseCase
  ],
  imports: [
    CommonModule
  ]
})
export class MedicalOrderInfModule { }
