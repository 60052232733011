import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'presentation/app/shared/auth/auth.guard';
import { UpdatePasswordComponent } from './update-password/update-password.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'app-update-password',
        canActivate: [AuthGuard],
        component: UpdatePasswordComponent,
        data: {
          title: 'Page',
          roles: ['Authenticated'],
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule { }
export class ProfileRoutings {
  public static UPDATE_PASSWORD: string = "/medicaloffice/core/profile/app-update-password";
}
