
export enum MedicalOrderType {
  TOM_LABORATORIO = "TOM_LABORATORIO",
  TOM_INTERCONSULTA = "TOM_INTERCONSULTA",
  TOM_IMAGENES_DIAGNOSTICAS = "TOM_IMAGENES_DIAGNOSTICAS",
  TOM_PROCEDIMIENTOS = "TOM_PROCEDIMIENTOS"
}

export const ITEM_LIST = {
  //Genders
  G_FEMENINO: Symbol("G_FEMENINO"),
  //Identifications Types
  TI_CEDULA_CIUDADANIA: Symbol("TI_CEDULA_CIUDADANIA")
};

