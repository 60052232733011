import { Mapper } from "base/mapper";
import { LoginRequestDom } from "domain/auth/models/login-request.dom";
import { LoginResponseDom } from "domain/auth/models/login-response.dom";
import { ChangePasswordRequestDto, LoginRequestDto, LoginResponseDto } from "../dtos/auth.dto";
import { HttpResponse } from "@angular/common/http";
import { ChangePasswordRequestDom } from "domain/auth/models/password-request.dom";

export class AuthMapper  {
    static toDom(param: HttpResponse<LoginResponseDto>): LoginResponseDom {
        return {
            id: param.body.id,
            fullName: `${param.body.firstName} ${param.body.lastName}`,
            email: param.body.email,
            userName: param.body.userName,
            phoneNumber: param.body.phoneNumber,
            roleNames: param.body.roleNames,
            token: param.headers.get('access_token')
        };
    }

    static toDto(param: LoginRequestDom): LoginRequestDto {
        return <LoginRequestDto>{
            username: param.params.username,
            password: param.params.password,
            rememberme: param.params.rememberme
        };
    }

    static toChangePasswordDto(param: ChangePasswordRequestDom): ChangePasswordRequestDto {
        return <ChangePasswordRequestDto>{
            userId: param.params.userId,
            currentPassword: param.params.currentPassword,
            newPassword: param.params.newPassword
        };
    }
}
