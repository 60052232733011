import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { IncapacityResponseDom } from '../models/incapacity.response.dom';
import { IncapacityRepository } from '../repositories/incapacity.repository';
import { UpdateIncapacityRequestDom } from '../models/incapacity.request.dom';

@Injectable({ providedIn: 'root' })
export class UpdateIncapacityUseCase implements UseCase<UpdateIncapacityRequestDom, Promise<Result<IncapacityResponseDom, Failure>>> {
    constructor(private _incapacityRepository: IncapacityRepository) { }
    execute = (params: UpdateIncapacityRequestDom): Promise<Result<IncapacityResponseDom, Failure>> => this._incapacityRepository.update(params);
}