class DebounceUtil {
    private debounce:any
    private static instance: DebounceUtil;
    //Assign "new Singleton()" here to avoid lazy initialisation
    constructor() {
        if (DebounceUtil.instance) {
            return DebounceUtil.instance;
        }
        DebounceUtil.instance = this;
    }

    $debounce(callback: (...args: any[]) => void, ms: number = 700) {
        clearTimeout(this.debounce)
          this.debounce = setTimeout(() => {
            callback()
        }, ms)
    }
}
export const debounceUtil =new  DebounceUtil();
