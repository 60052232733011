import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-generic-loading',
  templateUrl: './generic-loading.component.html',
  styleUrls: ['./generic-loading.component.scss']
})
export class GenericLoadingComponent implements OnInit {

  @Input() text="Cargando..."

  constructor(private _cd:ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes['text']){
      this._cd.detectChanges();
    }
  }

  ngOnInit(): void {
  }

}
