import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { CreateMedicalOrderRequestDom } from '../models/medical-order.request.dom';
import { MedicalOrderResponseDom } from '../models/medical-order.response.dom';
import { MedicalOrderRepository } from '../repositories/medical-order.repository';

@Injectable({ providedIn: 'root' })
export class CreateMedicalOrderUseCase implements UseCase<CreateMedicalOrderRequestDom, Promise<Result<MedicalOrderResponseDom, Failure>>> {
    constructor(private _medicalOrderRepository: MedicalOrderRepository) { }
    execute = (params: CreateMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>> => this._medicalOrderRepository.create(params);
}