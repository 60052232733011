import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { CreatePregnancyRequestDom } from '../models/pregnancy.request.dom';
import { PregnancyResponseDom } from '../models/pregnancy.response.dom';
import { PregnancyRepository } from '../repositories/pregnancy.repository';

@Injectable({ providedIn: 'root' })
export class CreatePregnancyUseCase implements UseCase<CreatePregnancyRequestDom, Promise<Result<PregnancyResponseDom, Failure>>> {
    constructor(private _pregnancyRepository: PregnancyRepository) { }
    execute = (params: CreatePregnancyRequestDom): Promise<Result<PregnancyResponseDom, Failure>> => this._pregnancyRepository.create(params);
}