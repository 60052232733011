import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms";
import { Utils } from "../utils/utils.resource";

export class CustomValidators {

  static birthYear(c: FormControl): ValidationErrors {
    const numValue = new Date(c.value);
    const currentYear = Utils.getDateNow().getFullYear();
    const minYear = currentYear - 85;
    const maxYear = currentYear - 18;
    const isValid =
      !isNaN(numValue.getFullYear()) &&
      numValue.getFullYear() >= minYear &&
      numValue.getFullYear() <= maxYear;
    const message = {
      years: {
        message:
          "El año debe ser un número válido entre el año " +
          minYear +
          " y " +
          maxYear,
      },
    };
    return isValid ? null : message;
  }

  /**
   * Confirm confirmChildrenEqual validator
   *  Verficate childs for formGroup are equals
   *
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */

  static confirmChildrenEqual: ValidatorFn = (formGroup: FormGroup) => {
    let error = null;
    let message = {};
    const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
    const isValid = otherControlNames.every(controlName =>
      formGroup.get(controlName).value === formGroup.get(firstControlName).value
    );
    if (!isValid) {
      // Evalue message for confim password
      // ========================================================================
      if (formGroup.get("password")) {
        let error = null;
        if (formGroup.get(firstControlName).value) {
          if (formGroup.get(firstControlName).value && !formGroup.get("passwordConfirm").value) {
            error = "Confirmacion de contraseña requerida";
          }
          if (formGroup.get("passwordConfirm").value === "" || !formGroup.get("passwordConfirm").value) {
            error = "Confirmacion de contraseña requerida";
          }
          if (formGroup.get("passwordConfirm").value !== formGroup.get(firstControlName).value) {
            error = "Las contraseñas deben coincidir";
          }
        }
        if (formGroup.get("passwordConfirm").value && !formGroup.get(firstControlName).value) {
          error = "Recuerde confirmar la contraseña";
        }
        message = {
          validatePassword: {
            message: error,
          },
        };
      }
    }
    return isValid ? null : message;
  }

  static validateDateGreaterThanNow: ValidatorFn = (form: FormControl) => {
    const fecha = form.value;
    let mUtcDate = Utils.getDateNow()
    let error = null;
    if (fecha != null) {
      const fechaAgendaValue = new Date(fecha);
      if (fechaAgendaValue < mUtcDate) {
        error = "La Fecha  debe ser mayor a la Actual";
      }
    }
    const message = {
      rangeBetwenDates: {
        message: error,
      },
    };
    return error ? message : null;
  }

  static validateDateLessThanOrEqualNow: ValidatorFn = (form: FormControl) => {
    const fecha = form.value;
    let mDateNow = Utils.getDateNowOnlyYYYYMMDD();
    let error = null;
    if (fecha != null) {
      const fechaAgendaValue = new Date(fecha);
      if(fechaAgendaValue > mDateNow &&  mDateNow.setHours(0,0,0,0) != fechaAgendaValue.setHours(0,0,0,0)) {
        error = "La Fecha  debe ser menor o igual a la Actual";
      }
    }
    const message = {
      rangeBetwenDates: {
        message: error,
      },
    };
    return error ? message : null;
  }

  static validateOnlyNumber: ValidatorFn = (form: FormControl) => {
    const value = form.value;
    let error = null;
    if (value === null || value === '') return null;
    if (!value.toString().match(/^-?[0-9][^\.]*$/)){
      error = "Este campo no contiene un numero valido";
    }
    const message = {
      invalidNumber: {
        message: error,
      },
    };
    return error ? message : null;
  }


  static validateIntOrDecimal: ValidatorFn = (form: FormControl) => {
    const value = form.value;
    let error = null;

    if (value === null || value === '') return null;

    // Validar si el valor es un número entero o decimal válido
    if (!value.toString().match(/^\d+(\.\d+)?$/)) {
      error = "Este campo no contiene un número entero o decimal válido";
    }
    const message = {
      invalidIntOrDecimal: {
        message: error,
      },
    };

    return error ? message : null;
  };

  static validateOnlyText: ValidatorFn = (form: FormControl) => {
    const value = form.value;
    let error = null;
    if (value === null || value === '') {
      return null;
    }
    if (!value.toString().match(/^[a-zA-Z\s]*$/)) {
      error = 'Este campo solo debe contener letras y espacios';
    }
    const message = {
      invalidText: {
        message: error,
      },
    };
    return error ? message : null;
  };

  static validateEmail(form: FormControl): ValidationErrors {
    var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = null;
    if (!EMAIL_REGEXP.test(form.value)) {
      error = "Por favor digite un email valido  (username@domain)";
    }
    const message = {
      email: {
        message: error,
      },
    };
    return error ? message : null;
  }
}
