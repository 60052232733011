import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, BaseResponseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Pageable } from 'base/pagination/pageable';
import { Result } from 'base/result/result';
import { CreateConsultationRequestDom, PageableConsultationsRequestDom, UpdateConsultationRequestDom } from 'domain/consultation/consultation/models/consultation.request.dom';
import { ConsultationFoundResponseDom, ConsultationPartialResponseDom, ConsultationResponseDom } from 'domain/consultation/consultation/models/consultation.response.dom';
import { ConsultationRepository } from 'domain/consultation/consultation/repositories/consultation.repository';
import { environment } from 'environments/environment';
import { ConsultationMapper } from './mappers/consultation.mapper';
import { ConsultationPartialResponseDto, ConsultationResponseDto } from './dtos/consultation.dto';
import { catchError, map } from 'rxjs/operators';
import { ConsultationFoundResponseDto } from './dtos/consultation-found.dto';

@Injectable({
  providedIn: 'root'
})
export class ConsultationImplRepository extends BaseApi implements  ConsultationRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
  findById(patientId: number): Promise<Result<ConsultationFoundResponseDom, Failure>> {
    return this.http
    .get<ConsultationFoundResponseDto>(`${this.baseUrl}/v1/Consultation/GetById/${patientId}`)
    .pipe(map( _ => this.baseOk(ConsultationMapper.toFoundDom(_))))
    .pipe(catchError((_) => this.baseCatch<ConsultationFoundResponseDom>(_))).toPromise();
  }
 
  complete(request: UpdateConsultationRequestDom): Promise<Result<ConsultationResponseDom, Failure>> {
    return this.update(request)
  }
  update(request: UpdateConsultationRequestDom): Promise<Result<ConsultationResponseDom, Failure>> {
    return this.http
    .put<ConsultationResponseDto>(`${this.baseUrl}/v1/Consultation/Update`, JSON.stringify(ConsultationMapper.toUpdateDto(request)))
      .pipe(map( _ => this.baseOk(ConsultationMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<ConsultationResponseDom>(_))).toPromise();
  }
  create(request: CreateConsultationRequestDom): Promise<Result<ConsultationResponseDom, Failure>> {
    return this.http
    .post<ConsultationResponseDto>(`${this.baseUrl}/v1/Consultation/Create`, JSON.stringify(ConsultationMapper.toCreateDto(request)))
      .pipe(map( _ => this.baseOk(ConsultationMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<ConsultationResponseDom>(_))).toPromise();
  }
  list(request: PageableConsultationsRequestDom): Promise<Result<Pageable<ConsultationResponseDom>, Failure>> {
    const params = this.basePageable(request.params)
    return this.http
      .get<BaseResponseApi<ConsultationResponseDto>>(`${this.baseUrl}/v1/Consultation/GetAll/${request.patientId}`, {
        params: params
      })
      .pipe(map( _ => this.baseOk(ConsultationMapper.toPageableDom(_))))
      .pipe(catchError((_) => this.baseCatch<Pageable<ConsultationResponseDom>>(_))).toPromise();
  }
  findPartialByPatient(patientId: number): Promise<Result<ConsultationPartialResponseDom, Failure>> {
    return this.http
    .get<ConsultationPartialResponseDto>(`${this.baseUrl}/v1/Consultation/GetPartialConsultation/${patientId}`)
    .pipe(map( _ => this.baseOk(ConsultationMapper.toPartialDto(_))))
    .pipe(catchError((_) => this.baseCatch<ConsultationPartialResponseDom>(_))).toPromise();
  }
}
