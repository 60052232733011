<div *ngIf="formGroup" class="form-group mb-0">
  <label *ngIf="title">{{ title }}</label><br>
  <ui-switch
    class="ml-1"
    [disabled]="disabled"
    [(ngModel)]="model"
    (change)="onChange($event)"
  >
  </ui-switch>
  <div *ngFor="let error of formErrors">
    <ng-container *ngIf="showTextError">
        <small>
            <span class="badge badge-pill bg-light-danger badge-round mt-1">
            <i class="ft-info"></i> {{ error }}</span>
        </small>
    </ng-container>
  </div>
</div>

<div *ngIf="!formGroup" class="form-group mb-0">
  <label *ngIf="title">{{ title }}</label><br>
  <ui-switch
    class="ml-1"
    [disabled]="disabled"
  >
  </ui-switch>
</div>

