
export class CreateIncapacityRequestDom {
    constructor(public params:
      CommonIncapacityParams
      ) {}
}

export class UpdateIncapacityRequestDom {
  constructor(public params:
    { id: number } & CommonIncapacityParams
    ) {}
}

interface CommonIncapacityParams {
    patientId: number
    initialDate: string
    finalDate: string
    duration: number
    diagnosis: string
    observations: string
}