<label>{{ required ? "*" : "" }} {{ title }}</label>

<div class="form-inline">
  <div class="form-group">
    <div class="input-group mb-0">
      <input
      type="date"
      [disabled]="disabled"
      class="form-control {{ inputSizeClass }}"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="model"
        (ngModelChange)="onChange($event)"
      />
    </div>
  </div>
</div>

<div *ngIf="formGroup">
  <div
    *ngFor="
      let error of showErrorsService.listOfErrors(
        formGroup.get(formControlName)
      )
    "
  >
    <ng-container *ngIf="showTextError">
      <small
        ><span class="badge badge-pill bg-light-danger badge-round mt-1">
          <i class="ft-info"></i> {{ error }}
        </span></small
      >
    </ng-container>
  </div>
</div>

<div *ngIf="formGroupValidator && formGroupNameError && showTextError">
  <ng-container
    *ngFor="
      let error of showErrorsService.listOfErrors(
        formGroupNameError
          ? formGroupValidator.get([formGroupNameError])
          : formGroupValidator.get('')
      )
    "
  >
    <small
      ><span class="badge badge-pill bg-light-danger badge-round mt-1">
        <i class="ft-info"></i> {{ error }}</span
      ></small
    >
  </ng-container>
</div>
