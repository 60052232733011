<section>
  <div class="card">
    <div class="card-header">
      <div class="col-lg-12 text-right">
        <app-generic-button (onClick)="onCreate()" [icon]="'ft-plus'"
          [title]="'actions.create' | translate"></app-generic-button>
      </div>
    </div>
    <div class="card-content">
      <div class="card-body">
        <app-medical-order-list></app-medical-order-list>
      </div>
    </div>
  </div>
</section>