import {
  Component,
  OnInit} from "@angular/core";


@Component({
  selector: 'app-generic-options',
  templateUrl: './generic-options.component.html',
  styleUrls: ['./generic-options.component.scss']
})
export class GenericOptionsComponent implements OnInit {
  constructor(
      ) {
  }
  ngOnInit() {}
}

