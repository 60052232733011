import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from 'presentation/app/shared/auth/login.guard';
import { LoginPageComponent } from './login-page/login-page.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        canActivate: [LoginGuard],
        component: LoginPageComponent,
        data: {
          title: 'Login Page'
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule { }
export class AuthRoutings {
  public static LOGIN: string = "/medicaloffice/auth/login";
}