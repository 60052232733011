export class IncapacityResponseDom {
    constructor(
        public id: number,
        public patientId: number,
        public initialDate: string,
        public finalDate: string,
        public duration: number,
        public diagnosis: string,
        public observations?: string
    ) {}
}