import {Pipe, PipeTransform} from '@angular/core';
@Pipe({name: 'capitalizeTitle'})
export class CapitalizeTitlePipe implements PipeTransform {
    public transform(input:string): string{
        if (!input) {
            return '';
        } else {
            return input.replace(/^[A-Z][a-z]+[.]$/,input)
                ///\w\S*/g,input);
                //=> txt[0].toUpperCase() + txt.substr(1).toLowerCase() ));
        }
    }

}
