import { Inject, Injectable } from '@angular/core';
import { SessionDom } from 'domain/session/models/session.dom';
import { SessionRepository } from 'domain/session/repositories/session.repository';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NoResult } from 'base/usecase/query';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EncryptionUtil } from 'base/utils/encryption.utils';

@Injectable({
  providedIn: 'root'
})
export class SessionImplRepository extends SessionRepository {
  
  SESSION = "session";
  jwt = new JwtHelperService();

  constructor(@Inject(LOCAL_STORAGE) private storageService: StorageService  ) {
    super();
  }

  save(request: SessionDom): NoResult {
    let result = EncryptionUtil.encrypt(JSON.stringify(request))
    this.storageService.set(this.SESSION, result);
    return NoResult;
  }
  get(): SessionDom {
    const session = this.storageService.get(this.SESSION);
    let result = EncryptionUtil.decryptToObject(session)
    return new SessionDom(
      result.id,
      result.fullName,
      result.phoneNumber,
      result.roleNames,
      result.userName,
      result.token,
      result.email
    )
  }
  remove(): void {
    this.storageService.remove(this.SESSION);
  }

  isActive(): boolean {
    try {
      const token = this.get().token;
      const expired = this.jwt.isTokenExpired( token );
      if (token && expired)
        return false;
      return !expired;
    } catch ( error ){
      return false;
    }
  }
}