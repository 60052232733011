import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fullAge'
})
export class FullAgePipe implements PipeTransform {
  transform(dateOfBirth: string): any {
    return this.fullAge(dateOfBirth);
  }
    
  fullAge(dateOfBirth: string):string{
    var a = moment(new Date());
    var b = moment(new Date(dateOfBirth));
    a.add(1, 'd');
    b.diff(a, 'days');
    b.diff(a, 'months');
    b.diff(a, 'years');
    var years = a.diff(b, 'year');
    b.add(years, 'years');
    var months = a.diff(b, 'months');
    b.add(months, 'months');
    var days = a.diff(b, 'days');
    return years + " año(s) " + "  " + months + " mes(es) " + "  " + days + " día(s) ";
  }
}
