<section>
  <div class="card">
    <app-generic-card-header [title]="'patient.title' | translate" [showBtnBack]="true"
      [showBtn]="false"></app-generic-card-header>
    <div class="card-content">
      <div class="card-body">
        <form novalidate *ngIf="formGroup" [formGroup]="formGroup">
          <div class="row">
            <div class="col-md-4">
              <app-generic-input [required]="true" [formGroup]="formGroup"
                [inputType]="inputType.TEXT" [title]="'Nombres'"
                [formControlName]="'names'"></app-generic-input>
            </div>
            <div class="col-md-4">
              <app-generic-input [required]="true" [formGroup]="formGroup"
                [inputType]="inputType.TEXT" [title]="'Apellidos'"
                [formControlName]="'lastNames'"></app-generic-input>
            </div>
            <div class="col-lg-4">
              <app-generic-select [required]="true" [appendTo]="'body'" [items]="listDataGenders"
                [formGroup]="formGroup" [selectType]="selectType.SELECT" [title]="'Genero'"
                [formControlName]="'genderId'" [defaultValue]="defaultGeneros">
              </app-generic-select>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-lg-4">
              <app-generic-select [required]="true" [appendTo]="'body'"
                [items]="listDataIdentificationsTypes" [formGroup]="formGroup"
                [selectType]="selectType.SELECT" [title]="'Tipo Identificacion'"
                [formControlName]="'identificationTypeId'"
                [defaultValue]="defaultTipoIdentificaciones">
              </app-generic-select>
            </div>
            <div class="col-md-4">
              <app-generic-input [required]="true" [formGroup]="formGroup"
                [inputType]="inputType.TEXT" [title]="'Numero Identificacion'"
                [formControlName]="'identificationNumber'"></app-generic-input>
            </div>
            <div class="col-md-4">
              <app-generic-input-datepicker [required]="true" [formControlName]="'dateOfBirth'"
                [formGroup]="formGroup"
                [title]="'Fecha de Nacimiento'"></app-generic-input-datepicker>
            </div>
          </div>
          <div class="form-section">
            <i class="ft-phone"></i>
            <span class="font-medium-2 black text-bold-700"> Contacto </span>
          </div>
          <div class="row">
            <div class="col-md-4">
              <app-generic-input [required]="false" [formGroup]="formGroup"
                [inputType]="inputType.TEXT" [title]="'Direccion'"
                [formControlName]="'address'"></app-generic-input>
            </div>
            <div class="col-md-4">
              <app-generic-input [required]="false" [formGroup]="formGroup"
                [inputType]="inputType.NUMBER" [title]="'Telefono'"
                [formControlName]="'phone'"></app-generic-input>
            </div>
          </div>
          <br /><br />
          <div class="form-actions right">
            <app-generic-button (onClick)="goToBack()" [background]="'bg-secondary'" [icon]="'ft-x'"
              [title]="'actions.cancel' | translate" [class]="'mr-2'"></app-generic-button>
            <app-generic-button (onClick)="onSave()" [disabled]="formGroup.invalid" [icon]="
                'ft-save
            '
              " [title]="
                _crudValue == _crud.EDIT_RECORD
                  ? ('actions.update' | translate)
                  : ('actions.add' | translate)
              "></app-generic-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>