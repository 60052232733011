import { Result } from "base/result/result";
import { Failure } from "base/failure/failure";
import { Pageable } from "base/pagination/pageable";import { CreateConsultationRequestDom, PageableConsultationsRequestDom, UpdateConsultationRequestDom } from "../models/consultation.request.dom";
import { ConsultationFoundResponseDom, ConsultationPartialResponseDom, ConsultationResponseDom } from "../models/consultation.response.dom";
export abstract class ConsultationRepository {
    abstract create(request: CreateConsultationRequestDom): Promise<Result<ConsultationResponseDom, Failure>>;
    abstract update(request: UpdateConsultationRequestDom): Promise<Result<ConsultationResponseDom, Failure>>;
    abstract complete(request: UpdateConsultationRequestDom): Promise<Result<ConsultationResponseDom, Failure>>;
    abstract list(request: PageableConsultationsRequestDom): Promise<Result<Pageable<ConsultationResponseDom>, Failure>>;
    abstract findPartialByPatient(patientId: number): Promise<Result<ConsultationPartialResponseDom, Failure>>;
    abstract findById(patientId: number): Promise<Result<ConsultationFoundResponseDom, Failure>>;
}