import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  AfterViewInit,
  ChangeDetectorRef} from "@angular/core";
import { apexAnimations } from "../../animations";

@Component({
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: apexAnimations
})
export class GenericButtonComponent implements OnInit, AfterViewInit {
  @Output() onClick = new EventEmitter<any>();
  @Input() data: any = null;
  @Input() title: String = "Save";
  @Input() icon: String = null; //"ft-check"
  @Input() size: String = "btn-xs";
  @Input() background: String = "bg-primary";
  @Input() color: String = "white";
  @Input() class: String = "";

  @Input() disabled: boolean = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  _onClick() {
    this.onClick.emit(this.data);
  }
}