import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'presentation/app/shared/auth/auth.guard';
import { PatientFormComponent } from './patient-form/patient-form.component';
import { PatientMainComponent } from './patient-main.component';
import { CrudOperation } from 'presentation/app/shared/utils/utils.resource';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: PatientMainComponent,
        data: {
          title: 'Page',
          roles: ['Authenticated'],
        }
      },
      {
        path: 'form-create',
        canActivate: [AuthGuard],
        component: PatientFormComponent,
        data: {
          action: CrudOperation.ADD_RECORD,
          title: 'Crear Paciente',
          roles: ['Authenticated'],
        },
      },
      {
        path: 'form-update',
        canActivate: [AuthGuard],
        component: PatientFormComponent,
        data: {
          action: CrudOperation.EDIT_RECORD,
          title: 'Modificar Paciente',
          roles: ['Authenticated']
        },
      },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientRoutingModule { }
export class PatientRoutings {
  public static MAIN: string = "/medicaloffice/core/patient";
  public static CREATE: string = "/medicaloffice/core/patient/form-create";
  public static  UPDATE: string =  "/medicaloffice/core/patient/form-update";
}


