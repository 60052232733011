/**
 * An object used to get page information from the server
 */
export class Page {
  // The number of elements in the page
  itemsPerPage: number = 10;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  currentPage: number = 0;
  filterSearch: string =null;
  sortBy: string="";
  sortDir: string = "desc";
  hasNextPage: boolean = false;
  nextPageNumber?: number=null;
  sourceData: SourceData =SourceData.SERVER;
}

export enum SourceData{
  SERVER="SERVER",
  LOCAL="LOCAL"
}
