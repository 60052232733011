import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { Pageable } from 'base/pagination/pageable';
import { PageableConsultationsRequestDom } from '../models/consultation.request.dom';
import { ConsultationResponseDom } from '../models/consultation.response.dom';
import { ConsultationRepository } from '../repositories/consultation.repository';

@Injectable({ providedIn: 'root' })
export class ListConsultationsUseCase implements UseCase<PageableConsultationsRequestDom, Promise<Result<Pageable<ConsultationResponseDom>, Failure>>> {
    constructor(private _consultationRepository: ConsultationRepository) { }
    execute = (params: PageableConsultationsRequestDom): Promise<Result<Pageable<ConsultationResponseDom>, Failure>> => this._consultationRepository.list(params);
}