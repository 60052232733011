import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { UltrasoundResponseDom } from '../models/ultrasound.response';
import { UltrasoundRepository } from '../repositories/ultrasound.repository';
@Injectable({ providedIn: 'root' })
export class ListUltrasoundsByPatientIdUseCase implements UseCase<number, Promise<Result<UltrasoundResponseDom[], Failure>>> {
    constructor(private _UltrasoundRepository: UltrasoundRepository) { }
    execute = (params: number): Promise<Result<UltrasoundResponseDom[], Failure>> => this._UltrasoundRepository.listByPatientId(params);
}