import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { MedicationRepository } from '../repositories/medication.repository';
import { UpdateMedicationRequestDom } from '../models/medication.request.dom';
import { MedicationResponseDom } from '../models/medication.response.dom';

@Injectable({ providedIn: 'root' })
export class UpdateMedicationUseCase implements UseCase<UpdateMedicationRequestDom, Promise<Result<MedicationResponseDom, Failure>>> {
    constructor(private _medicationRepository: MedicationRepository) { }
    execute = (params: UpdateMedicationRequestDom): Promise<Result<MedicationResponseDom, Failure>> => this._medicationRepository.update(params);
}