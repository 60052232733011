import { BaseResponseApi } from "base/api/base-api";
import { CreatePatientRequestDom, UpdatePatientRequestDom } from "domain/patient/models/patient.request.dom";
import { CreatePatientRequestDto, PatientResponseDto, UpdatePatientRequestDto } from "../dtos/patient.dto";
import { PatientFoundResponseDom, PatientInfoResponseDom, PatientResponseDom } from "domain/patient/models/patient.response.dom";
import { Pageable } from "base/pagination/pageable";
import { PaginationMapper } from "base/pagination/pagination-mapper";

export class PatientMapper {

    static toPageableDom(param: BaseResponseApi<PatientResponseDto>): Pageable<PatientResponseDom> {
        return <Pageable>{
            data: param.data.map(PatientMapper.toDom),
            pagination: PaginationMapper.toPagin(param.paging)
        };
    }

    static toDom(param: PatientResponseDto): PatientResponseDom {
        return {
            id: param.id,
            fullName: `${param.names} ${param.lastNames}`,
            identificationNumber: param.identificationNumber,
            age: param.age,
            phone: param.phone,
            genderName: param.genderName,
            address: param.address
        };
    }

    static toFoundDom(param: PatientResponseDto): PatientFoundResponseDom {
        return {
            id: param.id,
            names: param.names,
            lastNames: param.lastNames,
            identificationNumber: param.identificationNumber,
            dateOfBirth: param.dateOfBirth,
            phone: param.phone,
            address: param.address,
            identificationTypeId: param.identificationTypeId,
            genderId: param.genderId,
        };
    }

    static toInfoDom(param: PatientResponseDto): PatientInfoResponseDom {
        return {
            id: param.id,
            names: param.names,
            lastNames: param.lastNames,
            identificationNumber: param.identificationNumber,
            dateOfBirth: param.dateOfBirth,
            phone: param.phone,
            address: param.address,
            identificationTypeId: param.identificationTypeId,
            genderId: param.genderId,
            genderName: param.genderName,
            age: param.age
        };
    }

    static toCreateDto(param: CreatePatientRequestDom): CreatePatientRequestDto {
        return <CreatePatientRequestDto>{
            names: param.params.names,
            lastNames: param.params.lastNames,
            identificationNumber: param.params.identificationNumber,
            dateOfBirth: param.params.dateOfBirth,
            phone: param.params.phone,
            address: param.params.address,
            identificationTypeId: param.params.identificationTypeId,
            genderId: param.params.genderId
        };
    }

    static toUpdateDto(param: UpdatePatientRequestDom): UpdatePatientRequestDto {
        return <UpdatePatientRequestDto>{
            id: param.params.id,
            names: param.params.names,
            lastNames: param.params.lastNames,
            identificationNumber: param.params.identificationNumber,
            dateOfBirth: param.params.dateOfBirth,
            phone: param.params.phone,
            address: param.params.address,
            identificationTypeId: param.params.identificationTypeId,
            genderId: param.params.genderId
        };
    }
}