import { AfterViewInit, Injectable, Input, OnDestroy, OnInit, SimpleChanges, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { InputType } from '../generic-input/generic-input.component';
import { takeUntil } from 'rxjs/operators';
import { CrudOperation, Utils } from '../../utils/utils.resource';
import { SelectType } from '../generic-select/generic-select.component';
import { EncryptionUtil } from 'base/utils/encryption.utils';

export abstract class  AbsBaseComponent  {
  abstract onInit(): void;
  abstract onAfterViewInit(): void;
  abstract onDestroy(): void;
  abstract onChanges(changes:SimpleChanges): void;
}

@Injectable()
export abstract class BaseComponent extends AbsBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  // Parameters
  @Input() _routeParameters: any;
  @Input() _dataParameters: any;
  @Input() _modalParameters:any;

  // Crud
  @Input() _crudValue = CrudOperation.ADD_RECORD;
  _crud = CrudOperation;

  // Constants
  assets: string = environment.assets
  inputType = InputType
  selectType = SelectType
  
  // Suscribe
  public _unsubscribeAll: Subject<any>;

  constructor(private route: ActivatedRoute) {
    super()
    this._unsubscribeAll= new Subject()
  }
  ngOnInit(): void {
    // Data parameters
    this.route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((v) => {
      this._dataParameters = v;
      if(v.hasOwnProperty("action")){
        this._crudValue= v.action;
      }
      if(this._modalParameters?.hasOwnProperty("action")){
        this._crudValue= this._modalParameters.action;
      }
      // Route parameters
      this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(parameters => {
        if(!Utils.isEmptyObject(parameters))
            this._routeParameters = EncryptionUtil.decryptToObject(parameters.param);
        this.onInit()
      });
    });
  }

  ngAfterViewInit(): void {
    this.onAfterViewInit()
  }

  ngOnChanges(changes: SimpleChanges){
    this.onChanges(changes)
  }

  ngOnDestroy(): void {
    if(this._unsubscribeAll){
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
      this._unsubscribeAll.unsubscribe();
    }
    this.onDestroy()
  }
}
