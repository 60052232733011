import { Result } from "base/result/result";
import { Failure } from "base/failure/failure";
import { NoResult } from "base/usecase/query";
import { Pageable } from "base/pagination/pageable";
import { CreatePatientRequestDom, PageablePatientsRequestDom, UpdatePatientRequestDom } from "../models/patient.request.dom";
import { PatientFoundResponseDom, PatientInfoResponseDom, PatientResponseDom } from "../models/patient.response.dom";
export abstract class PatientRepository {
    abstract findInfoById(id: number): Promise<Result<PatientInfoResponseDom, Failure>>;
    abstract findById(id: number): Promise<Result<PatientFoundResponseDom, Failure>>;
    abstract create(request: CreatePatientRequestDom): Promise<Result<PatientResponseDom, Failure>>;
    abstract update(request: UpdatePatientRequestDom): Promise<Result<PatientResponseDom, Failure>>;
    abstract delete(id: number): Promise<Result<NoResult, Failure>>;
    abstract list(request: PageablePatientsRequestDom): Promise<Result<Pageable<PatientResponseDom>, Failure>>;
}