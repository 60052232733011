import { Result } from "base/result/result";
import { CancelMedicalOrderRequestDom, CreateMedicalOrderRequestDom, InterpretMedicalOrderRequestDom } from "../models/medical-order/medical-order.request.dom";
import { MedicalOrderResponseDom } from "../models/medical-order/medical-order.response.dom";
import { Failure } from "base/failure/failure";
import { NoResult } from "base/usecase/query";
import { MedicalOrderType } from "domain/item-list/constants/item-list.const";

export abstract class MedicalOrderRepository {
    abstract create(request: CreateMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>>;
    abstract delete(id: number): Promise<Result<NoResult, Failure>>;
    abstract list(type: MedicalOrderType, consultationId: number): Promise<Result<MedicalOrderResponseDom[], Failure>>;
    abstract interpret(request: InterpretMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>>;
    abstract cancel(request: CancelMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>>;
}