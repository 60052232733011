import { Injectable } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class GenericModalService {
  data:any;
  constructor(private modalService: NgbModal) { }
  //sizeModal 'sm' | 'lg' | 'xl'
  async openModal(component:any, params:any|null, sizeModal=ModalSize.SM ):Promise<any>{
    this.data = params;
    const modalRef = this.modalService.open(component,
      {
        size: sizeModal,
        centered:true,
        animation: true,
        backdrop:true,
        //backdropClass:"bg-secondary"
    });
    modalRef.componentInstance._modalParameters= params;
    return new Promise((resolve, reject)=>{
      modalRef.result.then(result=>{
        resolve(result)
      },(reason)=>{
        let result = this.getDismissReason(reason);
        resolve(result)
      })
    });
  }

  private getDismissReason(reason: any): ModalDismissReasonsCustom {
    if (reason === ModalDismissReasons.ESC) {
      return  <ModalDismissReasonsCustom>{
        reason:'by pressing ESC',
        code:DismissReasonsType.ESC
      } ;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return  <ModalDismissReasonsCustom>{
        reason:'by clicking on a backdrop',
        code:DismissReasonsType.BACKDROP_CLICK
      } ;
    } else {
      return  <ModalDismissReasonsCustom>{
        reason:`with: ${reason}`,
        code:DismissReasonsType.CROSS_CLICK
      } ;
    }
  }
}

export interface ModalDismissReasonsCustom{
  reason:string;
  result:any;
  code:DismissReasonsType;
}

export enum DismissReasonsType{
  ESC="ESC",
  BACKDROP_CLICK="BACKDROP_CLICK",
  CROSS_CLICK="CROSS_CLICK",
}
export enum ModalSize{
  SM="sm",
  LG="lg",
  XL="xl",
}
