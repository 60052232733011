import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { StatisticRepository } from '../repositories/statistic.repository';
import { ConsolidatedDom } from '../models/statistics.response.dom';
import { NoParams } from 'base/usecase/query';

@Injectable({ providedIn: 'root' })
export class GetConsolidatedUseCase implements UseCase<NoParams, Promise<Result<ConsolidatedDom, Failure>>> {
    constructor(private _StatisticRepository: StatisticRepository) { }
    execute = (params: NoParams): Promise<Result<ConsolidatedDom, Failure>> => this._StatisticRepository.consolidated();
}