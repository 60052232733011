import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { NoResult } from 'base/usecase/query';
import { MedicalOrderType } from 'domain/item-list/constants/item-list.const';
import { CancelMedicalOrderRequestDom, CreateMedicalOrderRequestDom, InterpretMedicalOrderRequestDom } from 'domain/plans/medical-order/models/medical-order/medical-order.request.dom';
import { MedicalOrderResponseDom } from 'domain/plans/medical-order/models/medical-order/medical-order.response.dom';
import { MedicalOrderRepository } from 'domain/plans/medical-order/repositories/medical-order.repository';
import { environment } from 'environments/environment';
import { MedicalOrderMapper } from './mappers/medical-order.mapper';
import { MedicalOrderResponseDto } from './dtos/medical-order.dto';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedicalOrderImplRepository extends BaseApi implements  MedicalOrderRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
 
  create(request: CreateMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>> {
    return this.http
    .post<MedicalOrderResponseDto>(`${this.baseUrl}/v1/Medical_Order/Create`, JSON.stringify(MedicalOrderMapper.toCreateDto(request)))
      .pipe(map(_ => this.baseOk(MedicalOrderMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicalOrderResponseDom>(_))).toPromise();
  }
  delete(id: number): Promise<Result<NoResult, Failure>> {
    return this.http
    .delete<any>(`${this.baseUrl}/v1/Medical_Order/Delete/${id}`)
      .pipe(map(_ => this.baseOk(NoResult)))
      .pipe(catchError(this.baseCatch)).toPromise();
  }
  list(type: MedicalOrderType, consultationId: number): Promise<Result<MedicalOrderResponseDom[], Failure>> {
    return this.http
    .get<MedicalOrderResponseDto[]>(`${this.baseUrl}/v1/Medical_Order/GetByCode/${consultationId}/${type}`)
    .pipe(map(_ => this.baseOk(_.map(MedicalOrderMapper.toDom))))
    .pipe(catchError((_) => this.baseCatch<MedicalOrderResponseDom[]>(_))).toPromise();
  }
  interpret(request: InterpretMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>> {
    return this.http
    .put<MedicalOrderResponseDto>(`${this.baseUrl}/v1/Medical_Order/UpdateInterpretation`, JSON.stringify(MedicalOrderMapper.toInterpretDto(request)))
      .pipe(map(_ => this.baseOk(MedicalOrderMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicalOrderResponseDom>(_))).toPromise();
  }
  cancel(request: CancelMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>> {
    return this.http
    .put<MedicalOrderResponseDto>(`${this.baseUrl}/v1/Medical_Order/UpdateState`, JSON.stringify(MedicalOrderMapper.toCancelDto(request)))
      .pipe(map(_ => this.baseOk(MedicalOrderMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicalOrderResponseDom>(_))).toPromise();
  }
}
