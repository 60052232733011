import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputType } from 'presentation/app/shared/components/generic-input/generic-input.component';
import { SweetAlertService } from 'presentation/app/shared/services/sweet-alert.service';
import { Utils } from 'presentation/app/shared/utils/utils.resource';
import { Location } from "@angular/common"
import { AuthChangePasswordUseCase } from 'domain/auth/usecases/auth/auth-change-password.usecase';
import { ChangePasswordRequestDom } from 'domain/auth/models/password-request.dom';
import { Failure } from 'base/failure/failure';
import { NavigationService } from 'presentation/app/shared/services/navigation.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  inputType = InputType;
  updatePasswordForm:FormGroup;

  constructor(
    private _sweetAlertService:SweetAlertService,
    private formBuilder: FormBuilder,
    private _navigation: NavigationService,
    private _authChangePasswordUseCase: AuthChangePasswordUseCase
  ) { }

  ngOnInit() {
    this.updatePasswordForm = this.returnPatientForm();
    Utils.markFormGroupTouched(this.updatePasswordForm);
  }

  goToBack() {
    this._navigation.back();
  }
  
  returnPatientForm(): FormGroup {
    return this.formBuilder.group({
      currentPassword: [null,[Validators.required,Validators.minLength(5)]],
      initialNewPassword: [null,[Validators.required,Validators.minLength(5)]],
      newPassword: [null,[Validators.required,Validators.minLength(5)]],
    })
  }

  async onChangePassword() {
    let request = this.updatePasswordForm.getRawValue()
    if(request.initialNewPassword != request.newPassword){
      return this._sweetAlertService.showToastError("Las contraseñas nuevas no coinciden")
    }
    delete request.initialNewPassword;
    let result = await this._authChangePasswordUseCase.execute(new ChangePasswordRequestDom(request))
    result.fold((_) => {
      this._sweetAlertService.showToastSucces("Contraseña actualizada correctamente, la próxima vez que inicies sesión debes ingresar su nueva contraseña");
      this.goToBack();
    }, (error: Failure)=> this.messageError(error))
  }

  messageError(failed: Failure) {
    switch (failed.key) {
      case 'FAILED_USER_NOT_FOUND':
        this._sweetAlertService.showToastInfo(
          "El usuario no corresponde a una cuenta inscrita"
        );
      break;
      case 'FAILED_PASWORD_INVALID':
        this._sweetAlertService.showToastInfo(
          "La contraseña actual no es valida o no fue posible cambiarla"
        );
      break;
      default:
        this._sweetAlertService.showToastInfo(
          "Ha ocurrido un error desconocido"
        );
        break;
    }
  }
}