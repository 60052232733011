import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericLoadingService {
  private onLoadingSubject = new BehaviorSubject<boolean>(false);
  onLoading$ = this.onLoadingSubject.asObservable();
  constructor(private spinner: NgxSpinnerService,) { }
  show(){
    this.onLoadingSubject.next(true);
    this.spinner.show()
  }
  hide(){
    this.onLoadingSubject.next(false);
    this.spinner.hide();
  }

  get isLoading() {
    return this.onLoadingSubject.value
  }
}
