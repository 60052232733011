import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { UpdatePhysicalExamRequestDom } from '../models/physical-exam.request.dom';
import { PhysicalExamResponseDom } from '../models/physical-exam.response.dom';
import { PhysicalExamRepository } from '../repositories/physical-exam.repository.dom';

@Injectable({ providedIn: 'root' })
export class UpdatePhysicalExamUseCase implements UseCase<UpdatePhysicalExamRequestDom, Promise<Result<PhysicalExamResponseDom, Failure>>> {
    constructor(private _physicalExamRepository: PhysicalExamRepository) { }
    execute = (params: UpdatePhysicalExamRequestDom): Promise<Result<PhysicalExamResponseDom, Failure>> => this._physicalExamRepository.update(params);
}