import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  ColumnChangesService,
  ColumnMode,
  DatatableComponent,
  DimensionsHelper,
  ScrollbarHelper,
} from "@swimlane/ngx-datatable";
import { Page, SourceData } from "./model/page";
import { Column } from "./model/columns.model";
import { ActivatedRoute } from "@angular/router";
import { debounceUtil } from "base/utils/debounce.utils";

@Component({
  selector: "app-generic-datatable",
  templateUrl: "./generic-datatable.component.html",
  styleUrls: [
    "./generic-datatable.component.scss",
    "../../../../assets/sass/libs/datatables.scss",
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [ScrollbarHelper, DimensionsHelper, ColumnChangesService],
})
export class GenericDatatableComponent implements OnInit {
  sourceData = SourceData;
  idSearch = Math.random();
  // row data
  @Input() public rows = [];
  // column header
  @Input() public columns: Array<Column> = [];
  // column header
  @Input() public filterCombineTwoProperties = [];
  @Output() nextPage = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<string>();
  @Output() sortEvent = new EventEmitter<any>();
  @Output() limitPageEvent = new EventEmitter<any>();
  @Input() page = new Page();
  @Input() inputType: InputTypeSearchTable = InputTypeSearchTable.TEXT;
  currentPageLimit = this.page.itemsPerPage;
  @Input() enabledFilter = true;
  public ColumnMode = ColumnMode;
  public pageLimitOptions = [
    { value: 10 },
    { value: 20 },
    { value: 50 },
    { value: 100 },
  ];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  /**
   * filterUpdate
   *
   * @param code
   */
  filter(event) {
    debounceUtil.$debounce(() => {
      this.filterEvent.next(event.target.value);
      this.table.offset = 0;
    },500)
  }

  /**
   * Constructor
   *
   * @param {ActivatedRoute} _route
   */
  constructor(private _route: ActivatedRoute) {}

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    this.page.currentPage = pageInfo.offset;
    this.nextPage.next(this.page);
  }
  public noopComparator(): number {
    return 0;
  }

  dynamicSort(property, order) {
    var sortOrder = order == "desc" ? -1 : 1;
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  onSort(event) {
    if (event.column.propSortable) {
      this.page.sortDir = event.sorts[0].dir;
      this.page.sortBy = event.column.propSortable;
    } else {
      this.page.sortDir = event.sorts[0].dir;
      this.page.sortBy = event.sorts[0].prop;
    }
    if (this.page.sourceData === this.sourceData.LOCAL)
      this.rows.sort(this.dynamicSort(this.page.sortBy, event.newValue));
    else 
      this.sortEvent.next(this.page);
  }

  onLimitChange(limit) {
    this.page.itemsPerPage = parseInt(limit);
    this.page.currentPage = 0;
    this.limitPageEvent.next(this.page);
  }
  /**
   * On init
   */
  ngOnInit() {}
}

export enum InputTypeSearchTable {
  TEXT = 'text',
  NUMBER = 'number'
}
