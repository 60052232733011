<form [formGroup]="formGroup">
  <div class="row  align-middle">
    <div class="col-lg-12  py-2 px-3 ">
      <app-generic-input
      [inputSizeClass]="'form-control-lg'"
      [icon]="'ft-file-text'"
      [showIcon]="true"
      [required]="true"
      [formGroup]="formGroup" 
      [inputType]="inputType.TEXT" 
      [title]="'medicalOrder.form.name' | translate"
      [formControlName]="'name'"></app-generic-input>
      <br>

      <app-generic-select 
      [required]="true" 
      [appendTo]="'body'" 
      [items]="listDataType"
      [formGroup]="formGroup" 
      [selectType]="selectType.SELECT"  
      [title]="'medicalOrder.form.type' | translate"
      [formControlName]="'typeId'"> </app-generic-select>

      <div class="form-actions right">
        <app-generic-button (onClick)="onCancel()" [background]="'bg-secondary'"  [icon]="'ft-x'" [title]="'actions.cancel' | translate" [class]="'mr-2'"></app-generic-button>
        <app-generic-button (onClick)="onSave()"  [disabled]="formGroup.invalid" [icon]="'ft-save
        '" [title]=" _crudValue == _crud.EDIT_RECORD ? ('actions.update' | translate):('actions.add' | translate)"></app-generic-button>
      </div>
    </div>
  </div>
</form>