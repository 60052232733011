import { Result } from "base/result/result";
import { Failure } from "base/failure/failure";
import { NoResult } from "base/usecase/query";
import { IncapacityResponseDom } from "../models/incapacity.response.dom";
import { CreateIncapacityRequestDom, UpdateIncapacityRequestDom } from "../models/incapacity.request.dom";

export abstract class IncapacityRepository {
    abstract findById(id: number): Promise<Result<IncapacityResponseDom, Failure>>;
    abstract create(request: CreateIncapacityRequestDom): Promise<Result<IncapacityResponseDom, Failure>>;
    abstract update(request: UpdateIncapacityRequestDom): Promise<Result<IncapacityResponseDom, Failure>>;
    abstract delete(id: number): Promise<Result<NoResult, Failure>>;
    abstract listByPatient(patientId: number): Promise<Result<IncapacityResponseDom[], Failure>>;
}