import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { PatientResponseDom } from '../models/patient.response.dom';
import { UpdatePatientRequestDom } from '../models/patient.request.dom';
import { PatientRepository } from '../repositories/patient.repository';

@Injectable({ providedIn: 'root' })
export class UpdatePatientUseCase implements UseCase<UpdatePatientRequestDom, Promise<Result<PatientResponseDom, Failure>>> {
    constructor(private _patientRepository: PatientRepository) { }
    execute = (params: UpdatePatientRequestDom): Promise<Result<PatientResponseDom, Failure>> => this._patientRepository.update(params);
}