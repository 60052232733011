import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ShowErrorsService } from 'presentation/app/shared/validators/show-errors.service';
import * as moment from 'moment';

@Component({
  selector: 'app-generic-input-datepicker',
  templateUrl: './generic-input-datepicker.component.html',
  styleUrls: ['./generic-input-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenericInputDatepickerComponent),
      multi: true
    }
  ],
})
export class GenericInputDatepickerComponent implements   OnInit,ControlValueAccessor{

  model: any;
  @Input() required = false;
  @Input() setCurrentDay= true;

  @Input() title=""
  @Input() showIcon = false;
  @Input() disabled = false;
  
  @Input() icon:any= "ft-user";
  @Input() mask="";

  @Input() formControlName:any = null;
  @Input() formGroupNameError=null;
  @Input() formGroup: FormGroup;
  @Input() formGroupValidator: FormGroup;
  @Input() showTextError= true;
  @Input() inputSizeClass='form-control-sm'; // form-control-lg  (or) form-control-sm

  @Output() eventChange = new EventEmitter<Date>();

  constructor(
    private _cd:ChangeDetectorRef,
    public showErrorsService:ShowErrorsService,) {
  }

  ngOnInit(): void {
    if(this.formGroup){
      let value= this.formGroup.get(this.formControlName).value
      if(value){
        const  result = this.formatDate(value)
        this.formGroup.get(this.formControlName).setValue(result)
        this._cd.detectChanges()
        return;
      }
    }
    if (this.setCurrentDay) {
      this.formatDate(new Date())
      this.onChange(this.model);
    }
  }

  private formatDate(date):Date {
    const dateFormat = moment.parseZone(date);
    const onlyDate = dateFormat.format("YYYY-MM-DD");
    const splitDdate = onlyDate.split('-')
    this.model = [splitDdate[0], splitDdate[1], splitDdate[2]].join('-')
    return dateFormat.toDate()
  }

  onChange(date) {
    if(this.formGroup && this.formControlName) this.formGroup.get(this.formControlName).markAsDirty()
  
    //Se marca como markAsDirty una vez se selecciona una fecha
    if(this.formGroupValidator && this.formGroupNameError){
      this.formGroupValidator.get(this.formGroupNameError).markAsDirty()
    }

    if (this.formGroup && this.formControlName) {
      var dateMoment = moment(`${date}T00:00:00Z`, 'YYYY-MM-DD');
      if (!dateMoment.isValid()) {
        this.formGroup.get(this.formControlName).patchValue(null)
        return;
      }
      this.formGroup.get(this.formControlName).patchValue(dateMoment.toDate())
      this.eventChange.next(dateMoment.toDate());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formGroup) {
      const newValue = changes.formGroup.currentValue.get(this.formControlName).value;
      if (newValue) {
        const result = this.formatDate(newValue)
        if(result)this.formGroup.get(this.formControlName).setValue(result)
      }
    }
  }


  writeValue(obj: any): void {
    //throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }

  errorEvent(event){
  }
}
