import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysicalExamImplRepository } from './physical-exam-impl.repository';
import { PhysicalExamRepository } from 'domain/consultation/physical-exam/repositories/physical-exam.repository.dom';
import { UpdatePhysicalExamUseCase } from 'domain/consultation/physical-exam/usecases/update-physical-exam.usecase';
import { CreatePhysicalExamUseCase } from 'domain/consultation/physical-exam/usecases/create-physical-exam.usecase';


export const physicalExamProvider = { provide: PhysicalExamRepository, useClass: PhysicalExamImplRepository }
@NgModule({
  providers: [
    physicalExamProvider,
    CreatePhysicalExamUseCase,
    UpdatePhysicalExamUseCase
  ],
  imports: [
    CommonModule
  ]
})
export class PhysicalExamInfModule { }
