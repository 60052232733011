import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { CreateIncapacityRequestDom } from '../models/incapacity.request.dom';
import { IncapacityResponseDom } from '../models/incapacity.response.dom';
import { IncapacityRepository } from '../repositories/incapacity.repository';

@Injectable({ providedIn: 'root' })
export class CreateIncapacityUseCase implements UseCase<CreateIncapacityRequestDom, Promise<Result<IncapacityResponseDom, Failure>>> {
    constructor(private _incapacityRepository: IncapacityRepository) { }
    execute = (params: CreateIncapacityRequestDom): Promise<Result<IncapacityResponseDom, Failure>> => this._incapacityRepository.create(params);
}