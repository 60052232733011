import { Injectable } from '@angular/core';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {

  constructor() { }

  private _animation() {
    return {
      showClass: {
        popup: 'animated flipInX faster',
        //icon: 'animated flipInX delay-1s'
      },
      hideClass: {
        popup: 'animated flipOutX faster',
      },
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary ml-1'
      },
    }
  }


  showAlertInputConfirm<T>(title: string, text: string,
    onRequest: (value: string) => Promise<Result<T, Failure>>,
    onSuccess?: (value: T) => void) {
    swal.fire({
      title: title,
      html:text,
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      ...this._animation(),
      preConfirm: async (data) => {
        if (!data) {
          swal.showValidationMessage(`Este campo es requerido.`);
          return <ResultConfirm>{
            isSuccess: false
          }
        }
        let result = await onRequest(data)
        return <ResultConfirm>{
          data: result.value,
          isSuccess: result.isRight()
        }
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result: SweetAlertResult) => {
      let response = result.value ? result.value as ResultConfirm: <ResultConfirm>{ isSuccess : false};
      if (response.isSuccess) {
        this.showToastSucces()
        onSuccess(response.data)
      } 
    });
  }


  showAlertConfirmation(title: string, text: string, type: any, confirmButtonText: string, showCancelButton: boolean, cancelButtonText: string) {
    return swal.fire({
      title: title,
      html:text,
      icon:type,
      showCancelButton:showCancelButton,
      confirmButtonText:confirmButtonText,
      cancelButtonText: cancelButtonText,
      ...this._animation(),
    }).then((result) => {
      return result.value
    })
  }

  showAlert(title: string, text: string, type: SweetAlertIcon = 'info', width:any | null=null) {
    return swal.fire({
      icon: type,
      title: title,
      html:text,
      width: width ? width : null,
      ...this._animation(),
      /* footer: '<a href>Why do I have this issue?</a>' */
    })
  }

  showAlertError(title: string, text: string, width:any | null=null) {
    return swal.fire({
      icon: 'error',
      title: title,
      html:text,
      width: width ? width : null,
      ...this._animation(),
      /* footer: '<a href>Why do I have this issue?</a>' */
    })
  }

  showToastSucces(message: string | null = null){
    const Toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    });

    Toast.fire({
      icon: 'success',
      title: message?`${message}`:`La solicitud se realizó correctamente!`
    })
  }

  showToastError(message: string | null){
    const Toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000
    });

    Toast.fire({
      icon: 'error',
      title: message?`${message}`:`Ha ocurrido un error!`
    })
  }

  showToastInfo(message: string | null){
    const Toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    });

    Toast.fire({
      icon: "info",
      title: message?`${message}`:``,
      
    })
  }
}



export interface ResultConfirm{
  isSuccess: boolean;
  data: any
}