import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { MedicalOrderResponseDom } from '../models/medical-order/medical-order.response.dom';
import { MedicalOrderRepository } from '../repositories/medical-order.repository';
import { MedicalOrderType } from 'domain/item-list/constants/item-list.const';

@Injectable({ providedIn: 'root' })
export class ListMedicalOrdersByConsultationIdUseCase implements UseCase<{
        type: MedicalOrderType,
        consultationId: number 
}, Promise<Result<MedicalOrderResponseDom[], Failure>>> {
    constructor(private _medicalOrderRepository: MedicalOrderRepository) { }
    execute = (params: {
        type: MedicalOrderType;
        consultationId: number;
    }): Promise<Result<MedicalOrderResponseDom[], Failure>> => this._medicalOrderRepository.list(params.type,params.consultationId);
}