import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicalHistoryImplRepository } from './clinical-history-impl.repository';
import { ClinicalHistoryRepository } from 'domain/consultation/clinical-history/repositories/clinical-history.repository';
import { CreateClinicalHistoryUseCase } from 'domain/consultation/clinical-history/usecases/create-clinical-history.usecase';
import { UpdateClinicalHistoryUseCase } from 'domain/consultation/clinical-history/usecases/update-clinical-history.usecase';

export const consultationHistoryProvider = { provide: ClinicalHistoryRepository, useClass: ClinicalHistoryImplRepository }

@NgModule({
  providers: [
    consultationHistoryProvider,
    CreateClinicalHistoryUseCase,
    UpdateClinicalHistoryUseCase,
  ],
  imports: [
    CommonModule
  ]
})
export class ClinicalHistoryInfModule { }
