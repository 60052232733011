import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { NoResult } from 'base/usecase/query';
import { PatientRepository } from '../repositories/patient.repository';

@Injectable({ providedIn: 'root' })
export class DeletePatientByIdUseCase implements UseCase<number, Promise<Result<NoResult, Failure>>> {
    constructor(private _patientRepository: PatientRepository) { }
    execute = (params: number): Promise<Result<NoResult, Failure>> => this._patientRepository.delete(params);
}