
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment, UrlTree, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { IsActiveSessionUseCase } from 'domain/session/usecases/is-active-session.usecase';
import { NoParams } from 'base/usecase/query';
import { AuthRoutings } from 'presentation/app/core/authentication/authentication-routing.module';
import { NavigationService } from '../services/navigation.service';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor( 
    private _navigation: NavigationService,
    private  _isActiveSessionUseCase: IsActiveSessionUseCase) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuthenticated = this._isActiveSessionUseCase.execute(NoParams);
    if(!isAuthenticated)
      this._navigation.navigateTo(AuthRoutings.LOGIN);
    return isAuthenticated
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Promise<boolean | UrlTree> {
    let isAuthenticated = this._isActiveSessionUseCase.execute(NoParams);
    if(!isAuthenticated)
      this._navigation.navigateTo(AuthRoutings.LOGIN);
    return isAuthenticated
  }
}
