<!--Login Page Starts-->
<section id="login">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 m-0 p-0">
          <ngb-carousel *ngIf="images" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
            <ng-template ngbSlide *ngFor="let image of images">
              <div id="carousel" class="picsum-img-wrapper carousel-size">
                <img [src]="image"  class="img-carousel" alt="Random slide" width="auto">
              </div>
            </ng-template>
          </ngb-carousel>
      </div>
      <div class="col-lg-8   d-flex align-items-center justify-content-center">
          <div class="card" >
            <div class="card-content ">
              <div class="card-body login-img mt-3 m-3 p-6" >
                <div class="text-center row">
                  <div class="col-lg-12">
                    <img src="{{assets}}/img/logos/logo-color.png"  width="200"/>
                  </div>
                </div>
                <form *ngIf="formGroupLogin" [formGroup]="formGroupLogin">
                  <div class="row  align-middle">
                    <div class="col-lg-12  py-2 px-3 ">
                      <h4 class="card-title mb-2 text-center"> {{'login.title' | translate }}</h4><br>
                      <app-generic-input
                      [inputSizeClass]="'form-control-lg'"
                      [icon]="'ft-user'"
                      [showIcon]="true"
                      [required]="true"
                      [formGroup]="formGroupLogin" [inputType]="inputType.TEXT" [title]="''" [_placeholder]="'login.userName' | translate"
                      [formControlName]="'username'"></app-generic-input>
                      <br>

                      <app-generic-input
                        [inputSizeClass]="'form-control-lg'"
                        [enabledIconEnd]="true"
                        [required]="true"
                        [icon]="'ft-lock'"
                        [showIcon]="true"
                        [formGroup]="formGroupLogin"
                        [inputType]="inputType.PASSWORD"
                        [title]="''"
                        [_placeholder]="'login.password' | translate"
                        [formControlName]="'password'"
                      ></app-generic-input>
                      <div class="row mt-3">
                        <div class="col-lg-12 text-right">
                          <app-generic-button (onClick)="onLogin(formGroupLogin)" [disabled]="formGroupLogin.invalid" [icon]="'icon-arrow-right'" [title]="'actions.enter' | translate"></app-generic-button>
                        </div>
                      </div>
                      <div class="d-sm-flex justify-content-between mb-3 font-small-2 mt-2">
                        <div class="remember-me mb-2 mb-sm-0">
                          <div class="checkbox auth-checkbox">
                            <input type="checkbox" formControlName="rememberme" class="form-control" id="rememberme">
                            <label for="rememberme"><span class="font-small-2 mb-3 font-weight-normal">{{'login.remember' | translate}}</span></label>
                          </div>
                        </div>
                        <a class="success" [routerLink]="['/pages/forgotpassword']">{{'login.forgetPassword' | translate}}</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->
<app-generic-loading [text]="'login.loading.verifyingInformation' | translate"></app-generic-loading>
