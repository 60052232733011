import { ChangeDetectorRef, Component, ViewChild, TemplateRef, OnInit, AfterViewInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Column } from 'presentation/app/shared/components/generic-datatable/model/columns.model';
import { PagedData } from 'presentation/app/shared/components/generic-datatable/model/paged-data';
import { MedicationListService } from './medication-list.service';
import { takeUntil } from 'rxjs/operators';
import { BasePaginationComponent } from 'presentation/app/shared/components/base/base-pagination-component';
import { MedicationResponseDom } from 'domain/administration/medication/models/medication.response.dom';
import { ListMedicationsUseCase } from 'domain/administration/medication/usecases/list-medications.usecase';
import { SweetAlertService } from 'presentation/app/shared/services/sweet-alert.service';
import { GenericModalService } from 'presentation/app/shared/components/generic-modal/generic-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DeleteMedicationByIdUseCase } from 'domain/administration/medication/usecases/delete-medication-by-id.usecase';
import { PageableMedicationsRequestDom } from 'domain/administration/medication/models/medication.request.dom';
import { BasePaginationRequest } from 'base/pagination/pagination';
import { Pageable } from 'base/pagination/pageable';
import { MedicationModalComponent } from '../medication-modal/medication-modal.component';
import { CrudOperation } from 'presentation/app/shared/utils/utils.resource';

@Component({
  selector: 'app-medication-list',
  templateUrl: './medication-list.component.html',
  styleUrls: ['./medication-list.component.scss']
})
export class MedicationListComponent  extends BasePaginationComponent<MedicationResponseDom>{
  @ViewChild('cellTemplateOptions')
  public cellTemplateOptions: TemplateRef<any>;
  public columns:Array<Column> = [];
  constructor(
    _route: ActivatedRoute,
    private _cd: ChangeDetectorRef,
    private _medicationListService: MedicationListService,
    private _listMedicationsUseCase: ListMedicationsUseCase,
    private _notify: SweetAlertService,
    public translate: TranslateService,
    private _modalService: GenericModalService,
    private _deleteMedicationByIdUseCase: DeleteMedicationByIdUseCase
  ) {
    super(_route);
  }
  onInit(): void {
    this._cd.detectChanges();
    this._medicationListService.refresh$.pipe(takeUntil(this._unsubscribeAll))
    .subscribe((_) => this.callOnLoadData());
  }
  onAfterViewInit(): void {
    this.load()
  }

  load(){
    this.createColumns();
    this.callOnLoadData();
    this._cd.detectChanges();
  }
  createColumns = () => setTimeout(() => {
    this.columns=[
      { name: this.translate.instant('medication.table.name'),prop:'name', sortable:true },
      { name: this.translate.instant('medication.table.type'),prop:'typeName', propSortable:'TypeList.Name', sortable:true },
      { name:this.translate.instant('actions.options'),prop:"Opciones",cellTemplate:this.cellTemplateOptions, sortable:false, width:130, maxWidth:130}
    ]
  },10)
  async onLoadData() {
    let result = await this._listMedicationsUseCase.execute(new PageableMedicationsRequestDom(<BasePaginationRequest>{
       search: this.page.filterSearch,
       pagination: this.requestPagination()
     }))
     result.foldRight((data: Pageable<MedicationResponseDom>) => {
       this.setPageData(data)
       this._cd.detectChanges();
     })
   }
   onEdit(data: MedicationResponseDom) {
     this._modalService.openModal(MedicationModalComponent, { action: CrudOperation.EDIT_RECORD, data: data}, null);
   }
 
   async onDelete(data: MedicationResponseDom) {
     let notify=await this._notify.showAlertConfirmation(this.translate.instant('alerts.title'),this.translate.instant('alerts.confirmDelete'),
     'question',this.translate.instant('actions.confirm'),true,this.translate.instant('actions.cancel'));
     if (!notify) return;
     let result = await this._deleteMedicationByIdUseCase.execute(data.id)
     if (result.isLeft()) return;
     this.callOnLoadData()
     this._notify.showToastSucces()
   }
   onDestroy(): void {}
   onChanges(changes: SimpleChanges): void {}
}
