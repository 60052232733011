import { ConsultationPartialResponseDto, ConsultationResponseDto, CreateConsultationRequestDto, UpdateConsultationRequestDto } from "../dtos/consultation.dto";
import { ConsultationFoundResponseDom, ConsultationPartialResponseDom, ConsultationResponseDom } from "domain/consultation/consultation/models/consultation.response.dom";
import { CreateConsultationRequestDom, UpdateConsultationRequestDom } from "domain/consultation/consultation/models/consultation.request.dom";
import { Pageable } from "base/pagination/pageable";
import { PaginationMapper } from "base/pagination/pagination-mapper";
import { BaseResponseApi } from "base/api/base-api";
import { ClinicalHistoryMapper } from "infraestructure/consultation/clinical-history/mappers/clinical-history.mapper";
import { PregnancyMapper } from "infraestructure/consultation/pregnancy/mappers/pregnancy.mapper";
import { PhysicalExamMapper } from "infraestructure/consultation/physical-exam/mappers/physical-exam.mapper";
import { Utils } from "presentation/app/shared/utils/utils.resource";
import { ConsultationFoundResponseDto } from "../dtos/consultation-found.dto";
import { PatientMapper } from "infraestructure/patient/mappers/patient.mapper";
import { UltrasoundMapper } from "infraestructure/plans/ultrasound/mappers/ultrasound.mapper";
import { MedicineMapper } from "infraestructure/plans/medicines/mappers/medicine.mapper";
import { MedicalOrderMapper } from "infraestructure/plans/medical-order/mappers/medical-order.mapper";
import { MedicalOrderType } from "domain/item-list/constants/item-list.const";

export class ConsultationMapper {
    static toPageableDom(param: BaseResponseApi<ConsultationResponseDto>): Pageable<ConsultationResponseDom> {
        return <Pageable>{
            data: param.data.map(ConsultationMapper.toDom),
            pagination: PaginationMapper.toPagin(param.paging)
        };
    }
    static toDom(param: ConsultationResponseDto): ConsultationResponseDom {
        return {
            consultationNumber: param.numberConsultation,
            createDate: param.createdDate,
            id: param.id,
            diagnosis: param.diagnosis,
            patientId: param.patientId,
            reason: param.reason,
            state: param.state,
            dateOfConsultation: param.dateOfConsultation,
            additionalNote: param.additionalMedicalOrderNote
        };
    }

    static toFoundDom(param: ConsultationFoundResponseDto): ConsultationFoundResponseDom {
        return {
            consultation: ConsultationMapper.toDom(param.consultationResponseDto),
            patient: PatientMapper.toDom(param.consultationResponseDto.patient),
            gynecologicalHistory: ClinicalHistoryMapper.toDom(param.medicalHistoryResponseDto).gynecological,
            personalHistory: ClinicalHistoryMapper.toDom(param.medicalHistoryResponseDto).personal,
            pregnancy: PregnancyMapper.toDom(param.gynecological_PregnancyResponseDto),
            physicalExam: PhysicalExamMapper.toDom(param.physical_ExaminationResponseDto),
            ultrasounds: param.ultrasoundResponseDto.map(UltrasoundMapper.toDom),
            medications: param.requested_MedicationResponseDto.map(MedicineMapper.toDom),
            laboratories: param.medical_OrderResponseDto.filter(a => a.medicalPrescriptionItemCode == MedicalOrderType.TOM_LABORATORIO).map(MedicalOrderMapper.toDom),
            medicalProcedures: param.medical_OrderResponseDto.filter(a => a.medicalPrescriptionItemCode == MedicalOrderType.TOM_PROCEDIMIENTOS).map(MedicalOrderMapper.toDom),
            interconsultations: param.medical_OrderResponseDto.filter(a => a.medicalPrescriptionItemCode == MedicalOrderType.TOM_INTERCONSULTA).map(MedicalOrderMapper.toDom),
            diagnosticImages: param.medical_OrderResponseDto.filter(a=>a.medicalPrescriptionItemCode == MedicalOrderType.TOM_IMAGENES_DIAGNOSTICAS).map(MedicalOrderMapper.toDom)
        };
    }

    static toCreateDto(param: CreateConsultationRequestDom): CreateConsultationRequestDto {
        return <CreateConsultationRequestDto>{
            reason: param.params.reason,
            diagnosis: param.params.diagnosis,
            patientId: param.params.patientId,
            dateOfConsultation: param.params.dateOfConsultation,
        };
    }

    static toUpdateDto(param: UpdateConsultationRequestDom): UpdateConsultationRequestDto {
        return <UpdateConsultationRequestDto>{
            id: param.params.id,
            diagnosis: param.params.diagnosis,
            patientId: param.params.patientId,
            reason: param.params.reason,
            state: param.params.status,
            numberConsultation: param.params.consultationNumber,
            dateOfConsultation: param.params.dateOfConsultation,
            additionalMedicalOrderNote: param.params.additionalNote
        };
    }

    static toPartialDto(param: ConsultationPartialResponseDto): ConsultationPartialResponseDom {
        return {
            consultation: !Utils.isEmptyObject(param.consultationResponseDto) ?
                ConsultationMapper.toDom(param.consultationResponseDto) : null,
            clinicalHistory: !Utils.isEmptyObject(param.medicalHistoryResponseDto) ?
                ClinicalHistoryMapper.toDom(param.medicalHistoryResponseDto) : null,
            gynecologicalPregnancy: !Utils.isEmptyObject(param.gynecological_PregnancyResponseDto) ?
                PregnancyMapper.toDom(param.gynecological_PregnancyResponseDto) : null,
            physicalExamination: !Utils.isEmptyObject(param.physical_ExaminationResponseDto) ?
                PhysicalExamMapper.toDom(param.physical_ExaminationResponseDto) : null
        };
    }
}