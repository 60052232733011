import { Result } from "base/result/result";
import { Failure } from "base/failure/failure";
import { NoResult } from "base/usecase/query";
import { Pageable } from "base/pagination/pageable";
import { CreateMedicineRequestDom, UpdateMedicineRequestDom } from "../models/medicine.request.dom";
import { MedicineResponseDom } from "../models/medicine.response.dom";
export abstract class MedicineRepository {
    abstract create(request: CreateMedicineRequestDom): Promise<Result<MedicineResponseDom, Failure>>;
    abstract update(request: UpdateMedicineRequestDom): Promise<Result<MedicineResponseDom, Failure>>;
    abstract delete(id: number): Promise<Result<NoResult, Failure>>;
    abstract listByConsultationId(consultationId: number): Promise<Result<MedicineResponseDom[], Failure>>;
}