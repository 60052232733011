import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { GenericModalService } from './generic-modal.service';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenericModalComponent implements OnInit {
  @Input() title;
  @Input() titleColor="black";
  @Input() closeColor="black";
  @Input() titleSize="font-medium-3";

  @Input() showCloseButton= true;
  @Input() showHeader= true;
  @Output() onCloseEvent= new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal
    ) { }
  ngOnInit(): void {}
  onClose(){
    this.activeModal.close()
    this.onCloseEvent.next()
  }
}
