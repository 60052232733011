import { MedicalOrderResponseDom } from "domain/plans/medical-order/models/medical-order/medical-order.response.dom";
import { CancelMedicalOrderRequestDto, CreateMedicalOrderRequestDto, InterpretMedicalOrderRequestDto, MedicalOrderResponseDto } from "../dtos/medical-order.dto";
import { CancelMedicalOrderRequestDom, CreateMedicalOrderRequestDom, InterpretMedicalOrderRequestDom } from "domain/plans/medical-order/models/medical-order/medical-order.request.dom";


export class MedicalOrderMapper {
    static toDom(param: MedicalOrderResponseDto): MedicalOrderResponseDom {
        return {
            id: param.id,
            cancelReason: param.cancelReason,
            interpretation: param.interpretation,
            medicalPrescriptionId: param.medicalPrescriptionId,
            medicalPrescriptionName: param.medicalPrescriptionName,
            consultationId: param.consultationId,
            stateId: param.stateId,
            stateName: param.stateName,
            stateCode: param.stateCode,
            description: param.description
        };
    }

    static toCreateDto(param: CreateMedicalOrderRequestDom): CreateMedicalOrderRequestDto[]{
        return param.params.medicalPrescriptionId.map((value)=> <CreateMedicalOrderRequestDto>{
            medicalPrescriptionId: value,
            consultationId: param.params.consultationId,
            description: param.params.description
        })
    }

    static toCancelDto(param: CancelMedicalOrderRequestDom): CancelMedicalOrderRequestDto {
        return <CancelMedicalOrderRequestDto>{
            id: param.params.id,
            cancelReason: param.params.cancelReason,
            interpretationConsultationId: param.params.consultatioId,
            description: param.params.description
        };
    }

    static toInterpretDto(param: InterpretMedicalOrderRequestDom): InterpretMedicalOrderRequestDto {
        return <InterpretMedicalOrderRequestDto>{
            id: param.params.id,
            interpretation: param.params.interpretation,
            interpretationConsultationId: param.params.consultatioId
        };
    }
}