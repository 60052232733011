import { CreatePregnancyRequestDto, PregnancyResponseDto, UpdatePregnancyRequestDto } from "../dtos/pregnancy.dto";
import { PregnancyResponseDom } from "domain/consultation/pregnancy/models/pregnancy.response.dom";
import { CreatePregnancyRequestDom, UpdatePregnancyRequestDom } from "domain/consultation/pregnancy/models/pregnancy.request.dom";

export class PregnancyMapper {
    static toDom(param: PregnancyResponseDto): PregnancyResponseDom {
        return {
            id: param.id,
            pregnancy: param.pregnancy,
            numberPregnancies: param.numberPregnancies,
            miscarriage: param.miscarriage,
            cesareanSection: param.cesareanSection,
            ectopicPregnancy: param.ectopicPregnancy,
            molarPregnancy: param.molarPregnancy,
            liveBirths: param.liveBirths,
            consultationId: param.consultationId
        };
    }

    static toCreateDto(param: CreatePregnancyRequestDom): CreatePregnancyRequestDto {
        return <CreatePregnancyRequestDto>{
            pregnancy: param.params.pregnancy,
            numberPregnancies: param.params.numberPregnancies,
            miscarriage: param.params.miscarriage,
            cesareanSection: param.params.cesareanSection,
            ectopicPregnancy: param.params.ectopicPregnancy,
            molarPregnancy: param.params.molarPregnancy,
            liveBirths: param.params.liveBirths,
            consultationId: param.params.consultationId  
        };
    }

    static toUpdateDto(param: UpdatePregnancyRequestDom): UpdatePregnancyRequestDto {
        return <UpdatePregnancyRequestDto>{
            id: param.params.id,
            pregnancy: param.params.pregnancy,
            numberPregnancies: param.params.numberPregnancies,
            miscarriage: param.params.miscarriage,
            cesareanSection: param.params.cesareanSection,
            ectopicPregnancy: param.params.ectopicPregnancy,
            molarPregnancy: param.params.molarPregnancy,
            liveBirths: param.params.liveBirths,
            consultationId: param.params.consultationId
        };
    }
}