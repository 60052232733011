import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { UltrasoundResponseDom } from '../models/ultrasound.response';
import { CreateUltrasoundRequestDom } from '../models/ultrasound.request';
import { UltrasoundRepository } from '../repositories/ultrasound.repository';

@Injectable({ providedIn: 'root' })
export class CreateUltrasoundUseCase implements UseCase<CreateUltrasoundRequestDom, Promise<Result<UltrasoundResponseDom, Failure>>> {
    constructor(private _ultrasoundRepository: UltrasoundRepository) { }
    execute = (params: CreateUltrasoundRequestDom): Promise<Result<UltrasoundResponseDom, Failure>> => this._ultrasoundRepository.create(params);
}