import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PatientListService  {
  constructor() { }
  private onRefreshSubject = new Subject<void>();
  refresh$ = this.onRefreshSubject.asObservable();
  refresh(): void {
    this.onRefreshSubject.next();
  }
}
