import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UltrasoundImplRepository } from './ultrasound-impl.repository';
import { UltrasoundRepository } from 'domain/plans/ultrasound/repositories/ultrasound.repository';
import { CreateUltrasoundUseCase } from 'domain/plans/ultrasound/usecases/create-ultrasound.usecase';
import { DeleteUltrasoundByIdUseCase } from 'domain/plans/ultrasound/usecases/delete-ultrasound-by-id.usecase';
import { ListUltrasoundsByPatientIdUseCase } from 'domain/plans/ultrasound/usecases/list-ultrasounds-by-patient-id.usecase';
import { UpdateUltrasoundUseCase } from 'domain/plans/ultrasound/usecases/update-ultrasound.usecase';

export const provider = { provide: UltrasoundRepository, useClass: UltrasoundImplRepository }

@NgModule({
  providers: [
    provider,
    CreateUltrasoundUseCase,
    DeleteUltrasoundByIdUseCase,
    ListUltrasoundsByPatientIdUseCase,
    UpdateUltrasoundUseCase
  ],
  imports: [
    CommonModule
  ]
})
export class UltrasoundInfModule { }
