import { ChangeDetectorRef, Component, ViewChild, TemplateRef, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Pageable } from 'base/pagination/pageable';
import { BasePaginationRequest } from 'base/pagination/pagination';
import { PageableMedicalOrdersRequestDom } from 'domain/administration/medical-order/models/medical-order.request.dom';
import { MedicalOrderResponseDom } from 'domain/administration/medical-order/models/medical-order.response.dom';
import { ListMedicalsOrderUseCase } from 'domain/administration/medical-order/usecases/list-medicals-order.usecase';
import { BasePaginationComponent } from 'presentation/app/shared/components/base/base-pagination-component';
import { Column } from 'presentation/app/shared/components/generic-datatable/model/columns.model';
import { GenericModalService } from 'presentation/app/shared/components/generic-modal/generic-modal.service';
import { SweetAlertService } from 'presentation/app/shared/services/sweet-alert.service';
import { MedicalOrderModalComponent } from '../medical-order-modal/medical-order-modal.component';
import { CrudOperation } from 'presentation/app/shared/utils/utils.resource';
import { takeUntil } from 'rxjs/operators';
import { MedicalOrderListService } from './medical-order-list.service';
import { DeleteMedicalOrderByIdUseCase } from 'domain/administration/medical-order/usecases/delete-medical-order-by-id.usecase';
@Component({
  selector: 'app-medical-order-list',
  templateUrl: './medical-order-list.component.html',
  styleUrls: ['./medical-order-list.component.scss']
})
export class MedicalOrderListComponent extends BasePaginationComponent<MedicalOrderResponseDom>{
  @ViewChild('cellTemplateOptions')
  public cellTemplateOptions: TemplateRef<any>;
  public columns:Array<Column> = [];
  constructor(
    _route: ActivatedRoute,
    private _listMedicalsOrderUseCase: ListMedicalsOrderUseCase,
    private _cd: ChangeDetectorRef,
    private _notify: SweetAlertService,
    public translate: TranslateService,
    private _modalService: GenericModalService,
    private _medicalOrderListService: MedicalOrderListService,
    private _deleteMedicalOrderByIdUseCase: DeleteMedicalOrderByIdUseCase
    ) {
    super(_route);
  }
  onInit(): void {
    this._cd.detectChanges();
    this._medicalOrderListService.refresh$.pipe(takeUntil(this._unsubscribeAll))
    .subscribe((_) => this.callOnLoadData());
  }
  onAfterViewInit(): void {
    this.load()
  }
  load(){
    this.createColumns();
    this.callOnLoadData();
    this._cd.detectChanges();
  }
  createColumns = () => setTimeout(() => {
    this.columns=[
      { name: this.translate.instant('medicalOrder.table.name'),prop:'name', sortable:true },
      { name: this.translate.instant('medicalOrder.table.type'),prop:'typeName', propSortable:'MedicalPrescriptionType.Name', sortable:true },
      { name:this.translate.instant('actions.options'),prop:"Opciones",cellTemplate:this.cellTemplateOptions, sortable:false, width:130, maxWidth:130}
    ]
  },10)
  async onLoadData() {
   let result = await this._listMedicalsOrderUseCase.execute(new PageableMedicalOrdersRequestDom(<BasePaginationRequest>{
      search: this.page.filterSearch,
      pagination: this.requestPagination()
    }))
    result.foldRight((data: Pageable<MedicalOrderResponseDom>) => {
      this.setPageData(data)
      this._cd.detectChanges();
    })
  }
  onEdit(data: MedicalOrderResponseDom) {
    this._modalService.openModal(MedicalOrderModalComponent, { action: CrudOperation.EDIT_RECORD, data: data}, null);
  }

  async onDelete(data: MedicalOrderResponseDom) {
    let notify=await this._notify.showAlertConfirmation(this.translate.instant('alerts.title'),this.translate.instant('alerts.confirmDelete'),
    'question',this.translate.instant('actions.confirm'),true,this.translate.instant('actions.cancel'));
    if (!notify) return;
    let result = await this._deleteMedicalOrderByIdUseCase.execute(data.id)
    if (result.isLeft()) return;
    this.callOnLoadData()
    this._notify.showToastSucces()
  }
  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void {}
}