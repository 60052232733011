import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, BaseResponseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { MedicalOrderType } from 'domain/item-list/constants/item-list.const';
import { MedicalPrescriptionResponseDom } from 'domain/plans/medical-order/models/medical-prescription/medical-prescription.response.dom';
import { MedicalPrescriptionRepository } from 'domain/plans/medical-order/repositories/medical-prescription.repository';
import { environment } from 'environments/environment';
import { MedicalPrescriptionResponseDto } from './dtos/medical-prescription.dto';
import { catchError, map } from 'rxjs/operators';
import { MedicalPrescriptionMapper } from './mappers/medical-prescription.mapper';

@Injectable({
  providedIn: 'root'
})
export class MedicalPrescriptionImplRepository extends BaseApi implements  MedicalPrescriptionRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
  list(type: MedicalOrderType): Promise<Result<MedicalPrescriptionResponseDom[], Failure>> {
    return this.http
    .get<MedicalPrescriptionResponseDto[]>(`${this.baseUrl}/v1/MedicalPrescription/GetByCodeItemList/${type}`)
    .pipe(map(_ => this.baseOk(_.map(MedicalPrescriptionMapper.toDom))))
    .pipe(catchError((_) => this.baseCatch<MedicalPrescriptionResponseDom[]>(_))).toPromise();
  }
}
