import { ItemSelect } from "presentation/app/shared/components/generic-select/generic-select.component";
import { CHART_COLORS } from "./chart-color";

export const months: Array<ItemSelect> = [
    <ItemSelect>{ value: 1, name: 'Enero' },
    <ItemSelect>{ value: 2, name: 'Febrero' },
    <ItemSelect>{ value: 3, name: 'Marzo' },
    <ItemSelect>{ value: 4, name: 'Abril' },
    <ItemSelect>{ value: 5, name: 'Mayo' },
    <ItemSelect>{ value: 6, name: 'Junio' },
    <ItemSelect>{ value: 7, name: 'Julio' },
    <ItemSelect>{ value: 8, name: 'Agosto' },
    <ItemSelect>{ value: 9, name: 'Septiembre' },
    <ItemSelect>{ value: 10, name: 'Octubre' },
    <ItemSelect>{ value: 11, name: 'Noviembre' },
    <ItemSelect>{ value: 12, name: 'Diciembre' }
];

export const years: ItemSelect[] = (() => {
    const now = new Date().getUTCFullYear();
    const yearsArray = Array.from({ length: 6 }, (_, idx) => now - idx);
    return yearsArray.map((item) => ({
      value: item,
      name: item.toString(),
    }));
})();
  
export let currentMonth: number = (() => new Date().getUTCMonth() + 1)();
export const findMonthName = (ids: number[]): string => (() => {
  if(!ids || ids && ids.length == 0) return ''
  return months.filter(a => ids.includes(a.value)).map(a=>a.name).join(', ')
})();

export const findMonthColor = (id: number): string => (() => {
  const colores = Object.values(CHART_COLORS);
  const month = months.find(item => item.value === id);
  if (month) 
    return colores[month.value];
  return CHART_COLORS.grey;
})();

export let currentYear: number= (() => new Date().getUTCFullYear())();
