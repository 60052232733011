import { BasePaginationRequest } from "base/pagination/pagination"

export class CreateMedicationRequestDom {
    constructor(public params:
      {
        name: string,
        typeId: number,
        requiresCalculation: boolean
      }
      ) {}
}
  
export class UpdateMedicationRequestDom {
  constructor(public params:
      {
        id: number,
        name: string,
        typeId: number,
        requiresCalculation: boolean
      }
    ) {}
}
export class PageableMedicationsRequestDom {
  constructor(public params: BasePaginationRequest) {}
}