import { Result } from "base/result/result";
import { Failure } from "base/failure/failure";
import { NoResult } from "base/usecase/query";
import { CreateMedicalOrderRequestDom, PageableMedicalOrdersRequestDom, UpdateMedicalOrderRequestDom } from "../models/medical-order.request.dom";
import { MedicalOrderResponseDom } from "../models/medical-order.response.dom";
import { Pageable } from "base/pagination/pageable";

export abstract class MedicalOrderRepository {
    abstract create(request: CreateMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>>;
    abstract update(request: UpdateMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>>;
    abstract delete(id: number): Promise<Result<NoResult, Failure>>;
    abstract list(request: PageableMedicalOrdersRequestDom): Promise<Result<Pageable<MedicalOrderResponseDom>, Failure>>;
}