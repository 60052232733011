<!--SHOW DEMO-->
<!--https://napster2210.github.io/ngx-spinner/-->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-triangle-path"
  fullScreen= true,
>
  <p style="font-size: 20px; color: white">{{text}}</p>
</ngx-spinner>
