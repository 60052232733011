import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NoParams } from 'base/usecase/query';
import { CreateMedicalOrderRequestDom, UpdateMedicalOrderRequestDom } from 'domain/administration/medical-order/models/medical-order.request.dom';
import { MedicalOrderResponseDom } from 'domain/administration/medical-order/models/medical-order.response.dom';
import { ItemListDom } from 'domain/item-list/models/item-list.dom';
import { AllTypesMedicalOrdersUseCase } from 'domain/item-list/usecases/all-types-medical-orders.usecase';
import { BaseComponent } from 'presentation/app/shared/components/base/base-component';
import { ItemSelect } from 'presentation/app/shared/components/generic-select/generic-select.component';
import { MastersMapper } from 'presentation/app/shared/utils/masters.mapper.utils';
import { CrudOperation } from 'presentation/app/shared/utils/utils.resource';
@Component({
  selector: 'app-medical-order-form',
  templateUrl: './medical-order-form.component.html',
  styleUrls: ['./medical-order-form.component.scss']
})
  
export class MedicalOrderFormComponent extends BaseComponent {
  formGroup: FormGroup;
  @Output() onCancelEvent= new EventEmitter<any>();
  @Output() onCreateEvent= new EventEmitter<CreateMedicalOrderRequestDom>();
  @Output() onUpdateEvent = new EventEmitter<UpdateMedicalOrderRequestDom>();
  @Input() _modalParameters;
  listDataType: ItemSelect[] = []
  
  constructor(
    _route: ActivatedRoute,
    private _allTypesMedicalOrdersUseCase: AllTypesMedicalOrdersUseCase,
    private _formBuilder: FormBuilder,
    ) {
    super(_route);
  }
  onInit(): void {
    this.formGroup = this.createForm(this._modalParameters?.data)
    this.load()
  }
  createForm(data: MedicalOrderResponseDom = null): FormGroup {
    return this._formBuilder.group({
      id: [data?.id],
      name: [data?.name, [Validators.required, Validators.minLength(3)]],
      typeId: [data?.typeId, [Validators.required]]
    });
  }
  load(){
    this.getTypesMedicalOrder();
  }
  async getTypesMedicalOrder() {
    let result = await this._allTypesMedicalOrdersUseCase.execute(NoParams)
    result.foldRight((value: ItemListDom[]) =>  this.listDataType = MastersMapper.toSelect(value))
  }
  onAfterViewInit(): void { }
  onSave() { 
    let data = this.formGroup.getRawValue()
    if (this._crudValue == CrudOperation.EDIT_RECORD)
      this.onUpdateEvent.emit(new UpdateMedicalOrderRequestDom(data))
    else 
      this.onCreateEvent.emit(new CreateMedicalOrderRequestDom(data))
  }
  onCancel = () => this.onCancelEvent.emit()
  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void {}
}
