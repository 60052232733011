import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { ReportRepository } from '../repositories/report.repository';
import { NoResult } from 'base/usecase/query';

@Injectable({ providedIn: 'root' })
export class AllConsultationsMonthlyReportUseCase implements UseCase<{
    year: number,
    monthIds: number[]
}, Promise<Result<Blob, Failure>>> {
    constructor(private _ReportRepository: ReportRepository) { }
    execute = (params: {
        year: number,
        monthIds: number[]
    }): Promise<Result<Blob, Failure>> => this._ReportRepository.consultationsMonthly(params.year, params.monthIds);
}