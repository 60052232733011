import { ChangeDetectorRef, EventEmitter, forwardRef, Output, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ShowErrorsService } from 'presentation/app/shared/validators/show-errors.service';
import { $ } from 'protractor';

@Component({

  selector: 'app-generic-select',
  templateUrl: './generic-select.component.html',
  styleUrls: ['./generic-select.component.scss', '../../../../assets/sass/libs/select.scss'],
 // styleUrls: ['./generic-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenericSelectComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None
})
export class GenericSelectComponent implements OnInit, ControlValueAccessor {

  selectTypeResource = SelectType;
  @Input() selectType = SelectType.SELECT;
  @Input() title: String = "";
  @Input() placeholder: String = "Seleccione una opcion";

  @Input() formControlName: any = null;
  @Input() formGroupNameError = null;
  @Input() formGroup: FormGroup;
  @Input() formGroupValidator: FormGroup;
  @Input() items: ItemSelect[] = [];
  @Input() searchable: boolean = true;



  @Input() icon:any= "ft-chevrons-down";

  @Input() appendTo= ''; //body

  @Input() idSelect:any= this.formControlName;
  @Input() showIcon= false;
  @Input() showTextError= true;
  @Input() captureValue= true;

  @Input() required = false;
  @Input() enabledAll= false;
  @Input() defaultValue= null


  @Output() onChange = new EventEmitter<any>();
  @Output() onChangeAll = new EventEmitter<any>();

  constructor(
    private _cd:ChangeDetectorRef,
    public showErrorsService: ShowErrorsService,) {
  }
  writeValue(obj: any): void {
    //throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }
  ngOnInit() {
    if(this.formGroup && this.formControlName){
      this.defaultValue=this.formGroup.get(this.formControlName).value
    }
    
    if(this.enabledAll && !this.items.find(a=>a.all == true)){
      this.items.unshift(<ItemSelect>{ value: 0, name: 'Todos', all: true})
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  _onChange($event) {
    if (Array.isArray($event)) {
      if ($event.length > 0 && this.enabledAll) {
        if ($event[$event.length - 1] == 0) {
          $event = [0]
          this.defaultValue = [0];
          this.onChangeAll.next(this.items.filter(a=>a.all!==true))
          return;
        } else {
          $event = $event.filter(a=> a !==0)
          this.defaultValue = $event;
        }
      }
      let itemsSelected=  this.items.filter(a => $event.includes(a.value));
      this.onChange.next(itemsSelected)
    }else{
      let itemSelected=  this.items.find(a =>a.value== $event);
      this.onChange.next(itemSelected)
    }
  }

  selectAll(select: NgSelectComponent) {
    let index = this.items.findIndex(a => a.all === true)
    this.defaultValue = [index];
    this.onChangeAll.next([this.items.filter(a=>a.all!==true)])
    select.close()
  }

  unselectAll(select: NgSelectComponent) {
    let itemsSelected = [];
    this.defaultValue = itemsSelected
    this.onChange.next(itemsSelected)
    select.close()
  }
}


export enum SelectType {
  SELECT = "select",
  MULTISELECT = "multiselect"
}

export interface ItemSelect<Result = any> {
  data?: Result;
  name: string;
  value: any;
  selected?: boolean;
  disabled?: boolean;
  all?: boolean;
}

