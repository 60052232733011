import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { NoResult } from 'base/usecase/query';
import { environment } from 'environments/environment';
import { UltrasoundMapper } from './mappers/ultrasound.mapper';
import { UltrasoundRepository } from 'domain/plans/ultrasound/repositories/ultrasound.repository';
import { CreateUltrasoundRequestDom, UpdateUltrasoundRequestDom } from 'domain/plans/ultrasound/models/ultrasound.request';
import { UltrasoundResponseDom } from 'domain/plans/ultrasound/models/ultrasound.response';
import { UltrasoundResponseDto } from './dtos/ultrasound.dto';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UltrasoundImplRepository extends BaseApi implements  UltrasoundRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
  create(request: CreateUltrasoundRequestDom): Promise<Result<UltrasoundResponseDom, Failure>> {
    return this.http
    .post<UltrasoundResponseDto>(`${this.baseUrl}/v1/Ultrasound/Create`, JSON.stringify(UltrasoundMapper.toCreateDto(request)))
      .pipe(map(_ => this.baseOk(UltrasoundMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<UltrasoundResponseDom>(_))).toPromise();
  }
  update(request: UpdateUltrasoundRequestDom): Promise<Result<UltrasoundResponseDom, Failure>> {
    return this.http
    .put<UltrasoundResponseDto>(`${this.baseUrl}/v1/Ultrasound/Update`, JSON.stringify(UltrasoundMapper.toUpdateDto(request)))
      .pipe(map(_ => this.baseOk(UltrasoundMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<UltrasoundResponseDom>(_))).toPromise();
  }
  delete(id: number): Promise<Result<NoResult, Failure>> {
    return this.http
    .delete<any>(`${this.baseUrl}/v1/Ultrasound/Delete/${id}`)
      .pipe(map(_ => this.baseOk(NoResult)))
      .pipe(catchError(this.baseCatch)).toPromise();
  }
  listByPatientId(patientId: number): Promise<Result<UltrasoundResponseDom[], Failure>> {
    return this.http
    .get<UltrasoundResponseDto[]>(`${this.baseUrl}/v1/Ultrasound/Get/${patientId}`)
    .pipe(map(_ => this.baseOk(_.map(UltrasoundMapper.toDom))))
    .pipe(catchError((_) => this.baseCatch<UltrasoundResponseDom[]>(_))).toPromise();
  }
}