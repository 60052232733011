import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PregnancyImplRepository } from './pregnancy-impl.repository';
import { PregnancyRepository } from 'domain/consultation/pregnancy/repositories/pregnancy.repository';
import { UpdatePregnancyUseCase } from 'domain/consultation/pregnancy/usecases/update-pregnancy.usecase';
import { CreatePregnancyUseCase } from 'domain/consultation/pregnancy/usecases/create-preagnancy.usecase';

export const pregnancyProvider = { provide: PregnancyRepository, useClass: PregnancyImplRepository }
@NgModule({
  providers: [
    pregnancyProvider,
    CreatePregnancyUseCase,
    UpdatePregnancyUseCase
  ],
  imports: [
    CommonModule
  ]
})
export class PregnancyInfModule { }
