import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PatientRepository } from 'domain/patient/repositories/patient.repository';
import { PatientImplRepository } from './patient-impl.repository';
import { CreatePatientUseCase } from 'domain/patient/usecases/create-patient.usecase';
import { DeletePatientByIdUseCase } from 'domain/patient/usecases/delete-patient-by-id.usecase';
import { ListMedicalsOrderUseCase } from 'domain/administration/medical-order/usecases/list-medicals-order.usecase';
import { UpdatePatientUseCase } from 'domain/patient/usecases/update-patient.usecase';
import { FindPatientByIdUseCase } from 'domain/patient/usecases/find-patient-by-id.usecase';
import { FindInfoPatientByIdUseCase } from 'domain/patient/usecases/find-info-patient-by-id.usecase';

export const patientProvider = { provide: PatientRepository, useClass: PatientImplRepository }
@NgModule({
  providers: [
    patientProvider,
    CreatePatientUseCase,
    DeletePatientByIdUseCase,
    ListMedicalsOrderUseCase,
    UpdatePatientUseCase,
    FindPatientByIdUseCase,
    FindInfoPatientByIdUseCase
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class PatientInfModule { }