import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { MedicationRepository } from '../repositories/medication.repository';
import { MedicationResponseDom } from '../models/medication.response.dom';
import { NoParams } from 'base/usecase/query';

@Injectable({ providedIn: 'root' })
export class AllMedicationsUseCase implements UseCase<NoParams, Promise<Result<MedicationResponseDom[], Failure>>> {
    constructor(private _medicationRepository: MedicationRepository) { }
    execute = (_: NoParams): Promise<Result<MedicationResponseDom[], Failure>> => this._medicationRepository.all();
}