import { CreateMedicalOrderRequestDto, MedicalOrderResponseDto, UpdateMedicalOrderRequestDto } from "../dtos/medical-order.dto";
import { MedicalOrderResponseDom } from "domain/administration/medical-order/models/medical-order.response.dom";
import { CreateMedicalOrderRequestDom, UpdateMedicalOrderRequestDom } from "domain/administration/medical-order/models/medical-order.request.dom";
import { Pageable } from "base/pagination/pageable";
import { BaseResponseApi } from "base/api/base-api";
import { PaginationMapper } from "base/pagination/pagination-mapper";

export class MedicalOrderMapper {

    static toPageableDom(param: BaseResponseApi<MedicalOrderResponseDto>): Pageable<MedicalOrderResponseDom> {
        return <Pageable>{
            data: param.data.map(MedicalOrderMapper.toDom),
            pagination: PaginationMapper.toPagin(param.paging)
        };
    }

    static toDom(param: MedicalOrderResponseDto): MedicalOrderResponseDom {
        return {
            id: param.id,
            name: param.name,
            typeId: param.typeId,
            typeName: param.typeName
        };
    }

    static toCreateDto(param: CreateMedicalOrderRequestDom): CreateMedicalOrderRequestDto {
        return <CreateMedicalOrderRequestDto>{
            name: param.params.name,
            typeId: param.params.typeId
        };
    }

    static toUpdateDto(param: UpdateMedicalOrderRequestDom): UpdateMedicalOrderRequestDto {
        return <UpdateMedicalOrderRequestDto>{
            id: param.params.id,
            name: param.params.name,
            typeId: param.params.typeId
        };
    }
}