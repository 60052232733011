import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { MedicationRepository } from '../repositories/medication.repository';
import {  PageableMedicationsRequestDom } from '../models/medication.request.dom';
import { MedicationResponseDom } from '../models/medication.response.dom';
import { Pageable } from 'base/pagination/pageable';

@Injectable({ providedIn: 'root' })
export class ListMedicationsUseCase implements UseCase<PageableMedicationsRequestDom, Promise<Result<Pageable<MedicationResponseDom>, Failure>>> {
    constructor(private _medicationRepository: MedicationRepository) { }
    execute = (params: PageableMedicationsRequestDom): Promise<Result<Pageable<MedicationResponseDom>, Failure>> => this._medicationRepository.list(params);
}