import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthRepository } from 'domain/auth/repositories/auth.repository';
import { AuthMapper } from './mappers/auth.mapper';
import { environment } from 'environments/environment';
import { LoginResponseDom } from 'domain/auth/models/login-response.dom';
import { LoginRequestDom } from 'domain/auth/models/login-request.dom';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { LoginResponseDto } from './dtos/auth.dto';
import { NoResult } from 'base/usecase/query';
import { ChangePasswordRequestDom } from 'domain/auth/models/password-request.dom';
import { BaseApi } from 'base/api/base-api';

@Injectable({
    providedIn: 'root',
})
export class UserImplRepository extends BaseApi implements AuthRepository {
    baseUrl = environment.securityUrl;
    constructor(private http: HttpClient) {
        super();
    }
    async login(request: LoginRequestDom): Promise<Result<LoginResponseDom, Failure>> {
        return this.http
        .post<HttpResponse<LoginResponseDto>>(`${this.baseUrl}/Login`, JSON.stringify(AuthMapper.toDto(request)), {
                headers:new HttpHeaders().set('Content-Type', 'application/json'),
                observe: 'response'
            })
            .pipe(map((_: HttpResponse<any>) => this.baseResponse(_, ()=> AuthMapper.toDom(_))))
            .pipe(catchError((_) => this.baseCatch<LoginResponseDom>(_))).toPromise();
    }
    changePassword(request: ChangePasswordRequestDom): Promise<Result<NoResult, Failure>> {
        return this.http.put<any>(`${this.baseUrl}/ChangePassword`, JSON.stringify(AuthMapper.toChangePasswordDto(request)))
            .pipe(map((_) => this.baseOk(NoResult)))
            .pipe(catchError(this.baseCatch)).toPromise();
    }
}