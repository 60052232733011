import { BaseResponsePageableApi } from "base/api/base-api";
import { Pagination } from "./pagination";

export class PaginationMapper {
    static toPagin(param: BaseResponsePageableApi): Pagination {
        return <Pagination>{
            currentPage:param.currentPage,
            itemsPerPage:param.pageSize,
            totalCount: param.totalCount,
            totalPages: param.totalPages,
            hasNextPage: param.hasNextPage,
            nextPageNumber: param.nextPageNumber
        };
    }
}