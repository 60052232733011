import { ItemListDom } from "domain/item-list/models/item-list.dom";
import { ItemSelect } from "../components/generic-select/generic-select.component";

export class MastersMapper {
    static toSelect(param: ItemListDom[]): ItemSelect[] {
        return param.map(item => <ItemSelect<ItemListDom>>{
            value: item.id,
            name: item.name,
            data: item
        })
    }
}