import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { ConsultationFoundResponseDom } from '../models/consultation.response.dom';
import { ConsultationRepository } from '../repositories/consultation.repository';

@Injectable({ providedIn: 'root' })
export class FindByIdConsultationUseCase implements UseCase<number, Promise<Result<ConsultationFoundResponseDom, Failure>>> {
    constructor(private _consultationRepository: ConsultationRepository) { }
    execute = (params: number): Promise<Result<ConsultationFoundResponseDom, Failure>> => this._consultationRepository
        .findById(params)
}
