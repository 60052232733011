import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { StatisticRepository } from '../repositories/statistic.repository';
import { AnualSerie } from '../models/statistics.response.dom';

@Injectable({ providedIn: 'root' })
export class AllConsultationsAnnualUseCase implements UseCase<number, Promise<Result<AnualSerie[], Failure>>> {
    constructor(private _StatisticRepository: StatisticRepository) { }
    execute = (params: number): Promise<Result<AnualSerie[], Failure>> => this._StatisticRepository.consultationsAnnual(params);
}