import { BasePaginationRequest, Pagination } from "base/pagination/pagination"

export class CreateMedicalOrderRequestDom {
    constructor(public params:
      {
        name: string,
        typeId: number
      }
      ) {}
}
  
export class UpdateMedicalOrderRequestDom {
    constructor(public params:
        {
            id: number,
            name: string,
        typeId: number
        }
      ) {}
}
  
export class PageableMedicalOrdersRequestDom {
  constructor(public params: BasePaginationRequest) {}
}