import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRepository } from 'domain/auth/repositories/auth.repository';
import { UserImplRepository } from './auth-impl.repository';
import { HttpClientModule } from '@angular/common/http';
import { AuthLoginUseCase } from 'domain/auth/usecases/auth/auth-login.usecase';
import { sessionProvider } from 'infraestructure/session/session-inf.module';
import { AuthChangePasswordUseCase } from 'domain/auth/usecases/auth/auth-change-password.usecase';

export const authProvider = { provide: AuthRepository, useClass: UserImplRepository }
@NgModule({
    providers: [
        AuthLoginUseCase,
        AuthChangePasswordUseCase,
        authProvider,
        sessionProvider,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
    ],
})
export class AuthInfModule { }
