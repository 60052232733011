import { NgModule } from '@angular/core';
import { AuthInfModule } from './auth/auth-inf.module';
import { SessionInfModule } from './session/session-inf.module';
import { MedicationInfModule } from './administration/medications/medication-inf.module';
import { ItemsListInfModule } from './item-list/items-list-inf.module';
import { MedicalOrderInfModule } from './administration/medical-orders/medical-order-inf.module';
import { PatientInfModule } from './patient/patient-inf.module';
import { ClinicalHistoryInfModule } from './consultation/clinical-history/clinical-history-inf.module';
import { ConsultationInfModule } from './consultation/consultation/consultation-inf.module';
import { PregnancyInfModule } from './consultation/pregnancy/pregnancy-inf.module';
import { PhysicalExamInfModule } from './consultation/physical-exam/physical-exam-inf.module';
import { MedicalOrderInfModule as _MedicalOrderInfModule } from './plans/medical-order/medical-order-inf.module';
import { MedicalPrescriptionInfModule } from './plans/medical-prescription/medical-prescription-inf.module';
import { UltrasoundInfModule } from './plans/ultrasound/ultrasound-inf.module';
import { MedicinesInfModule } from './plans/medicines/medicines-inf.module';
import { StatisticModule } from './statistic/statistic.module';
import { IncapacityInfModule } from './incapacity/incapacity-inf.module';

@NgModule({
  imports: [
    AuthInfModule,
    SessionInfModule,
    MedicationInfModule,
    MedicalOrderInfModule,
    ItemsListInfModule,
    PatientInfModule,
    ClinicalHistoryInfModule,
    ConsultationInfModule,
    PhysicalExamInfModule,
    PregnancyInfModule,

    //Plans
    _MedicalOrderInfModule,
    MedicalPrescriptionInfModule,
    MedicinesInfModule,
    UltrasoundInfModule,

    //Statistic
    StatisticModule,

    //Incapacidades
    IncapacityInfModule
  ],
  exports: []
})
export class IocModule { }
