<section>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h5 class="form-section" style="color: #342e49">
            <a (click)="goToBack()" style="margin-right: 5px"
              ><i class="ft-arrow-left"></i
            ></a>
            Actualizar Contraseña
          </h5>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form novalidate *ngIf="updatePasswordForm" [formGroup]="updatePasswordForm">
              <div class="row">
                <div class="col-md-4">
                  <app-generic-input
                    [required]="true"
                    [formGroup]="updatePasswordForm"
                    [inputType]="inputType.PASSWORD"
                    [title]="'Contraseña Actual'"
                    [formControlName]="'currentPassword'"
                  ></app-generic-input>
                </div>
                <div class="col-md-4">
                  <app-generic-input
                    [required]="true"
                    [formGroup]="updatePasswordForm"
                    [inputType]="inputType.PASSWORD"
                    [title]="'Nueva Contraseña'"
                    [formControlName]="'initialNewPassword'"
                  ></app-generic-input>
                </div>
                <div class="col-md-4">
                  <app-generic-input
                    [required]="true"
                    [formGroup]="updatePasswordForm"
                    [inputType]="inputType.PASSWORD"
                    [title]="'Repita la  Contraseña'"
                    [formControlName]="'newPassword'"
                  ></app-generic-input>
                </div>
              </div>
              <br /><br />
              <div
                class="form-actions right"
                style="display: flex; justify-content: flex-end"
              >
                <button
                  type="button"
                  (click)="goToBack()"
                  class="btn bg-secondary white btn-xs"
                >
                  <i class="ft-x"></i> Cancelar
                </button>
                <button
                  type="button"
                  (click)="onChangePassword()"
                  class="btn bg-success white btn-xs ml-2"
                  [disabled]="updatePasswordForm.invalid"
                >
                  <i class="ft-save"></i> Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
