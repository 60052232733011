//line chart
import { ChartOptions, ChartType } from 'chart.js';
import { transparentize } from './chart-color';
export let lineChartDataSet = (data: DataSet[]):Array<any> => (() => {
  return data.map(value => {
    return {
      label: value.label,
      data: value.data,
      borderColor: value.color,
      backgroundColor: transparentize(value.color, 0.5),
      tension: 0.3,
      fill: false
    }
  })
})();

export const lineChartLabels = (labels: Array<ChartLabel>): Array<string> => (() => labels.map(item => item.label))();

export const lineChartOptions = (params: {
  title: string,
  xTitle?: string,
  yTitle?: string
}):ChartOptions => (() => {
  return {
    animation: {
      duration: 1000, // general animation time
      easing: 'easeOutBack'
    },
    aspectRatio: 2.5,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: params.title
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: params.xTitle
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: params.yTitle
        }
      }
    }
  }
})();
export let lineChartType: ChartType = 'line';
export interface DataSet{
  label: string;
  color: string;
  data: Array<any>;
}
export interface ChartLabel{
  label: string;
}
