import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { CreatePhysicalExamRequestDom, UpdatePhysicalExamRequestDom } from 'domain/consultation/physical-exam/models/physical-exam.request.dom';
import { PhysicalExamResponseDom } from 'domain/consultation/physical-exam/models/physical-exam.response.dom';
import { PhysicalExamRepository } from 'domain/consultation/physical-exam/repositories/physical-exam.repository.dom';
import { environment } from 'environments/environment';
import { PhysicalExamResponseDto } from './dtos/physical-exam.dto';
import { PhysicalExamMapper } from './mappers/physical-exam.mapper';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhysicalExamImplRepository extends BaseApi implements  PhysicalExamRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
  create(request: CreatePhysicalExamRequestDom): Promise<Result<PhysicalExamResponseDom, Failure>> {
    return this.http
    .post<PhysicalExamResponseDto>(`${this.baseUrl}/v1/Physical_Examination/Create`, JSON.stringify(PhysicalExamMapper.toCreateDto(request)))
      .pipe(map( _ => this.baseOk(PhysicalExamMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<PhysicalExamResponseDom>(_))).toPromise();
  }
  update(request: UpdatePhysicalExamRequestDom): Promise<Result<PhysicalExamResponseDom, Failure>> {
    return this.http
    .put<PhysicalExamResponseDto>(`${this.baseUrl}/v1/Physical_Examination/Update`, JSON.stringify(PhysicalExamMapper.toUpdateDto(request)))
      .pipe(map( _ => this.baseOk(PhysicalExamMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<PhysicalExamResponseDom>(_))).toPromise();
  }
}
