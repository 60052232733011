import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { UltrasoundResponseDom } from '../models/ultrasound.response';
import { UpdateUltrasoundRequestDom } from '../models/ultrasound.request';
import { UltrasoundRepository } from '../repositories/ultrasound.repository';
@Injectable({ providedIn: 'root' })
export class UpdateUltrasoundUseCase implements UseCase<UpdateUltrasoundRequestDom, Promise<Result<UltrasoundResponseDom, Failure>>> {
    constructor(private _ultrasoundRepository: UltrasoundRepository) { }
    execute = (params: UpdateUltrasoundRequestDom): Promise<Result<UltrasoundResponseDom, Failure>> => this._ultrasoundRepository.update(params);
}