import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ShowErrorsService } from 'presentation/app/shared/validators/show-errors.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-generic-input',
  templateUrl: './generic-input.component.html',
  styleUrls: ['./generic-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenericInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,

})

export class GenericInputComponent implements  OnInit,ControlValueAccessor,OnDestroy  {

  public _unsubscribeAll: Subject<any>;


  // @Input() buttonType: String;
  @Input() inputType=InputType.TEXT;
  @Input() title: String="";
  @Input() formControlName:any = null;
  @Input() confirmValidParentMatcher:any = false;
  @Input() formGroupNameError=null;
  @Input() formGroup: FormGroup;
  @Input() formGroupValidator: FormGroup;
  @Input() showIcon= false;
  @Input() showTextError= true;
  @Input() icon:any= "ft-user";
  @Input() _placeholder = "";
  @Input() required= false;
  @Input() disabled= false;
  @Input() value= "";
  @Input() controlColor='primary';
  @Input() rowsTextTarea=3;
  @Input() mask="";


  @Input() enabledIconEnd= false;
  @Input() iconEnd= '';



  @Input() inputSizeClass='form-control-sm'; // form-control-lg  (or) form-control-sm
  @Input() inputCustomClass=''; // form-control-lg  (or) form-control-sm


  @Output() eventChange = new EventEmitter<any>();
  @Output() eventChangeButtonEnd = new EventEmitter<any>();



  //getConfirmValidParentMatcher  = new ConfirmValidParentMatcher();
  inputTypeLocal=this.inputType;

  // For Input Password
  inputTypeResource=InputType;
  visible = false;

  constructor(
    private _cd:ChangeDetectorRef,
    public showErrorsService:ShowErrorsService,
    private cd: ChangeDetectorRef) {
      this._unsubscribeAll= new Subject()

    }
  ngOnDestroy(): void {
    this._unsubscribeAll.complete()
    this._unsubscribeAll.next()
    this._unsubscribeAll.unsubscribe()
  }

  ngOnInit(): void {
    this.inputTypeLocal=this.inputType;
    this._cd.detectChanges();

    this.formGroup.get(this.formControlName).valueChanges
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((value)=>{
      setTimeout(()=>{
        this.eventChange.next(value);
      },500)
    })
  }

  // Se implementa para detectar cambios
  ngAfterContentChecked() {
  // this._cd.detectChanges();
  }

  get isValid() { return this.formGroup.get(this.formControlName).valid; }
  get isTochedOrDirty() {
    return this.formGroup.get(this.formControlName)?.touched || this.formGroup.get(this.formControlName)?.dirty ? true: false ;
  }


  writeValue(obj: any): void {
    //throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }

  eventChangeValue(){
    this.eventChange.next(this.formGroup.get(this.formControlName).value);
  }

  toggleShowPassword(){
    if(this.inputTypeLocal==InputType.PASSWORD){
      this.inputTypeLocal =InputType.TEXT ;
    }else{
      this.inputTypeLocal =InputType.PASSWORD ;
    }

    this.cd.markForCheck();

  }
  toggleIconEnd(){
    this.eventChangeButtonEnd.next()
  }
}



export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  CHECKBOX = 'checkbox',
  TEXTAREA='textarea'
}
