import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, BaseResponseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Pageable } from 'base/pagination/pageable';
import { Result } from 'base/result/result';
import { NoResult } from 'base/usecase/query';
import { CreateMedicalOrderRequestDom, PageableMedicalOrdersRequestDom } from 'domain/administration/medical-order/models/medical-order.request.dom';
import { MedicalOrderResponseDom } from 'domain/administration/medical-order/models/medical-order.response.dom';
import { MedicalOrderRepository } from 'domain/administration/medical-order/repositories/medical-order.repository';
import { UpdateMedicationRequestDom } from 'domain/administration/medication/models/medication.request.dom';
import { environment } from 'environments/environment';
import { MedicalOrderResponseDto } from './dtos/medical-order.dto';
import { MedicalOrderMapper } from './mappers/medical-order.mapper';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedicalOrderImplRepository extends BaseApi implements MedicalOrderRepository {
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  }

  create(request: CreateMedicalOrderRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>> {
    return this.http
    .post<MedicalOrderResponseDto>(`${this.baseUrl}/v1/MedicalPrescription/Create`, JSON.stringify(MedicalOrderMapper.toCreateDto(request)))
      .pipe(map( _ => this.baseOk(MedicalOrderMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicalOrderResponseDom>(_))).toPromise();
  }
  update(request: UpdateMedicationRequestDom): Promise<Result<MedicalOrderResponseDom, Failure>> {
    return this.http
    .put<MedicalOrderResponseDto>(`${this.baseUrl}/v1/MedicalPrescription/Update`, JSON.stringify(MedicalOrderMapper.toUpdateDto(request)))
      .pipe(map( _ => this.baseOk(MedicalOrderMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<MedicalOrderResponseDom>(_))).toPromise();
  }
  delete(id: number): Promise<Result<NoResult, Failure>> {
    return this.http
      .delete<any>(`${this.baseUrl}/v1/MedicalPrescription/Delete/${id}`)
      .pipe(map(_ => this.baseOk(NoResult)))
      .pipe(catchError(this.baseCatch)).toPromise();
  }

  list(request: PageableMedicalOrdersRequestDom): Promise<Result<Pageable<MedicalOrderResponseDom>, Failure>> {
    const params = this.basePageable(request.params)
    return this.http
      .get<BaseResponseApi<MedicalOrderResponseDto>>(`${this.baseUrl}/v1/MedicalPrescription/GetAll`, {
        params: params
      })
      .pipe(map( _ => this.baseOk(MedicalOrderMapper.toPageableDom(_))))
      .pipe(catchError((_) => this.baseCatch<Pageable<MedicalOrderResponseDom>>(_))).toPromise();
  }
}