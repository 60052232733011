import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticImplRepository } from './statistic-impl.repository';
import { StatisticRepository } from 'domain/statistics/repositories/statistic.repository';
import { AllConsultationsMonthlyUseCase } from 'domain/statistics/usecases/all-consultation-monthly.usecase';
import { AllConsultationsAnnualUseCase } from 'domain/statistics/usecases/all-consultation-annual.usecase';
import { GetConsolidatedUseCase } from 'domain/statistics/usecases/get-consolidated.usecase';
import { ReportRepository } from 'domain/statistics/repositories/report.repository';
import { ReportImplRepository } from './report-impl.repository';


export const provider = { provide: StatisticRepository, useClass: StatisticImplRepository }
export const providerReport = { provide: ReportRepository, useClass: ReportImplRepository }

@NgModule({
  providers: [
    provider,
    providerReport,
    AllConsultationsAnnualUseCase,
    AllConsultationsMonthlyUseCase,
    GetConsolidatedUseCase
  ],
  imports: [
    CommonModule
  ]
})
export class StatisticModule { }
