import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { NoResult } from 'base/usecase/query';
import { UltrasoundRepository } from '../repositories/ultrasound.repository';

@Injectable({ providedIn: 'root' })
export class DeleteUltrasoundByIdUseCase implements UseCase<number, Promise<Result<NoResult, Failure>>> {
    constructor(private _ultrasoundRepository: UltrasoundRepository) { }
    execute = (params: number): Promise<Result<NoResult, Failure>> => this._ultrasoundRepository.delete(params);
}