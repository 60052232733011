import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { Pageable } from 'base/pagination/pageable';
import { PageableMedicalOrdersRequestDom } from '../models/medical-order.request.dom';
import { MedicalOrderResponseDom } from '../models/medical-order.response.dom';
import { MedicalOrderRepository } from '../repositories/medical-order.repository';

@Injectable({ providedIn: 'root' })
export class ListMedicalsOrderUseCase implements UseCase<PageableMedicalOrdersRequestDom, Promise<Result<Pageable<MedicalOrderResponseDom>, Failure>>> {
    constructor(private _medicalOrderRepository: MedicalOrderRepository) { }
    execute = (params: PageableMedicalOrdersRequestDom): Promise<Result<Pageable<MedicalOrderResponseDom>, Failure>> => this._medicalOrderRepository.list(params);
}