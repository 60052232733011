import { ChangeDetectorRef, Component, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CrudOperation, Utils } from "presentation/app/shared/utils/utils.resource";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ItemSelect } from "presentation/app/shared/components/generic-select/generic-select.component";
import { CustomValidators } from "presentation/app/shared/validators/custom-validators";
import { NavigationService } from "presentation/app/shared/services/navigation.service";
import { BaseComponent } from "presentation/app/shared/components/base/base-component";
import { AllGendersUseCase } from "domain/item-list/usecases/all-genders.usecase";
import { AllTypesIdentificationsUseCase } from "domain/item-list/usecases/all-types-identifications.usecase";
import { NoParams } from "base/usecase/query";
import { ItemListDom } from "domain/item-list/models/item-list.dom";
import { MastersMapper } from "presentation/app/shared/utils/masters.mapper.utils";
import { PatientFoundResponseDom } from "domain/patient/models/patient.response.dom";
import { FindPatientByIdUseCase } from "domain/patient/usecases/find-patient-by-id.usecase";
import { CreatePatientRequestDom, UpdatePatientRequestDom } from "domain/patient/models/patient.request.dom";
import { CreatePatientUseCase } from "domain/patient/usecases/create-patient.usecase";
import { UpdatePatientUseCase } from "domain/patient/usecases/update-patient.usecase";
import { PatientListService } from "../patient-list/patient-list.service";
import { SweetAlertService } from "presentation/app/shared/services/sweet-alert.service";
import { TranslateService } from "@ngx-translate/core";
import { ITEM_LIST } from "domain/item-list/constants/item-list.const";
import { SymbolsUtil } from "base/utils/symbols.utils";

@Component({
  selector: "app-patient-form",
  templateUrl: "./patient-form.component.html",
  styleUrls: ["./patient-form.component.scss"],
})
export class PatientFormComponent extends BaseComponent {
  formGroup: FormGroup;
  listDataGenders:ItemSelect<ItemListDom>[]=[]
  listDataIdentificationsTypes:ItemSelect<ItemListDom>[]=[]
  
  constructor(
    _route: ActivatedRoute,
    private _navigation: NavigationService,
    private _patientListService: PatientListService,
    private _allGendersUseCase: AllGendersUseCase,
    private _allTypesIdentificationsUseCase: AllTypesIdentificationsUseCase,
    private _findPatientByIdUseCase: FindPatientByIdUseCase,
    private _createPatientUseCase: CreatePatientUseCase,
    private _updatePatientUseCase: UpdatePatientUseCase,
    private _formBuilder: FormBuilder,
    private _notify: SweetAlertService,
    private _translate: TranslateService,
    private _cd: ChangeDetectorRef
    ) {
    super(_route);
  }
  onInit(): void {
    this.formGroup = this.createForm()
    this.load()
    this.getMastersLists()
    Utils.markFormGroupTouched(this.formGroup)
  }
  onAfterViewInit(): void { }
  createForm(data: PatientFoundResponseDom = null): FormGroup {
    return this._formBuilder.group({
      id: [data?.id],
      names: [data?.names, [Validators.required]],
      lastNames: [data?.lastNames, [Validators.required]],
      identificationNumber: [data?.identificationNumber, [Validators.required, Validators.maxLength(20), CustomValidators.validateOnlyNumber]],
      dateOfBirth: [data?.dateOfBirth, [Validators.required]],
      phone: [data?.phone ?? "", [CustomValidators.validateOnlyNumber, Validators.maxLength(15)]],
      address: [data?.address ?? ""],
      genderId: [data?.genderId, [Validators.required, CustomValidators.validateOnlyNumber]],
      identificationTypeId: [data?.identificationTypeId, [Validators.required]]
    });
  }
  load() {
    if (this._crudValue === CrudOperation.EDIT_RECORD) this.findPatient()
    this.getMastersLists();
  }
  async findPatient() {
    let id = this._routeParameters.id;
    let result = await this._findPatientByIdUseCase.execute(Number(id))
    result.foldRight((value: PatientFoundResponseDom) => {
      this.formGroup = this.createForm(value)
      this._cd.detectChanges()
    })
  }
  async getMastersLists() {
    let resultGenders = await this._allGendersUseCase.execute(NoParams)
    resultGenders.foldRight((value: ItemListDom[]) => {
      this.listDataGenders = MastersMapper.toSelect(value)
      this.setDefault(value,ITEM_LIST.G_FEMENINO, 'genderId' )
    })
    let resultIdentifiTypes = await this._allTypesIdentificationsUseCase.execute(NoParams)
    resultIdentifiTypes.foldRight((value: ItemListDom[]) => {
      this.listDataIdentificationsTypes = MastersMapper.toSelect(value)
      this.setDefault(value,ITEM_LIST.TI_CEDULA_CIUDADANIA, 'identificationTypeId' )
    })
  }
  setDefault(data: ItemListDom[], value: Symbol, property: string ) {
    if (this._crudValue == CrudOperation.EDIT_RECORD) return;
    let findDefault = data.find(a => a.code === SymbolsUtil.value(value))?.id
    if(findDefault) this.formGroup.get(property).setValue(findDefault)
  }

  onSave() { 
    let data = this.formGroup.getRawValue()
    if (this._crudValue == CrudOperation.EDIT_RECORD) 
      this.onUpdate(new UpdatePatientRequestDom(data))
    else 
      this.onCreate(new CreatePatientRequestDom(data))
  }
  async onCreate(request: CreatePatientRequestDom) {
    let result = await this._createPatientUseCase.execute(request)
    result.fold((_) => this.successResponse(), (_) => this.errorResponse('error.create'))
  }
  async onUpdate(request: UpdatePatientRequestDom) {
    let result = await this._updatePatientUseCase.execute(request)
    result.fold((_) => this.successResponse(), (_) => this.errorResponse('error.update'))
  }
  successResponse = () => {
    this._notify.showToastSucces()
    this._patientListService.refresh()
    this.goToBack()
  }
  errorResponse = (key: string) => this._notify.showAlertError(this._translate.instant('alerts.title'),this._translate.instant(key))
  onCancel = () => this._navigation.back()
  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void { }
  goToBack= () => this._navigation.back();
}