import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IsActiveSessionUseCase } from 'domain/session/usecases/is-active-session.usecase';
import { CurrentSessionUseCase } from 'domain/session/usecases/current-session.usecase';
import { NoParams } from 'base/usecase/query';
import { AuthRoutings } from 'presentation/app/core/authentication/authentication-routing.module';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {
  constructor(private router: Router,
    private _isActiveSessionUseCase: IsActiveSessionUseCase,
    private  _currentSessionUseCase: CurrentSessionUseCase) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._isActiveSessionUseCase.execute(NoParams)) {
      let session = this._currentSessionUseCase.execute(NoParams);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": !request.headers.has("Content-Type") ? "application/json": request.headers.get("Content-Type")
        }
      });
    }
    return next.handle(request).pipe(catchError((response: any) => {
      if (response.status === 401) {
        const redirectUrl = this.router.url;
        this.router.navigate([{ outlets: { popup: null } }]).then(() =>
          this.router.navigate([AuthRoutings.LOGIN], { queryParams: { redirectUrl } })
        );
        response.error = {error: {message: 'Session expired.'}};
      } 
      return throwError(response);
    }));
  }
}



