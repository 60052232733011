import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { CreateClinicalHistoryRequestDom } from '../models/clinical-history.request.dom';
import { ClinicalHistoryRepository } from '../repositories/clinical-history.repository';
import { ClinicalHistoryResponseDom } from '../models/clinical-history.response.dom';

@Injectable({ providedIn: 'root' })
export class CreateClinicalHistoryUseCase implements UseCase<CreateClinicalHistoryRequestDom, Promise<Result<ClinicalHistoryResponseDom, Failure>>> {
    constructor(private _clinicalHistoryRepository: ClinicalHistoryRepository) { }
    execute = (params: CreateClinicalHistoryRequestDom): Promise<Result<ClinicalHistoryResponseDom, Failure>> => this._clinicalHistoryRepository.create(params);
}