<div *ngIf="showHeader" class="modal-header">
  <!-- <h5 class="modal-title">{{title}}</h5> -->
  <span class="{{titleSize}} text-bold-600 text-center {{titleColor}}">{{title}}</span>
  <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button> -->
  <button type="button" class="close {{closeColor}}" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <p>Hello, {{modalParameter | json}}!</p> -->
  <ng-content ></ng-content>
</div>
<div *ngIf="showCloseButton" class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onClose()">Cerrar</button>
  
</div>


