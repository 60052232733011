import { FrecuenceDom, ItemListDom } from "domain/item-list/models/item-list.dom";
import { ItemListResponseDto } from "../dtos/item-list.dto";

export class ItemListMapper {
    static toDom(param: ItemListResponseDto): ItemListDom {
        return {
            id: param.id,
            name: param.name,
            code: param.code,
            properties: param.properties
        };
    }

    static toFrecuenceDom(param: ItemListResponseDto): FrecuenceDom {
        return {
            id: param.id,
            name: param.name,
            code: param.code,
            value: param.properties ?  Number(param.properties): 1
        };
    }
}