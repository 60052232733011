import { Component, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericModalService } from 'presentation/app/shared/components/generic-modal/generic-modal.service';
import { MedicalOrderModalComponent } from './medical-order-modal/medical-order-modal.component';
import { BaseComponent } from 'presentation/app/shared/components/base/base-component';
import { CrudOperation } from 'presentation/app/shared/utils/utils.resource';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-medical-order-main',
  templateUrl: './medical-order-main.component.html',
  styleUrls: ['./medical-order-main.component.scss']
})
export class MedicalOrderMainComponent extends BaseComponent {
  onRefreshData= new Subject<boolean>();
  constructor(
    _route: ActivatedRoute,
    private _modalService: GenericModalService
  ) { 
    super(_route)
    }
  onInit(): void {}
  onAfterViewInit(): void { }
  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void {}
  onCreate() {
    this._modalService.openModal(MedicalOrderModalComponent, { action: CrudOperation.ADD_RECORD }, null);
  }
}