import { AnualSerie, ConsolidatedDom, ItemSerie, MonthSerie } from "domain/statistics/models/statistics.response.dom";
import { AnnualChartDto, ConsolidatedDto, MonthlyChartDto } from "../dtos/statistic.dto";

export class StatisticMapper {
    static toConsolidated(param: ConsolidatedDto): ConsolidatedDom {
        return {
            totalConsultations: param.totalConsultations,
            totalPatients: param.totalPatients
        };
    }

    static toMonthly(param: MonthlyChartDto): MonthSerie {
        return <MonthSerie>{
            key: param.month.toString(),
            label: param.name,
            data: param.data.map(e => <ItemSerie>{
                key: e.key.toString(),
                value: e.value
            })
        };
    }

    static toAnnual(param: AnnualChartDto): AnualSerie {
        return <AnualSerie>{
            key: param.month.toString(),
            label: param.name,
            value: param.data
        };
    }
}