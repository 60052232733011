
import * as CryptoJS from 'crypto-ts';
import { environment } from 'environments/environment';
export class EncryptionUtil {
  static decrypt(value:string):string{
    let key:string = environment.encryptionKey;
    let iv:string = environment.encryptionIv;
    var dataDecrypt = CryptoJS.AES.decrypt(
        value,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.PKCS7
      }
    );
    let data= dataDecrypt.toString(CryptoJS.enc.Utf8)
    return data;
  }
  static decryptToObject(value:string):any{
    return JSON.parse(this.decrypt(value));
  }
  static encrypt(value:string):string{
    let key:string = environment.encryptionKey;
    let iv:string = environment.encryptionIv;
    var dataDecrypt = CryptoJS.AES.encrypt(
      value,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.PKCS7
      }
    );
    return dataDecrypt.toString();
  }
}