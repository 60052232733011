import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ShowErrorsService } from '../../validators/show-errors.service';

@Component({
  selector: 'app-generic-switch',
  templateUrl: './generic-switch.component.html',
  styleUrls: ['./generic-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenericSwitchComponent),
      multi: true
    }
  ],
})
export class GenericSwitchComponent implements OnInit, ControlValueAccessor {
  @Input() formGroup: FormGroup;
  @Input() formControlName: string = null;
  @Input() title:string = '';
  @Input() model: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showTextError= true;


  constructor(    public _showErrorsService:ShowErrorsService,
    ) { }

  ngOnInit(): void {
    if (this.formGroup && this.formControlName) {
      let value = this.formGroup.get(this.formControlName).value
      this.model = value
    }
  }
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  get formErrors(): string[] {
    return this._showErrorsService.listOfErrors(
      this.formGroup.get(this.formControlName)
    )
  }

  onChange($event) {
    if (this.formGroup && this.formControlName) {
      this.formGroup.get(this.formControlName).setValue($event)
    }
  }
}
