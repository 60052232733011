<div class="dropdown btn-group mr-1 mb-1" ngbDropdown container="body">
  <!--#buttonOpciones-->
  <button type="button" class="btn btn-outline-info dropdown-toggle btn-sm" ngbDropdownToggle>
    <i class="icon-info"></i> {{'actions.options' | translate}}
    <span class="caret"></span>
  </button>
  <div ngbDropdownMenu class="dropdown-menu" role="menu">
    <ng-content></ng-content>
  </div>
</div>
