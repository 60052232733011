import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { ConsultationResponseDom } from '../models/consultation.response.dom';
import { UpdateConsultationRequestDom } from '../models/consultation.request.dom';
import { ConsultationRepository } from '../repositories/consultation.repository';

@Injectable({ providedIn: 'root' })
export class CompleteConsultationUseCase implements UseCase<CompleteConsultationUseCaseParams, Promise<Result<ConsultationResponseDom, Failure>>> {
    constructor(private _consultationRepository: ConsultationRepository) { }
    execute = (params: CompleteConsultationUseCaseParams): Promise<Result<ConsultationResponseDom, Failure>> => this._consultationRepository
        .update(new UpdateConsultationRequestDom(
        {
            ...params,
            status: true
        }
    ))
}

interface CompleteConsultationUseCaseParams{
    id: number;
    reason: string;
    diagnosis: string;
    patientId: number;
    consultationNumber: number;
    dateOfConsultation: string;
    additionalNote?: string;

}