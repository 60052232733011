import { Pagination, SORT_DIR } from "base/pagination/pagination";
import { Page, SourceData } from "../generic-datatable/model/page";
import { PagedData } from "../generic-datatable/model/paged-data";
import { Pageable } from "base/pagination/pageable";
import { BaseComponent } from "./base-component";
import * as moment from "moment";

export abstract class BasePaginationComponent<T> extends BaseComponent {
  private pageData = new PagedData<T>();
  allData = new Array<T>();

  get page(): Page {
    return this.pageData.page;
  }

  get data(): Array<T> {
    return this.pageData.data;
  }

  setOrderBy(value: string) {
    this.pageData.page.sortBy = value
  }

  setSourceLocalData() {
    this.pageData.page.sourceData= SourceData.LOCAL
  }
  requestPagination(): Pagination {
    return <Pagination>{
      currentPage: this.page.currentPage + 1,
      itemsPerPage: this.page.itemsPerPage,
      sortBy: this.page.sortBy,
      sortDir: this.page.sortDir == "desc" ? SORT_DIR.DESC : SORT_DIR.ASC,
    };
  }
  setPageData(pageable: Pageable<T>): void {
    this.pageData.page.hasNextPage = pageable.pagination.hasNextPage;
    this.pageData.page.nextPageNumber = pageable.pagination?.nextPageNumber;
    this.pageData.page.totalElements = pageable.pagination.totalCount;
    this.pageData.page.currentPage = pageable.pagination.currentPage - 1;
    this.pageData.data = pageable.data;
  }

  setLocalData(data: Array<T>): void {
    this.pageData.page.totalElements = data.length;
    this.allData = data;
    this.pageData.data = data;
    
  }

  setFilterLocalData($query: string, predicate: Function): void {
    if (!$query) {
      this.pageData.page.totalElements = this.allData.length;
      this.pageData.data = this.allData;
      return;
    }
    const result = this.allData.filter((user) => predicate(user));
    this.pageData.page.totalElements = result.length;
    this.pageData.data = result;
  }

  callOnLoadData() {
    if (this.pageData.data.length === 1 && this.page.currentPage > 0) {
      this.pageData.page.currentPage = this.pageData.page.currentPage - 1;
    }
    this.onLoadData();
  }
  onFilterEvent($event: string) {
    this.pageData.page.currentPage = 0;
    this.pageData.page.filterSearch = $event;
    this.onLoadData();
  }
  onSortEvent($event: Page) {
    this.pageData.page.sortBy = $event.sortBy;
    this.pageData.page.sortDir = $event.sortDir;
    this.onLoadData();
  }
  onLimitPageEvent($event: Page) {
    this.pageData.page.itemsPerPage = $event.itemsPerPage;
    this.pageData.page.currentPage = $event.currentPage;
    this.onLoadData();
  }
  onNexPageEvent($event: Page) {
    this.page.currentPage = $event.currentPage;
    this.onLoadData();
  }

  datePipe = (value: any, ...args: any[]) =>
    value
      ? moment(value).utc().format("DD/MM/YYYY hh:mm:ss A")
      : "---";
  
  onlyDatePipe = (value: any, ...args: any[]) =>
      value
        ? moment(value).format("DD/MM/YYYY")
        : "---";

  abstract onLoadData();
}
