export enum PasswordLocalStorage {
  PasswordLocalStorage = 'M)TP(Q)d]r[Goy$9hUP)wD4Pt^%P^m}dnuU2;5Gpj]Dq/F4@udp@bJV/wJ-m'
}

export enum NamesLocalStorage {
  Rol = 'Rol',
  CurrentAccountUser = 'Account',
  RegisterUserClient = 'RegisterUserClient',
  AddressRegisterUserClient = 'AddressRegisterUserClient',
  idToken = 'id_token'
}
