import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { AuthRepository } from '../../repositories/auth.repository';
import { SessionRepository } from 'domain/session/repositories/session.repository';
import { Injectable } from '@angular/core';
import { NoResult } from 'base/usecase/query';
import { ChangePasswordRequestDom } from 'domain/auth/models/password-request.dom';

@Injectable({ providedIn: 'root' })
export class AuthChangePasswordUseCase implements UseCase<ChangePasswordRequestDom, Promise<Result<NoResult, Failure>>> {
    constructor(private _authRepository: AuthRepository,
    private _sessionRepository: SessionRepository) { }
    async execute(params: ChangePasswordRequestDom): Promise<Result<NoResult, Failure>> {
        let session = this._sessionRepository.get()
        params.params.userId = session.id 
        return this._authRepository.changePassword(params);
    }
}