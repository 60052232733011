import { Component, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateMedicationUseCase } from 'domain/administration/medication/usecases/create-medication.usecase';
import { UpdateMedicationUseCase } from 'domain/administration/medication/usecases/update-medication.usecase';
import { BaseComponent } from 'presentation/app/shared/components/base/base-component';
import { MedicationListService } from '../medication-list/medication-list.service';
import { SweetAlertService } from 'presentation/app/shared/services/sweet-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { CreateMedicationRequestDom, UpdateMedicationRequestDom } from 'domain/administration/medication/models/medication.request.dom';
@Component({
  selector: 'app-medication-modal',
  templateUrl: './medication-modal.component.html',
  styleUrls: ['./medication-modal.component.scss']
})
export class MedicationModalComponent extends BaseComponent {
  constructor(_route: ActivatedRoute,
    private _createMedicationUseCase: CreateMedicationUseCase,
    private _updateMedicationUseCase: UpdateMedicationUseCase,
    private _medicalOrderListService: MedicationListService,
    private _notify: SweetAlertService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,) {
    super(_route)
  }
  onInit(): void {}
  onAfterViewInit(): void { }
  async onCreate(request: CreateMedicationRequestDom) {
    let result = await this._createMedicationUseCase.execute(request)
    result.fold((_) => this.successResponse(), (_) => this.errorResponse('error.create'))
  }
  async onUpdate(request: UpdateMedicationRequestDom) {
    let result = await this._updateMedicationUseCase.execute(request)
    result.fold((_) => this.successResponse(), (_) => this.errorResponse('error.update'))
  }

  successResponse = () => {
    this._notify.showToastSucces()
    this._medicalOrderListService.refresh()
    this.activeModal.close()
  }

  errorResponse = (key: string) => this._notify.showAlertError(this.translate.instant('alerts.title'),this.translate.instant(key))
  onDestroy(): void {}
  onChanges(changes: SimpleChanges): void {}
}
