import { Component, Input, SimpleChanges, Injectable } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShowErrorsService {

  messagesValidation=null;
  constructor(private _traslateService: TranslateService) {
    this._traslateService
    .get(["validation"])
    .subscribe((translations) => {
      this.messagesValidation= translations;
    });
  }

  private readonly errorMessages = {
    'required': () => this.messagesValidation?  this.messagesValidation.validation.required:"",
    'email': () => this.messagesValidation ? this.messagesValidation.validation.email.invalid:"",
    //'minlength': (params) => this.messagesValidation.validation.minlength + params.requiredLength,

    'minlength': (params) => this._traslateService.instant('validation.minlength', {minlength: params.requiredLength}),

    'maxlength': (params) => this._traslateService.instant('validation.maxlength', {maxlength: params.requiredLength}),
    //'email': (params) => this._traslateService.instant('validation.email.invalid'),

    'max': (params) =>  this._traslateService.instant('validation.max', {max: params.max}),
    'min': (params) => this._traslateService.instant('validation.min', {max: params.min}),
    'pattern': (params) => this._traslateService.instant('validation.pattern', {pattern: params.pattern}),


    'years': (params) => params.message,
    'countryCity': (params) => params.message,
    'uniqueName': (params) => params.message,
    'telephoneNumbers': (params) => params.message,
    'telephoneNumber': (params) => params.message,
    'rangeBetwenDates': (params) => params.message,
    'validateMaxAndminValue': (params) => params.message,
    'validateMinValue': (params) => params.message,
    'validatePassword': (params) => params.message,
    'validateValorEstimado': (params) => params.message,
    'childrenNotEqual': (params) => params.message,
    'invalidNumber': (params) => params.message,
    'invalidIntOrDecimal': (params) => params.message,

    'invalidText': (params) => params.message
  };



  private shouldShowErrors(control:AbstractControlDirective | AbstractControl): boolean {
    return control &&
      (control.errors)&&
      (control.dirty || control.touched);
  }

  listOfErrors(control:AbstractControlDirective | AbstractControl): string[] {
    //console.log("ERRORS GROUP: ",control ? control.errors: null)
    if(this.shouldShowErrors(control)){
      return Object.keys(control.errors)
      .map(field => this.getMessage(field, control.errors[field]));
    }else{
      return []
    }
  }
  private getMessage(type: string, params: any) {
    if(type=='required' || type== 'email'){
      return this.errorMessages[type]();
    }else{
      return this.errorMessages[type](params);
    }
  }
}
