import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { NoParams } from 'base/usecase/query';
import { ItemListDom } from 'domain/item-list/models/item-list.dom';
import { TYPE_LIST } from 'domain/item-list/constants/type-list.const';
import { ItemListRepository } from 'domain/item-list/repositories/item-list.repository';

@Injectable({ providedIn: 'root' })
export class AllTypesMedicalOrdersUseCase implements UseCase<NoParams, Promise<Result<ItemListDom[], Failure>>> {
    constructor(private _itemListRepository: ItemListRepository) { }
    execute = (_): Promise<Result<ItemListDom[], Failure>> => this._itemListRepository.listByTypeName(TYPE_LIST.TYPE_MEDICAL_ORDER);
}