import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, BaseResponseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Pageable } from 'base/pagination/pageable';
import { Result } from 'base/result/result';
import { NoResult } from 'base/usecase/query';
import { CreatePatientRequestDom, UpdatePatientRequestDom, PageablePatientsRequestDom } from 'domain/patient/models/patient.request.dom';
import { PatientFoundResponseDom, PatientInfoResponseDom, PatientResponseDom } from 'domain/patient/models/patient.response.dom';
import { PatientRepository } from 'domain/patient/repositories/patient.repository';
import { environment } from 'environments/environment';
import { PatientResponseDto } from './dtos/patient.dto';
import { catchError, map } from 'rxjs/operators';
import { PatientMapper } from './mappers/patient.mapper';

@Injectable({
  providedIn: 'root'
})
export class PatientImplRepository extends BaseApi implements PatientRepository {
  baseUrl = environment.api;
  cachePatient: { [resource: string]: { result: PatientResponseDto } } = {};

  constructor(private http: HttpClient) {
    super();
  }
  findInfoById(id: number): Promise<Result<PatientInfoResponseDom, Failure>> {
    const cache = this.cachePatient[id];
    if (cache) return Promise.resolve(this.baseOk(PatientMapper.toInfoDom(this.cachePatient[id].result)));
    return this.http
    .get<PatientResponseDto>(`${this.baseUrl}/v1/Patient/GetById/${id}`)
      .pipe(map(_ => {
          this.cachePatient[id] =  { result: _ };
          return this.baseOk(PatientMapper.toInfoDom(_))
      }))
      .pipe(catchError((_) => this.baseCatch<PatientInfoResponseDom>(_))).toPromise();
  }
  findById(id: number): Promise<Result<PatientFoundResponseDom, Failure>> {
    return this.http
    .get<PatientResponseDto>(`${this.baseUrl}/v1/Patient/GetById/${id}`)
      .pipe(map(_ => this.baseOk(PatientMapper.toFoundDom(_))))
      .pipe(catchError((_) => this.baseCatch<PatientFoundResponseDom>(_))).toPromise();
  }
  create(request: CreatePatientRequestDom): Promise<Result<PatientResponseDom, Failure>> {
    return this.http
    .post<PatientResponseDto>(`${this.baseUrl}/v1/Patient/Create`, JSON.stringify(PatientMapper.toCreateDto(request)))
      .pipe(map( _ => this.baseOk(PatientMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<PatientResponseDom>(_))).toPromise();
  }
  update(request: UpdatePatientRequestDom): Promise<Result<PatientResponseDom, Failure>> {
    return this.http
    .put<PatientResponseDto>(`${this.baseUrl}/v1/Patient/Update`, JSON.stringify(PatientMapper.toUpdateDto(request)))
      .pipe(map( _ => this.baseOk(PatientMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<PatientResponseDom>(_))).toPromise();
  }
  delete(id: number): Promise<Result<NoResult, Failure>> {
    return this.http
    .delete<any>(`${this.baseUrl}/v1/Patient/Delete/${id}`)
    .pipe(map(_ => this.baseOk(NoResult)))
    .pipe(catchError(this.baseCatch)).toPromise();
  }
  list(request: PageablePatientsRequestDom): Promise<Result<Pageable<PatientResponseDom>, Failure>> {
    const params = this.basePageable(request.params)
    return this.http
      .get<BaseResponseApi<PatientResponseDto>>(`${this.baseUrl}/v1/Patient/GetAll`, {
        params: params
      })
      .pipe(map(_ => {
        this.cachePatient = _.data.reduce((acc, item) => {
          acc[item.id] = { result: item };
          return acc;
        }, {} as { [resource: string]: { result: PatientResponseDto } });
        return this.baseOk(PatientMapper.toPageableDom(_))
      }))
      .pipe(catchError((_) => this.baseCatch<Pageable<PatientResponseDom>>(_))).toPromise();
  }
}