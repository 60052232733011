//line chart
import colorLib from '@kurkle/color';

export const CHART_COLORS = {
    red: 'rgb(255, 99, 132)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)',
    brown: 'rgb(165, 42, 42)',
    pink: 'rgb(255, 182, 193)',
    teal: 'rgb(0, 128, 128)',
    indigo: 'rgb(75, 0, 130)',
    orange: 'rgb(255, 159, 64)',
    gold: 'rgb(255, 215, 0)',
    lime: 'rgb(50, 205, 50)',
  };
  
  function ramdomColor() {
    const colores = Object.values(CHART_COLORS);
    const colorAlAzar = colores[Math.floor(Math.random() * colores.length)];
    return colorAlAzar;
  }
  
  export function transparentize(value: string, opacity: number) {
    var alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
  }