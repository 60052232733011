import { MedicalOrdersRoutings } from 'presentation/app/core/administration/medical-orders/medical-orders-routing.module';
import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';
import { MedicationsRoutings } from 'presentation/app/core/administration/medications/medications-routing.module';
import { PatientRoutings } from 'presentation/app/core/home/patient/patient-routing.module';
import { StatisticsRoutings } from 'presentation/app/core/home/statistics/statistics-routing.module';

export const HROUTES: RouteInfo[] = [
  {
    path: '/medicaloffice/core/admin', title: 'menu.administration.title', icon: 'ft-settings', class: 'dropdown nav-item has-sub', isExternalLink: false, submenu: [
      { path: `${MedicalOrdersRoutings.MAIN}`, title: 'menu.administration.medicalOrder', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: `${MedicationsRoutings.MAIN}`, title: 'menu.administration.medicines', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: PatientRoutings.MAIN, title: 'menu.patients', icon: 'ft-users', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  },
  {
    path: StatisticsRoutings.MAIN, title: 'menu.reports', icon: 'ft-bar-chart-2', class: 'dropdown nav-item', isExternalLink: false, submenu: []
  },
  
  /* {
    path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
        { path: '/YOUR-ROUTE-PATH', title: 'Second Level', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        {
            path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            ]
        },
    ]
}, */
];
