<div *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-group mb-0">
    <label *ngIf="title">{{ required ? "*" : "" }} {{ title }}</label>
    <div
      class="mb-0"
      [ngClass]="{
        'position-relative has-icon-left': showIcon,
        'input-group': enabledIconEnd
      }"
    >
      <!-- <input *ngIf="inputType!=inputTypeResource.TEXTAREA" [type]="inputType" (change)="eventChangeValue()" [formControlName]="formControlName" [disabled]="disabled" class="form-control {{inputSizeClass}}" [ngClass]="isTochedOrDirty ? isValid ? 'is-valid' :'is-invalid' :''"  [placeholder]="_placeholder">-->
      <input
        *ngIf="
          inputType != inputTypeResource.TEXTAREA &&
          inputType != inputTypeResource.PASSWORD
        "
        [mask]="mask"
        [errorStateMatcher]="confirmValidParentMatcher ? null : null"
        [type]="inputType"
        (change)="eventChangeValue()"
        [formControlName]="formControlName"
        class="form-control {{ inputSizeClass }} {{ inputCustomClass }}"
        [ngClass]="{
          isValid: isTochedOrDirty && isValid,
          'is-invalid': isTochedOrDirty && !isValid
        }"
        [placeholder]="_placeholder"
        />
      <input
        *ngIf="
          inputType != inputTypeResource.TEXTAREA &&
          inputType == inputTypeResource.PASSWORD
        "
        [errorStateMatcher]="confirmValidParentMatcher ? null : null"
        [type]="inputTypeLocal"
        (change)="eventChangeValue()"
        [formControlName]="formControlName"
        class="form-control {{ inputSizeClass }} {{ inputCustomClass }}"
        [ngClass]="{
          isValid: isTochedOrDirty && isValid,
          'is-invalid': isTochedOrDirty && !isValid
        }"
        [placeholder]="_placeholder"

            />
      <textarea
        *ngIf="inputType === inputTypeResource.TEXTAREA"
        [rows]="rowsTextTarea"
        class="form-control"
        [formControlName]="formControlName"
        [disabled]="disabled"
        [ngClass]="isTochedOrDirty ? (isValid ? 'is-valid' : 'is-invalid') : ''"
        [(ngModel)]="value"
      ></textarea>

      <div *ngIf="showIcon" class="form-control-position">
        <i [ngClass]="icon ? icon : ''"></i>
      </div>

      <div *ngIf="enabledIconEnd" class="input-group-append">
        <span
          *ngIf="inputType === inputTypeResource.PASSWORD"
          class="input-group-text"
          (click)="toggleShowPassword()"
        >
          <i
            class=""
            [ngClass]="{
              'ft-eye-off': inputTypeLocal === inputTypeResource.PASSWORD,
              'ft-eye': inputTypeLocal === inputTypeResource.TEXT
            }"
          ></i>
        </span>
        <span
          *ngIf="inputType !== inputTypeResource.PASSWORD"
          class="input-group-text"
          (click)="toggleIconEnd()"
        >
          <i class="{{ iconEnd }}"></i>
        </span>
      </div>
    </div>

    <div *ngIf="formGroup">
      <div
        *ngFor="
          let error of showErrorsService.listOfErrors(
            formGroup.get(formControlName)
          )
        "
      >
        <ng-container *ngIf="showTextError">
          <small
            ><span class="badge badge-pill bg-light-danger badge-round mt-1">
              <i class="ft-info"></i> {{ error }}</span
            ></small
          >
        </ng-container>
      </div>
    </div>

    <div *ngIf="formGroupValidator && formGroupNameError && showTextError">
      <ng-container
        *ngFor="
          let error of showErrorsService.listOfErrors(
            formGroupNameError
              ? formGroupValidator.get([formGroupNameError])
              : formGroupValidator.get('')
          )
        "
      >
        <small
          ><span class="badge badge-pill bg-light-danger badge-round mt-1">
            <i class="ft-info"></i> {{ error }}</span
          ></small
        >
      </ng-container>
    </div>
  </div>
  <!-- Custom Form Validaton Ends -->
</div>
