import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { NoResult } from 'base/usecase/query';
import { CreateIncapacityRequestDom, IncapacityRepository, IncapacityResponseDom, UpdateIncapacityRequestDom } from 'domain/incapacity';
import { environment } from 'environments/environment';
import { IncapacityMapper } from './mappers/incapacity.mapper';
import { IncapacityResponseDto } from './dtos/incapacity.dto';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IncapacityImplRepository extends BaseApi implements IncapacityRepository {
  baseUrl = environment.api;
  cacheIncapacity: { [resource: string]: { result: IncapacityResponseDom } } = {};

  constructor(private http: HttpClient) {
    super();
  }
  findById(id: number): Promise<Result<IncapacityResponseDom, Failure>> {
    const cache = this.cacheIncapacity[id];
    if (cache) return Promise.resolve(this.baseOk(IncapacityMapper.toDom(this.cacheIncapacity[id].result)));
    return this.http
    .get<IncapacityResponseDto>(`${this.baseUrl}/v1/Incapacity/GetById/${id}`)
    .pipe(map( _ => this.baseOk(IncapacityMapper.toDom(_))))
    .pipe(catchError((_) => this.baseCatch<IncapacityResponseDom>(_))).toPromise();
  }
  create(request: CreateIncapacityRequestDom): Promise<Result<IncapacityResponseDom, Failure>> {
    return this.http
    .post<IncapacityResponseDto>(`${this.baseUrl}/v1/Incapacity/Create`, JSON.stringify(IncapacityMapper.toCreateDto(request)))
      .pipe(map( _ => this.baseOk(IncapacityMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<IncapacityResponseDom>(_))).toPromise();
  }
  update(request: UpdateIncapacityRequestDom): Promise<Result<IncapacityResponseDom, Failure>> {
    return this.http
    .put<IncapacityResponseDto>(`${this.baseUrl}/v1/Incapacity/Update`, JSON.stringify(IncapacityMapper.toUpdateDto(request)))
      .pipe(map( _ => this.baseOk(IncapacityMapper.toDom(_))))
      .pipe(catchError((_) => this.baseCatch<IncapacityResponseDom>(_))).toPromise();
  }
  delete(id: number): Promise<Result<NoResult, Failure>> {
    return this.http
    .delete<any>(`${this.baseUrl}/v1/Incapacity/Delete/${id}`)
    .pipe(map(_ => this.baseOk(NoResult)))
    .pipe(catchError(this.baseCatch)).toPromise();
  }
  listByPatient(patientId: number): Promise<Result<IncapacityResponseDom[], Failure>> {
    return this.http
      .get<IncapacityResponseDto[]>(`${this.baseUrl}/v1/Incapacity/GetAll/${patientId}`)
      .pipe(map(_ => {
        this.cacheIncapacity = _.reduce((acc, item) => {
          acc[item.id] = { result: item };
          return acc;
        }, {} as { [resource: string]: { result: IncapacityResponseDto } });
        return this.baseOk( _.map(IncapacityMapper.toDom))
      }))
      .pipe(catchError((_) => this.baseCatch<IncapacityResponseDom[]>(_))).toPromise();
  }
}