import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MedicationRepository } from 'domain/administration/medication/repositories/medication.repository';
import { MedicationImplRepository } from './medication-impl.repository';
import { CreateMedicationUseCase } from 'domain/administration/medication/usecases/create-medication.usecase';
import { DeleteMedicationByIdUseCase } from 'domain/administration/medication/usecases/delete-medication-by-id.usecase';
import { ListMedicationsUseCase } from 'domain/administration/medication/usecases/list-medications.usecase';
import { UpdateMedicationUseCase } from 'domain/administration/medication/usecases/update-medication.usecase';
import { AllMedicationsUseCase } from 'domain/administration/medication/usecases/all-medications.usecase';

export const medicationProvider = { provide: MedicationRepository, useClass: MedicationImplRepository }
@NgModule({
  providers: [
    medicationProvider,
    CreateMedicationUseCase,
    DeleteMedicationByIdUseCase,
    ListMedicationsUseCase,
    UpdateMedicationUseCase,
    AllMedicationsUseCase
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class MedicationInfModule { }
