import { Observable } from 'rxjs';
import { LoginRequestDom } from '../models/login-request.dom';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { LoginResponseDom } from '../models/login-response.dom';
import { ChangePasswordRequestDom } from '../models/password-request.dom';
import { NoResult } from 'base/usecase/query';

export abstract class AuthRepository {
    abstract login(request: LoginRequestDom): Promise<Result<LoginResponseDom, Failure>>;
    abstract changePassword(request: ChangePasswordRequestDom): Promise<Result<NoResult, Failure>>;
}
