import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicinesImplRepository } from './medicines-impl.repository';
import { MedicineRepository } from 'domain/plans/medicines/repositories/medicine.repository';
import { CreateMedicineUseCase } from 'domain/plans/medicines/usecases/create-medicine.usecase';
import { DeleteMedicineByIdUseCase } from 'domain/plans/medicines/usecases/delete-medicine-by-id.usecase';
import { ListMedicinesByConsultationIdUseCase } from 'domain/plans/medicines/usecases/list-medicines-by-consultation-id.usecase';
import { UpdateMedicineUseCase } from 'domain/plans/medicines/usecases/update-medicine.usecase';

export const provider = { provide: MedicineRepository, useClass: MedicinesImplRepository }
@NgModule({
  providers: [
    provider,
    CreateMedicineUseCase,
    DeleteMedicineByIdUseCase,
    ListMedicinesByConsultationIdUseCase,
    UpdateMedicineUseCase
  ],
  imports: [
    CommonModule
  ]
})
export class MedicinesInfModule { }
