import { ClinicalHistoryResponseDom } from "domain/consultation/clinical-history/models/clinical-history.response.dom";
import { ClinicalHistoryResponseDto, CreateClinicalHistoryRequestDto, UpdateClinicalHistoryRequestDto } from "../dtos/clinical-history.dto";
import { CreateClinicalHistoryRequestDom, UpdateClinicalHistoryRequestDom } from "domain/consultation/clinical-history/models/clinical-history.request.dom";

export class ClinicalHistoryMapper {
    static toDom(param: ClinicalHistoryResponseDto): ClinicalHistoryResponseDom {
        return {
            consultationId: param?.gynecological_HistoryResponseDto?.consultationId,
            gynecological: {
                id: param?.gynecological_HistoryResponseDto?.id,
                menarche: param?.gynecological_HistoryResponseDto?.menarche,
                tenarche: param?.gynecological_HistoryResponseDto?.tenarche,
                onsetSexualActivity: param?.gynecological_HistoryResponseDto?.onsetSexualActivity,
                numberSexualPartners: param?.gynecological_HistoryResponseDto?.numberSexualPartners,
                menstrualCycles: param?.gynecological_HistoryResponseDto?.menstrualCycles,
                lastMenstrualPeriodFum: param?.gynecological_HistoryResponseDto?.lastMenstrualPeriod_FUM,
                lastMenstrualPeriodFup: param?.gynecological_HistoryResponseDto?.lastMenstrualPeriod_FUP,
                currentlySexuallyActive: param?.gynecological_HistoryResponseDto?.currentlySexuallyActive,
                contraceptiveMethodId: param?.gynecological_HistoryResponseDto?.contraceptiveMethodId,
                contraceptiveMethodName: param?.gynecological_HistoryResponseDto?.contraceptiveMethodName
            },
            personal: {
                id: param?.personal_HistoryResponseDto?.id,
                alergies: param?.personal_HistoryResponseDto?.allergies,
                medications: param?.personal_HistoryResponseDto?.medications,
                pathologicalConditions: param?.personal_HistoryResponseDto?.pathologicalConditions,
                familyHistory: param?.personal_HistoryResponseDto?.familyHistory,
                surgicalHistory: param?.personal_HistoryResponseDto?.surgicalHistory
            }
        };
    }

    static toCreateDto(param: CreateClinicalHistoryRequestDom): CreateClinicalHistoryRequestDto {
        return <CreateClinicalHistoryRequestDto>{
            createGynecological_HistoryDto: {
                menarche: param.params.gynecological.menarche,
                tenarche: param.params.gynecological.tenarche,
                onsetSexualActivity: param.params.gynecological.onsetSexualActivity,
                numberSexualPartners: param.params.gynecological.numberSexualPartners,
                menstrualCycles: param.params.gynecological.menstrualCycles,
                lastMenstrualPeriod_FUM: param.params.gynecological.lastMenstrualPeriodFum,
                lastMenstrualPeriod_FUP: param.params.gynecological.lastMenstrualPeriodFup,
                currentlySexuallyActive: param.params.gynecological.currentlySexuallyActive,
                contraceptiveMethodId: param.params.gynecological.contraceptiveMethodId,
                consultationId: param.params.consultationId,
            },
            createPersonal_HistoryDto: {
                allergies: param.params.personal.allergies,
                medications: param.params.personal.medications,
                pathologicalConditions: param.params.personal.pathologicalConditions,
                familyHistory: param.params.personal.familyHistory,
                surgicalHistory: param.params.personal.surgicalHistory,
                consultationId: param.params.consultationId
            }
        };
    }

    static toUpdateDto(param: UpdateClinicalHistoryRequestDom): UpdateClinicalHistoryRequestDto {
        return <UpdateClinicalHistoryRequestDto>{
            updateGynecological_HistoryDto: {
                id: param.params.gynecological.id,
                menarche: param.params.gynecological.menarche,
                tenarche: param.params.gynecological.tenarche,
                onsetSexualActivity: param.params.gynecological.onsetSexualActivity,
                numberSexualPartners: param.params.gynecological.numberSexualPartners,
                menstrualCycles: param.params.gynecological.menstrualCycles,
                lastMenstrualPeriod_FUM: param.params.gynecological.lastMenstrualPeriodFum,
                lastMenstrualPeriod_FUP: param.params.gynecological.lastMenstrualPeriodFup,
                currentlySexuallyActive: param.params.gynecological.currentlySexuallyActive,
                contraceptiveMethodId: param.params.gynecological.contraceptiveMethodId,
                consultationId: param.params.consultationId,
            },
            updatePersonal_HistoryDto: {
                id: param.params.personal.id,
                allergies: param.params.personal.allergies,
                medications: param.params.personal.medications,
                pathologicalConditions: param.params.personal.pathologicalConditions,
                familyHistory: param.params.personal.familyHistory,
                surgicalHistory: param.params.personal.surgicalHistory,
                consultationId: param.params.consultationId
            }
        };
    }
}