import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'base/api/base-api';
import { Failure } from 'base/failure/failure';
import { Result } from 'base/result/result';
import { MonthSerie, AnualSerie, ConsolidatedDom } from 'domain/statistics/models/statistics.response.dom';
import { StatisticRepository } from 'domain/statistics/repositories/statistic.repository';
import { environment } from 'environments/environment';
import { AnnualChartDto, AnnualRequestDto, ConsolidatedDto, MonthlyChartDto, MonthlyRequestDto } from './dtos/statistic.dto';
import { catchError, map } from 'rxjs/operators';
import { StatisticMapper } from './mappers/statistic.mapper';

@Injectable({
  providedIn: 'root'
})
export class StatisticImplRepository  extends BaseApi implements  StatisticRepository{
  baseUrl = environment.api;
  constructor(private http: HttpClient) {
    super();
  } 
  consolidated(): Promise<Result<ConsolidatedDom, Failure>> {
    return this.http
    .get<ConsolidatedDto>(`${this.baseUrl}/v1/Statistic/GetConsolidated`)
    .pipe(map(_ => this.baseOk(StatisticMapper.toConsolidated(_))))
    .pipe(catchError((_) => this.baseCatch<ConsolidatedDom>(_))).toPromise();
  }
  consultationsMonthly(year: number, monthIds: number[]): Promise<Result<MonthSerie[], Failure>> {
    return this.http.post<MonthlyChartDto[]>(`${this.baseUrl}/v1/Statistic/ConsultationsMonthly`, <MonthlyRequestDto>{
        months: monthIds,
        year: year
    }).pipe(map(_ => this.baseOk(_.map(StatisticMapper.toMonthly))))
    .pipe(catchError((_) => this.baseCatch<MonthSerie[]>(_))).toPromise();
  }
  consultationsAnnual(year: number): Promise<Result<AnualSerie[], Failure>> {
    return this.http.post<AnnualChartDto[]>(`${this.baseUrl}/v1/Statistic/ConsultationsAnnual`,     <AnnualRequestDto>{ year: year })
    .pipe(map(_ => this.baseOk(_.map(StatisticMapper.toAnnual))))
    .pipe(catchError((_) => this.baseCatch<AnualSerie[]>(_))).toPromise();
  }
}