import { UseCase } from 'base/usecase/use-case';
import { Result } from 'base/result/result';
import { Failure } from 'base/failure/failure';
import { Injectable } from '@angular/core';
import { IncapacityResponseDom } from '../models/incapacity.response.dom';
import { IncapacityRepository } from '../repositories/incapacity.repository';

@Injectable({ providedIn: 'root' })
export class ListIncapacitiesUseCase implements UseCase<number, Promise<Result<IncapacityResponseDom[], Failure>>> {
    constructor(private _incapacityRepository: IncapacityRepository) { }
    execute = (params: number): Promise<Result<IncapacityResponseDom[], Failure>> => this._incapacityRepository.listByPatient(params);
}